import React from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

import { Label, Separator, Stack, Text } from '@fluentui/react';

import { Ticket } from '../../../../../api-client';
import { TRANSLATION_KEYS } from './localization/translation-keys';

import ViewAttachment from './components/ViewAttachment/view-attachment';

interface IViewForm {
    ticket?: Ticket;
}

const ViewForm: React.FunctionComponent<IViewForm> = ({ ticket }) => {
    const {
        t: translate
    } = useTranslation();

    return (
        <div className="view-form-container">
            <Stack tokens={{ childrenGap: 15 }}>
                <Stack.Item>
                    <Label>{translate(TRANSLATION_KEYS.SubjectLabel)}</Label>
                    <Text block>{ticket?.subject}</Text>
                </Stack.Item>
                <Stack.Item>
                    <Label>{translate(TRANSLATION_KEYS.DescriptionLabel)}</Label>
                    <Text block>{parse(ticket?.description || '')}</Text>
                </Stack.Item>
                <Stack.Item>
                    <Label>{translate(TRANSLATION_KEYS.AgentLabel)}</Label>
                    <Text block>{parse(ticket?.agent?.displayName || '-')}</Text>
                </Stack.Item>
            </Stack>

            <Separator />

            <ViewAttachment disabled={ticket?.status === 'closed'} ticketId={ticket?.id} />
        </div>
    );
}

export default ViewForm;
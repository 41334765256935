export const TRANSLATION_KEYS = {
    Heading: "common.refinementsDrawer.heading",
    ModeLabel: "ticketDetailsPage.detailsForm.modeLabel",
    ModePlaceholder: "ticketDetailsPage.detailsForm.modePlaceholder",
    ModeOptionLevel01: "common.ticketMode.modeOptionLevel01",
    ModeOptionLevel02: "common.ticketMode.modeOptionLevel02",
    ModeOptionLevel03: "common.ticketMode.modeOptionLevel03",
    AreaLabel: "ticketDetailsPage.detailsForm.areaLabel",
    AreaPlaceholder: "ticketDetailsPage.detailsForm.areaPlaceholder",
    DepartmentLabel: "ticketDetailsPage.detailsForm.departmentLabel",
    DepartmentPlaceholder: "ticketDetailsPage.detailsForm.departmentPlaceholder",
    AgentLabel: "ticketDetailsPage.detailsForm.agentLabel",
    AgentPlaceholder: "ticketDetailsPage.detailsForm.agentPlaceholder",
    StatusLabel: "ticketDetailsPage.detailsForm.statusLabel",
    StatusPlaceholder: "ticketDetailsPage.detailsForm.statusPlaceholder",
    StatusOptionOpen: "common.ticketStatus.statusOptionOpen",
    StatusOptionWorking: "common.ticketStatus.statusOptionWorking",
    StatusOptionPending: "common.ticketStatus.statusOptionPending",
    StatusOptionClosed: "common.ticketStatus.statusOptionClosed",
    PriorityLabel: "ticketDetailsPage.detailsForm.priorityLabel",
    PriorityPlaceholder: "ticketDetailsPage.detailsForm.priorityPlaceholder",
    PriorityOptionLow: "common.ticketPriority.priorityOptionLow",
    PriorityOptionMedium: "common.ticketPriority.priorityOptionMedium",
    PriorityOptionHigh: "common.ticketPriority.priorityOptionHigh",
    FromDateLabel: "reportPage.reportCriteria.fromDateLabel",
    FromDatePlaceholder: "reportPage.reportCriteria.fromDatePlaceholder",
    ToDateLabel: "reportPage.reportCriteria.toDateLabel",
    ToDatePlaceholder: "reportPage.reportCriteria.toDatePlaceholder",
    ConfirmText: "common.applyText",
    DismissText: "common.clearText"
};
import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { ActivityItem, Stack, Text } from '@fluentui/react';

import { TicketChangeLog } from '../../../../../../../api-client';
import { TRANSLATION_KEYS } from './localization/translation-keys';
import { DataEnchanter, DateTimeEnchanter } from '../../../../../../../utils/data-enchanters';

import { IAIAccordion, IAIAccordionItem } from '../../../../../../../common/components/IAIAccordion';
import { IAIEmptyContent } from '../../../../../../../common/components/IAIEmptyContent';
import { ParameterName } from '../../../../../../../utils/constants/ticket-changelogs-constants';

interface IChangeLogsAccordion {
    changeLogs?: TicketChangeLog[];
    disabled?: boolean;
};

const ChangeLogsAccordion: React.FunctionComponent<IChangeLogsAccordion> = ({
    changeLogs,
    disabled
}) => {
    const {
        i18n,
        t: translate
    } = useTranslation();

    const retrieveTranslatedText = (parameterName: string | undefined, value: string) => {
        if(parameterName === ParameterName.MODE || parameterName === ParameterName.STATUS || parameterName === ParameterName.PRIORITY || value.indexOf('common.unknown') !== -1) {
            return translate(value);
        } else {
            return value ? value : translate('common.unknown');
        }
    };

    const _handleChangesRefined = (changeLog: TicketChangeLog) => {
        if (!changeLog || (!changeLog.oldValue || !changeLog.newValue)) {
            return '';
        }

        const oldValue = DataEnchanter.getChangelogOldAndNewValText(changeLog.parameterName, changeLog.oldValue);
        const newValue = DataEnchanter.getChangelogOldAndNewValText(changeLog.parameterName, changeLog.newValue);

        return `${translate('common.changelogFrom')} ${retrieveTranslatedText(changeLog.parameterName, oldValue)} ${translate('common.changelogTo')} ${retrieveTranslatedText(changeLog.parameterName, newValue)}`;
    };

    const _handleInitialsFromName = (displayName?: string) => {
        if (!displayName) {
            return 'OP';
        }

        let initials = displayName.split(' ');

        return ((initials.shift()?.[0] || '') + (initials.pop()?.[0] || '')).toUpperCase();
    };

    const _generateFormattedString = (parameterName?: string) => {
        return translate(DataEnchanter.getChangelogParameterPreText(parameterName)).replace('#PARAM#', translate(DataEnchanter.getChangelogParameterNameText(parameterName)));        
    };

    return (
        <IAIAccordion>
            <IAIAccordionItem heading={translate(TRANSLATION_KEYS.Heading)} disabled={disabled}>
                <Stack tokens={{ childrenGap: 15 }}>
                    {_.map(_.orderBy(changeLogs, entry => entry.createdAt, 'desc'), (dataItem, dataIndex) => (
                        <ActivityItem
                            key={dataIndex}
                            activityDescription={(
                                <React.Fragment>
                                    <Text variant="small"><strong>{dataItem?.userInfo?.displayName}</strong> </Text>
                                    <Text variant="small">{_generateFormattedString(dataItem?.parameterName)} </Text>
                                    <Text variant="small">{_handleChangesRefined(dataItem)}</Text>
                                </React.Fragment>
                                
                            )}
                            activityPersonas={[
                                {
                                    /*
                                    imageInitials: _handleInitialsFromName(dataItem?.userInfo?.displayName),
                                    text: dataItem?.userInfo?.displayName,
                                    imageAlt: dataItem?.userInfo?.displayName,
                                    imageShouldFadeIn: true
                                    */

                                    imageInitials: dataItem?.userInfo?.userImageBase64 
                                        ? undefined 
                                        : _handleInitialsFromName(dataItem?.userInfo?.displayName),
                                    text: dataItem?.userInfo?.displayName,
                                    imageAlt: dataItem?.userInfo?.displayName,
                                    imageUrl: dataItem?.userInfo?.userImageBase64 
                                        ? `data:image/png;base64,${dataItem.userInfo.userImageBase64}` 
                                        : undefined,
                                    imageShouldFadeIn: true
                                }
                            ]}
                            timeStamp={(
                                <Text variant="xSmall">
                                    {DateTimeEnchanter.formatCustomDate(dataItem?.createdAt, i18n.language, { dateStyle: 'short', timeStyle: 'short' })}
                                </Text>
                            )}
                        />
                    ))}

                    {!disabled && !changeLogs?.length && (
                        <IAIEmptyContent title={translate(TRANSLATION_KEYS.ChangeLogsEmpty)} />
                    )}
                </Stack>
            </IAIAccordionItem>
        </IAIAccordion>
    );
}

export default ChangeLogsAccordion;
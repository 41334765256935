import React from 'react';

import { IconButton, TooltipHost } from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';

interface IIAIActionColumn {
    iconName: string;
    tooltip?: string;
    onActionClick?: () => void;
}

const IAIActionColumn: React.FunctionComponent<IIAIActionColumn> = ({
    iconName,
    tooltip,
    onActionClick
}) => {
    const iconButtonId = useId('iai-action-column');

    return (
        <div className="text-center">
            <TooltipHost content={tooltip}>
                <IconButton
                    id={iconButtonId}
                    iconProps={{ iconName: iconName }}
                    onClick={onActionClick}
                />
            </TooltipHost>
        </div>
    );
}

export default IAIActionColumn;
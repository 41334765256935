import { useState } from 'react';
import { useMsal } from "@azure/msal-react";

import { User } from '../../../../../../api-client';
import { IHookCallback } from '../../../../../../common/models';
import { DepartmentApiClient } from '../../../../../../providers/api-provider';
import { NotificationHelper } from '../../../../../../utils/notification';
import { useTranslation } from 'react-i18next';

export const useDepartmentMembers = () => {
    const {
        instance
    } = useMsal();

    const {
        t: translate
    } = useTranslation();

    interface IRetrieveMembers extends IHookCallback {
        id: string;
    }

    const [retrieveMembers, setRetrieveMembers] = useState<User[]>();
    const [retrieveMembersLoading, setRetrieveMembersLoading] = useState<boolean>(false);

    const handleRetrieveMembers = async (params: IRetrieveMembers) => {
        setRetrieveMembersLoading(true);

        const clientApi = await DepartmentApiClient(instance);

        clientApi.getDepartmentMembers(params.id)
            .then((response) => {
                setRetrieveMembers(response.data);

                params.onSuccess && params.onSuccess();
            })
            .catch((reason: any) => {
                setRetrieveMembers([]);

                params.onError && params.onError();

                NotificationHelper.createNotification({
                    type: 'error',
                    message: translate('system.notification.getDepartmentMembers.title'),
                    title: translate('system.notification.getDepartmentMembers.message')
                });
            })
            .finally(() => {
                setRetrieveMembersLoading(false);

                params.onAlways && params.onAlways();
            });
    }

    return {
        handleRetrieveMembers,
        retrieveMembers,
        retrieveMembersLoading
    }
}
import { useState } from 'react';

import { useMsal } from "@azure/msal-react";

import { Item, Property } from '../../../../../../api-client';
import { IHookCallback } from '../../../../../../common/models/IHookCallback';
import { PropertyApiClient } from '../../../../../../providers/api-provider';
import { NotificationHelper } from '../../../../../../utils/notification';
import { useTranslation } from 'react-i18next';

export const useProperties = () => {
    const {
        instance
    } = useMsal();

    const {
        t: translate
    } = useTranslation();

    const [properties, setProperties] = useState<Property[]>();
    const [propertiesLoading, setPropertiesLoading] = useState<boolean>(false);

    const handleProperties = async () => {
        setPropertiesLoading(true);
        
        const clientApi = await PropertyApiClient(instance);
        clientApi.getProperties()
            .then((response: any) => {
                setProperties(response.data);
                // Fix for Fluent UI -> Dropdown
                response.data.forEach((props: any) => {
                    for (let index = 0; index < props.values.length; index++) {
                        const element = props.values[index];
                        const obj = { key: element, text: element }
                        props.values[index] = obj;
                    }
                });
            })
            .catch((reason) => {
                setProperties([]);
                NotificationHelper.createNotification({
                    type: 'error',
                    message: translate('system.notification.getProperties.title'),
                    title: translate('system.notification.getProperties.message')
                });
            })
            .finally(() => {
                setPropertiesLoading(false);
            });
    }

    // CRUD Operations

    interface ICreateProperties extends IHookCallback {
        properties: Property;
    }
    const [createProperties, setCreateProperties] = useState<Item>();
    const [createPropertiesLoading, setCreatePropertiesLoading] = useState<boolean>(false);

    const handleCreateProperties = async (params: ICreateProperties) => {
        setCreatePropertiesLoading(true);

        const clientApi = await PropertyApiClient(instance);

        clientApi.insertProperty(params.properties)
            .then((response) => {
                setCreateProperties(response.data);

                params.onSuccess && params.onSuccess();

                NotificationHelper.createNotification({
                    type: 'success',
                    message: translate('system.notification.insertPropertySuccess.title'),
                    title: translate('system.notification.insertPropertySuccess.message')
                });
            })
            .catch((reason) => {
                setCreateProperties(undefined);

                params.onError && params.onError();

                NotificationHelper.createNotification({
                    type: 'error',
                    message: translate('system.notification.insertProperty.title'),
                    title: translate('system.notification.insertProperty.message')
                });
            })
            .finally(() => {
                setCreatePropertiesLoading(false);

                params.onAlways && params.onAlways();
            });
    }

    interface IRetrieveProperties extends IHookCallback {
        id: string;
        onRetrieveSuccess: (properties: Property) => void;
    }

    const [retrieveProperties, setRetrieveProperties] = useState<Property>();
    const [retrievePropertiesLoading, setRetrievePropertiesLoading] = useState<boolean>(false);

    const handleRetrieveProperties = async (params: IRetrieveProperties) => {
        setRetrievePropertiesLoading(true);
        const clientApi = await PropertyApiClient(instance);
        clientApi.getProperty(params.id)
            .then((response: any) => {
                setRetrieveProperties(response.data);
                params.onRetrieveSuccess(response.data);
            })
            .catch((reason) => {
                setRetrieveProperties(undefined);
                params.onError && params.onError();
                NotificationHelper.createNotification({
                    type: 'error',
                    message: translate('system.notification.getProperty.title'),
                    title: translate('system.notification.getProperty.message')
                });
            })
            .finally(() => {
                setRetrievePropertiesLoading(false);
                params.onAlways && params.onAlways();
            });
    }

    interface IUpdateProperties extends IHookCallback {
        id: string;
        properties: Property;
    }

    const [updatePropertiesLoading, setUpdatePropertiesLoading] = useState<boolean>(false);

    const handleUpdateProperties = async (params: IUpdateProperties) => {
        setUpdatePropertiesLoading(true);

        const clientApi = await PropertyApiClient(instance);

        clientApi.updateProperty(params.id, params.properties)
            .then((response) => {
                params.onSuccess && params.onSuccess();

                NotificationHelper.createNotification({
                    type: 'success',
                    message: translate('system.notification.updatePropertySuccess.title'),
                    title: translate('system.notification.updatePropertySuccess.message')
                });
            })
            .catch((reason) => {
                params.onError && params.onError();

                NotificationHelper.createNotification({
                    type: 'error',
                    message: translate('system.notification.updateProperty.title'),
                    title: translate('system.notification.updateProperty.message')
                });
            })
            .finally(() => {
                setUpdatePropertiesLoading(false);

                params.onAlways && params.onAlways();
            });
    }

    interface IDeleteProperties extends IHookCallback {
        id: string;
    }

    const [deletePropertiesLoading, setDeletePropertiesLoading] = useState<boolean>(false);

    const handleDeleteProperties = async (params: IDeleteProperties) => {
        setDeletePropertiesLoading(true);

        const clientApi = await PropertyApiClient(instance);

        clientApi.deleteProperty(params.id)
            .then((response) => {
                params.onSuccess && params.onSuccess();

                NotificationHelper.createNotification({
                    type: 'success',
                    message: translate('system.notification.deletePropertySuccess.title'),
                    title: translate('system.notification.deletePropertySuccess.message')
                });
            })
            .catch((reason) => {
                params.onError && params.onError();

                NotificationHelper.createNotification({
                    type: 'error',
                    message: translate('system.notification.deleteProperty.title'),
                    title: translate('system.notification.deleteProperty.message')
                });
            })
            .finally(() => {
                setDeletePropertiesLoading(false);

                params.onAlways && params.onAlways();
            });
    }

    return {
        handleProperties,
        properties,
        propertiesLoading,
        // CRUD Operations
        handleCreateProperties,
        createProperties,
        createPropertiesLoading,
        handleRetrieveProperties,
        retrieveProperties,
        retrievePropertiesLoading,
        handleUpdateProperties,
        updatePropertiesLoading,
        handleDeleteProperties,
        deletePropertiesLoading
    }
}
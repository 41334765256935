import React from 'react';

import { Callout, IconButton, Text, TooltipHost } from '@fluentui/react';
import { useBoolean, useId } from '@fluentui/react-hooks';

import { useClassNames } from './iai-preview-column.classNames';

interface IIAIPreviewColumn {
    heading?: string;
    content?: string | JSX.Element | JSX.Element[];
    tooltip?: string;
}

const IAIPreviewColumn: React.FunctionComponent<IIAIPreviewColumn> = ({
    heading,
    content,
    tooltip
}) => {
    const {
        calloutContainer,
        calloutBeakCurtain
    } = useClassNames();

    const [previewCalloutVisible, { toggle: togglePreviewCalloutVisible }] = useBoolean(false);

    const iconButtonId = useId('iai-preview-column');
    const headingId = useId('preview-column-heading');
    const contentId = useId('preview-column-content');

    return (
        <div className="text-center">
            <TooltipHost content={tooltip}>
                <IconButton
                    id={iconButtonId}
                    iconProps={{ iconName: 'MiniExpand' }}
                    onClick={togglePreviewCalloutVisible}
                />
            </TooltipHost>

            {previewCalloutVisible && (
                <Callout
                    className={calloutContainer}
                    styles={{
                        beak: calloutBeakCurtain,
                        beakCurtain: calloutBeakCurtain,
                        calloutMain: calloutBeakCurtain
                    }}
                    ariaLabelledBy={headingId}
                    ariaDescribedBy={contentId}
                    role="dialog"
                    gapSpace={0}
                    target={`#${iconButtonId}`}
                    onDismiss={togglePreviewCalloutVisible}
                    setInitialFocus
                >
                    <Text id={headingId} block variant="xLarge" className="mb-3">
                        {heading}
                    </Text>
                    <Text id={contentId} block variant="small">
                        {content}
                    </Text>
                </Callout>
            )}
        </div>
    );
}

export default IAIPreviewColumn;
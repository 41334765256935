import { useSelector } from 'react-redux';

import { getTheme, mergeStyleSets } from '@fluentui/react';

import { RootState } from '../../../../../redux/application/redux-store';

interface IWebchatMessageClassNames {
    messageContainer: string;
    messageContainerAlt: string;
}

export const useClassNames = (): IWebchatMessageClassNames => {
    const applicationTheme = getTheme();

    const iaiSystem = useSelector((state: RootState) => state.system);

    return mergeStyleSets({
        messageContainer: {
            background: applicationTheme.palette.themePrimary,
            color: applicationTheme.semanticColors.inputText
        },
        messageContainerAlt: {
            background: iaiSystem.theme === 'light'
                ? applicationTheme.palette.themeLighter
                : applicationTheme.palette.themeTertiary,
            color: applicationTheme.semanticColors.inputText
        }
    });
}
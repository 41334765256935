export const TRANSLATION_KEYS = {
    Heading: "common.webchat.meetingModal.heading",
    SecondaryHeading: "common.webchat.meetingModal.secondaryHeading",
    MeetingDateLabel: "common.webchat.meetingModal.meetingDateLabel",
    MeetingDatePlaceholder: "common.webchat.meetingModal.meetingDatePlaceholder",
    MeetingDateErrorMessage: "common.webchat.meetingModal.meetingDateErrorMessage",
    StartTimeLabel: "common.webchat.meetingModal.startTimeLabel",
    StartTimePlaceholder: "common.webchat.meetingModal.startTimePlaceholder",
    StartTimeErrorMessage: "common.webchat.meetingModal.startTimeErrorMessage",
    EndTimeLabel: "common.webchat.meetingModal.endTimeLabel",
    EndTimePlaceholder: "common.webchat.meetingModal.endTimePlaceholder",
    EndTimeErrorMessage: "common.webchat.meetingModal.endTimeErrorMessage",
    SubjectLabel: "common.webchat.meetingModal.subjectLabel",
    SubjectPlaceholder: "common.webchat.meetingModal.subjectPlaceholder",
    SubjectErrorMessage: "common.webchat.meetingModal.subjectErrorMessage",
    ConfirmText: "common.webchat.meetingModal.confirmText",
    DismissText: "common.dismissText",

    MeetingScheduleIntroText: "common.webchat.meetingModal.meetingScheduleIntro",
    MeetingScheduleFromToText: "common.webchat.meetingModal.meetingScheduleFromTo",
    MeetingSchedulePressLink: "common.webchat.meetingModal.meetingSchedulePressLink",
    MeetingScheduleLinkText: "common.webchat.meetingModal.meetingScheduleLinkText",
};
import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { IDatePickerProps, DatePicker } from '@fluentui/react';

import { datePickerStrings } from '../../../../utils/constants';
import { DateTimeEnchanter } from '../../../../utils/data-enchanters';

interface IIAIDatePicker extends IDatePickerProps {
    name: string;
}

const IAIDatePicker: React.FunctionComponent<IIAIDatePicker> = ({
    name,
    ...other
}) => {
    const {
        control
    } = useFormContext();

    const {
        i18n
    } = useTranslation();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <DatePicker
                    {...field}
                    strings={datePickerStrings(i18n.language)}
                    formatDate={(date) => DateTimeEnchanter.formatStandardDate(date, i18n.language)}
                    onSelectDate={(date) => date && field.onChange(date)}
                    {...other}
                />
            )}
        />
    );
}

export default IAIDatePicker;
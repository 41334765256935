import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { useMsal } from "@azure/msal-react";

import { TicketFilteringCriteria, TicketStatusEnum } from '../../../api-client';
import { IHookCallback } from '../../../common/models';
import { TicketApiClient } from '../../../providers/api-provider';
import { NotificationHelper } from '../../../utils/notification';
import helpers from '../../../utils/helpers/helpers';

export const useReport = () => {
    const {
        instance
    } = useMsal();

    const {
        t: translate
    } = useTranslation();

    interface IReports {
        totalCount: number;
        openCount: number;
        openPercentage: number;
        workingCount: number;
        workingPercentage: number;
        pendingCount: number;
        pendingPercentage: number;
        closedCount: number;
        closedPercentage: number;
    }

    interface ITicketReports extends IHookCallback {
        criteria?: TicketFilteringCriteria;
    }

    const [ticketReports, setTicketReports] = useState<IReports>();
    const [ticketReportsLoading, setTicketReportsLoading] = useState<boolean>(false);

    const handleTicketReports = async (params: ITicketReports) => {
        setTicketReportsLoading(true);

        const clientApi = await TicketApiClient(instance, helpers.getStoredI18Lang());

        clientApi.getFilteredTickets(params.criteria)
            .then((response) => {
                const totalCount = response.data.length;
                const openCount = _.filter(response.data, entry => entry.status === TicketStatusEnum.Open).length;
                const workingCount = _.filter(response.data, entry => entry.status === TicketStatusEnum.Working).length;
                const pendingCount = _.filter(response.data, entry => entry.status === TicketStatusEnum.Pending).length;
                const closedCount = _.filter(response.data, entry => entry.status === TicketStatusEnum.Closed).length;

                setTicketReports({
                    totalCount: totalCount,
                    openCount: openCount,
                    openPercentage: ((openCount || 0) / (totalCount || 0)) * 100,
                    workingCount: workingCount,
                    workingPercentage: ((workingCount || 0) / (totalCount || 0)) * 100,
                    pendingCount: pendingCount,
                    pendingPercentage: ((pendingCount || 0) / (totalCount || 0)) * 100,
                    closedCount: closedCount,
                    closedPercentage: ((closedCount || 0) / (totalCount || 0)) * 100
                });
            })
            .catch((reason) => {
                setTicketReports({
                    totalCount: 0,
                    openCount: 0,
                    openPercentage: 0,
                    workingCount: 0,
                    workingPercentage: 0,
                    pendingCount: 0,
                    pendingPercentage: 0,
                    closedCount: 0,
                    closedPercentage: 0
                });

                NotificationHelper.createNotification({
                    type: 'error',
                    title: translate('reportPage.notification.ticketReports.title'),
                    message: translate('reportPage.notification.ticketReports.message')
                });
            })
            .finally(() => {
                setTicketReportsLoading(false);
            });
    }

    return {
        handleTicketReports,
        ticketReports,
        ticketReportsLoading
    }
}
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import _ from 'lodash';

import {
    DefaultButton,
    PrimaryButton,
    Panel,
    PanelType,
    Separator,
    Stack
} from '@fluentui/react';

import { EventReceiver } from '../../../../../api-client';
import { TRANSLATION_KEYS } from './localization/translation-keys';

import { FormProvider, IAIComboBox } from '../../../../../common/components/IAIForm';

interface FormValuesProps extends EventReceiver { }

interface IEventReceiverDrawer {
    eventReceiver?: EventReceiver;
    disabled?: boolean;
    drawerVisible?: boolean;
    onDrawerConfirm: (dataItem: EventReceiver) => void;
    onDrawerDismiss: () => void;
};

const EventReceiverDrawer: React.FunctionComponent<IEventReceiverDrawer> = ({
    eventReceiver,
    disabled,
    drawerVisible,
    onDrawerConfirm,
    onDrawerDismiss
}) => {
    const {
        t: translate
    } = useTranslation();

    //#region Form-Configuration

    const formDataSchema = Yup.object().shape({
        onCreating: Yup.array(),
        onCreated: Yup.array(),
        onUpdating: Yup.array(),
        onUpdated: Yup.array(),
        onDeleting: Yup.array(),
        onDeleted: Yup.array()
    });

    const defaultValues = useMemo(
        () => ({
            onCreating: eventReceiver?.onCreating || [],
            onCreated: eventReceiver?.onCreated || [],
            onUpdating: eventReceiver?.onUpdating || [],
            onUpdated: eventReceiver?.onUpdated || [],
            onDeleting: eventReceiver?.onDeleting || [],
            onDeleted: eventReceiver?.onDeleted || []
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [eventReceiver]
    );

    const methods = useForm<FormValuesProps>({ resolver: yupResolver(formDataSchema), defaultValues });

    const {
        reset,
        handleSubmit,
        formState: { isSubmitting }
    } = methods;

    useEffect(() => {
        reset(defaultValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventReceiver]);

    //#endregion

    const _handleDismiss = () => {
        reset(defaultValues);
        onDrawerDismiss();
    }

    const _handleSubmit = (data: FormValuesProps) => {
        let solutionData = {
            ...eventReceiver,
            ...data
        };

        onDrawerConfirm(solutionData);
    }

    const disableCondition = disabled || isSubmitting;

    return (
        <FormProvider methods={methods}>
            <Panel
                type={PanelType.smallFixedFar}
                headerText={translate(TRANSLATION_KEYS.Heading)}
                isOpen={drawerVisible}
                isBlocking={true}
                isFooterAtBottom={true}
                onDismiss={_handleDismiss}
                onRenderFooterContent={() => (
                    <div className="text-end">
                        <DefaultButton
                            disabled={disableCondition}
                            iconProps={{ iconName: 'Cancel' }}
                            styles={{ root: { marginRight: 10 } }}
                            onClick={_handleDismiss}
                        >
                            {translate(TRANSLATION_KEYS.DismissText)}
                        </DefaultButton>
                        <PrimaryButton
                            type="submit"
                            disabled={disableCondition}
                            iconProps={{ iconName: 'CheckMark' }}
                            onClick={handleSubmit(_handleSubmit)}
                        >
                            {translate(TRANSLATION_KEYS.ConfirmText)}
                        </PrimaryButton>
                    </div>
                )}
            >
                <Separator />

                <Stack tokens={{ childrenGap: 15 }}>
                    <IAIComboBox
                        name="onCreating"
                        label={translate(TRANSLATION_KEYS.OnCreatingLabel)}
                        placeholder={translate(TRANSLATION_KEYS.OnCreatingPlaceholder)}
                        allowFreeform
                        multiSelect
                        options={_.map(eventReceiver?.onCreating, entry => ({ key: entry, text: entry })) || []}
                    />

                    <IAIComboBox
                        name="onCreated"
                        label={translate(TRANSLATION_KEYS.OnCreatedLabel)}
                        placeholder={translate(TRANSLATION_KEYS.OnCreatedPlaceholder)}
                        allowFreeform
                        multiSelect
                        options={_.map(eventReceiver?.onCreated, entry => ({ key: entry, text: entry })) || []}
                    />

                    <IAIComboBox
                        name="onUpdating"
                        label={translate(TRANSLATION_KEYS.OnUpdatingLabel)}
                        placeholder={translate(TRANSLATION_KEYS.OnUpdatingPlaceholder)}
                        allowFreeform
                        multiSelect
                        options={_.map(eventReceiver?.onUpdating, entry => ({ key: entry, text: entry })) || []}
                    />

                    <IAIComboBox
                        name="onUpdated"
                        label={translate(TRANSLATION_KEYS.OnUpdatedLabel)}
                        placeholder={translate(TRANSLATION_KEYS.OnUpdatedPlaceholder)}
                        allowFreeform
                        multiSelect
                        options={_.map(eventReceiver?.onUpdated, entry => ({ key: entry, text: entry })) || []}
                    />

                    <IAIComboBox
                        name="onDeleting"
                        label={translate(TRANSLATION_KEYS.OnDeletingLabel)}
                        placeholder={translate(TRANSLATION_KEYS.OnDeletingPlaceholder)}
                        allowFreeform
                        multiSelect
                        options={_.map(eventReceiver?.onDeleting, entry => ({ key: entry, text: entry })) || []}
                    />

                    <IAIComboBox
                        name="onDeleted"
                        label={translate(TRANSLATION_KEYS.OnDeletedLabel)}
                        placeholder={translate(TRANSLATION_KEYS.OnDeletedPlaceholder)}
                        allowFreeform
                        multiSelect
                        options={_.map(eventReceiver?.onDeleted, entry => ({ key: entry, text: entry })) || []}
                    />
                </Stack>
            </Panel>
        </FormProvider>
    );
}

export default EventReceiverDrawer;
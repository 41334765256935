import React from 'react';
import _ from 'lodash';

import { useClassNames } from './iai-accordion.classNames';

import './iai-accordion.scss';

interface IIAIAccordion { }

const IAIAccordion: React.FunctionComponent<IIAIAccordion> = ({
    children
}) => {
    const {
        accordionContainer
    } = useClassNames();

    return (
        <div className={`iai-accordion-container ${accordionContainer}`}>
            {children}
        </div>
    );
}

export default IAIAccordion;
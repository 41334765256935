import React, { useEffect, useMemo } from 'react';
import debounce from 'lodash.debounce';

import { ICommandBarItemProps, CommandBar, SearchBox, Stack } from '@fluentui/react';

import { useClassNames } from './iai-command-bar.classNames';

interface IIAICommandBar {
    commands?: ICommandBarItemProps[];
    hiddenCommands?: ICommandBarItemProps[];
    sideCommands?: ICommandBarItemProps[];
    onSearch: (searchKey?: string) => void;
};

const IAICommandBar: React.FunctionComponent<IIAICommandBar> = ({
    commands,
    hiddenCommands,
    sideCommands,
    onSearch
}) => {
    const {
        commandBarContainer
    } = useClassNames();

    const debouncedSearch = useMemo(() => {
        return debounce(onSearch, 500);
    }, []);

    useEffect(() => {
        return () => {
            debouncedSearch.cancel();
        };
    });

    return (
        <Stack
            grow
            horizontal
            horizontalAlign="space-between"
            verticalAlign="center"
            className={`iai-command-bar-container ${commandBarContainer}`}
        >
            <Stack grow={1} horizontalAlign="start">
                {commands && (
                    <CommandBar
                        ariaLabel="IAI details list commands"
                        items={commands}
                        primaryGroupAriaLabel="Main commands"
                        overflowItems={hiddenCommands}
                        styles={{ root: { padding: '0 12px' } }}
                    />
                )}
            </Stack>
            <Stack grow={1} horizontal horizontalAlign="end" verticalAlign="center">
                {sideCommands && (
                    <CommandBar
                        ariaLabel="IAI details list commands"
                        items={sideCommands}
                        primaryGroupAriaLabel="Additional commands"
                        styles={{ root: { padding: '0 12px' } }}
                    />
                )}

                <SearchBox
                    placeholder="Search"
                    styles={{ root: { width: 300 } }}
                    onChange={(_, value) => onSearch(value)}
                    onSearch={onSearch}
                />
            </Stack>
        </Stack>
    );
};

export default IAICommandBar;
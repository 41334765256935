import { PartialTheme } from '@fluentui/react/lib/Theme';

const defaultTheme: PartialTheme = {
    palette: {
        themePrimary: '#e83782',
        themeLighterAlt: '#fef6fa',
        themeLighter: '#fbddea',
        themeLight: '#f8c0d7',
        themeTertiary: '#f183b1',
        themeSecondary: '#eb4e8f',
        themeDarkAlt: '#d13274',
        themeDark: '#b02a62',
        themeDarker: '#821f48',
        neutralLighterAlt: '#faf9f8',
        neutralLighter: '#f3f2f1',
        neutralLight: '#edebe9',
        neutralQuaternaryAlt: '#e1dfdd',
        neutralQuaternary: '#d0d0d0',
        neutralTertiaryAlt: '#c8c6c4',
        neutralTertiary: '#a19f9d',
        neutralSecondary: '#605e5c',
        neutralPrimaryAlt: '#3b3a39',
        neutralPrimary: '#323130',
        neutralDark: '#201f1e',
        black: '#000000',
        white: '#ffffff',
    }
}

const intranetAiTheme: PartialTheme = {
    palette: {
        themePrimary: '#e83782',
        themeLighterAlt: '#fef6fa',
        themeLighter: '#fbddea',
        themeLight: '#f8c0d7',
        themeTertiary: '#f183b1',
        themeSecondary: '#eb4e8f',
        themeDarkAlt: '#d13274',
        themeDark: '#b02a62',
        themeDarker: '#821f48',
        neutralLighterAlt: '#faf9f8',
        neutralLighter: '#f3f2f1',
        neutralLight: '#edebe9',
        neutralQuaternaryAlt: '#e1dfdd',
        neutralQuaternary: '#d0d0d0',
        neutralTertiaryAlt: '#c8c6c4',
        neutralTertiary: '#a19f9d',
        neutralSecondary: '#605e5c',
        neutralPrimaryAlt: '#3b3a39',
        neutralPrimary: '#323130',
        neutralDark: '#201f1e',
        black: '#000000',
        white: '#ffffff'
    }
}

const intranetAiDarkTheme: PartialTheme = {
    palette: {
        themePrimary: '#e83782',
        themeLighterAlt: '#090205',
        themeLighter: '#250915',
        themeLight: '#461127',
        themeTertiary: '#8b214e',
        themeSecondary: '#cc3172',
        themeDarkAlt: '#ea4a8d',
        themeDark: '#ee649e',
        themeDarker: '#f28bb6',
        neutralLighterAlt: '#2b2928',
        neutralLighter: '#333231',
        neutralLight: '#41403e',
        neutralQuaternaryAlt: '#4a4846',
        neutralQuaternary: '#514f4d',
        neutralTertiaryAlt: '#6f6c6a',
        neutralTertiary: '#c8c8c8',
        neutralSecondary: '#d0d0d0',
        neutralSecondaryAlt: '#d0d0d0',
        neutralPrimaryAlt: '#dadada',
        neutralPrimary: '#ffffff',
        neutralDark: '#f4f4f4',
        black: '#f8f8f8',
        white: '#201f1e'
    }
};

export class ApplicationTheme {
    public static THEMES = {
        IAI_APPLICATION: "IAI_APPLICATION"
    };

    public static partialTheme = (theme: string): PartialTheme => {
        switch (theme) {
            case ApplicationTheme.THEMES.IAI_APPLICATION: return intranetAiTheme;

            default: return defaultTheme;
        }
    };

    public static partialThemeDark = (theme: string): PartialTheme => {
        switch (theme) {
            case ApplicationTheme.THEMES.IAI_APPLICATION: return intranetAiDarkTheme;

            default: return defaultTheme;
        }
    };

    public static themeResourcesRoot = (theme: string): string => {
        switch (theme) {
            case ApplicationTheme.THEMES.IAI_APPLICATION: return 'intranet.ai';

            default: return 'iai';
        }
    };
}
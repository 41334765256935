import React from 'react';

interface IIAIStringColumn {
    align: 'start' | 'center' | 'end';
    text?: string;
}

const IAIStringColumn: React.FunctionComponent<IIAIStringColumn> = ({
    align = 'start',
    text
}) => (
    <div className={`text-${align}`}>
        {text}
    </div>
);

export default IAIStringColumn;
import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import {
    DefaultButton,
    Label,
    PrimaryButton,
    Stack,
    TooltipHost
} from '@fluentui/react';

import { TicketAttachmentFile } from '../../../../../../../api-client';
import { DataEnchanter } from '../../../../../../../utils/data-enchanters';
import { TRANSLATION_KEYS } from './localization/translation-keys';
import { useViewAttachment } from './hooks/useViewAttachment';

import { IAIShimmer } from '../../../../../../../common/components/IAIShimmer';

interface IViewAttachment {
    disabled?: boolean;
    ticketId?: string;
}

const ViewAttachment: React.FunctionComponent<IViewAttachment> = ({
    disabled,
    ticketId
}) => {
    const {
        handleRetrieveTicketAttachments,
        ticketAttachments,
        ticketAttachmentsLoading,
        handleRetrieveTicketAttachment,
        ticketAttachmentLoading,
        handleUploadTicketAttachment,
        uploadTicketAttachmentLoading
    } = useViewAttachment();

    useEffect(() => {
        !!ticketId && handleRetrieveTicketAttachments({ id: ticketId });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ticketId]);

    //#region Upload-Management

    const [attachmentsUpload, setAttachmentsUpload] = useState<any[]>([]);

    useEffect(() => {
        if (attachmentsUpload && !!attachmentsUpload.length) {
            _.each(attachmentsUpload, entry => {
                if (entry) {
                    DataEnchanter.fileToB64(entry).then((response) => {
                        let attachmentFile: TicketAttachmentFile = {
                            fileContent: response.split(',')[1],
                            fileName: entry.name,
                            fileSize: entry.size,
                            fileType: entry.type
                        };

                        if (ticketId) {
                            handleUploadTicketAttachment({
                                id: ticketId,
                                attachment: attachmentFile,
                                onSuccess: () => handleRetrieveTicketAttachments({ id: ticketId })
                            });
                        }
                    });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attachmentsUpload]);

    const _handleDrop = useCallback((acceptedFiles) => {
        setAttachmentsUpload(acceptedFiles);
    }, []);

    const {
        getRootProps,
        getInputProps
    } = useDropzone({ onDrop: _handleDrop });

    //#endregion

    const {
        t: translate
    } = useTranslation();

    return (
        <div className={`ticket-attachment-container`}>
            <Label className="mb-2">
                {translate(TRANSLATION_KEYS.AttachmentLabel)}
            </Label>

            <IAIShimmer type={'shimmer-heading'} loading={ticketAttachmentsLoading}>
                <Stack horizontal tokens={{ childrenGap: 15 }}>
                    {ticketId && (
                        _.map(ticketAttachments, (dataItem, dataIndex) => (
                            <TooltipHost
                                key={dataIndex}
                                content={`${dataItem.fileName} (${DataEnchanter.fileSize(dataItem.fileSize)})`}
                            >
                                <DefaultButton
                                    id={'attachment_button_' + dataIndex}
                                    iconProps={{ iconName: DataEnchanter.fileIcon(dataItem.fileName || '') }}
                                    styles={{ root: { maxWidth: 35, minWidth: 0 } }}
                                    onClick={() => (
                                        handleRetrieveTicketAttachment({
                                            id: ticketId,
                                            attachmentId: dataItem.id || '',
                                            fileName: dataItem.fileName || ''
                                        })
                                    )}
                                />
                            </TooltipHost>
                        ))
                    )}

                    <div {...getRootProps({ className: 'dropzone' })}>
                        <input
                            {...getInputProps()}
                            disabled={disabled || ticketAttachmentLoading || uploadTicketAttachmentLoading}
                        />

                        <PrimaryButton
                            disabled={disabled || ticketAttachmentLoading || uploadTicketAttachmentLoading}
                            iconProps={{ iconName: 'Add' }}
                            styles={{ root: { maxWidth: 35, minWidth: 0 } }}
                        />
                    </div>
                </Stack>
            </IAIShimmer>
        </div>
    );
}

export default ViewAttachment;
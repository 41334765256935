import { getTheme, mergeStyleSets } from '@fluentui/react';

interface ISolutionPageClassNames {
    solutionPageContainer: string;
}

export const useClassNames = (): ISolutionPageClassNames => {
    const applicationTheme = getTheme();

    return mergeStyleSets({
        solutionPageContainer: {
            background: applicationTheme.semanticColors.bodyBackground
        }
    });
}
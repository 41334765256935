import { getTheme, mergeStyleSets } from '@fluentui/react';

interface IIAICommandBarClassNames {
    commandBarContainer: string;
}

export const useClassNames = (): IIAICommandBarClassNames => {
    const applicationTheme = getTheme();

    return mergeStyleSets({
        commandBarContainer: {
            padding: 12,
            paddingLeft: 0
        }
    });
}
import React from 'react';
import { useTranslation } from 'react-i18next';

import { DateTimeEnchanter } from '../../../../utils/data-enchanters';

interface IIAIDatetimeColumn {
    align: 'start' | 'center' | 'end';
    datetime?: string;
}

const IAIDatetimeColumn: React.FunctionComponent<IIAIDatetimeColumn> = ({
    align = 'start',
    datetime
}) => {
    const {
        i18n
    } = useTranslation();

    return (
        <div className={`text-${align}`}>
            {DateTimeEnchanter.formatStandardDate(datetime, i18n.language)}
        </div>
    );
}

export default IAIDatetimeColumn;
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { Stack } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';

import { EventReceiver, ProfileRoleEnum } from '../../../api-client';
import { RootState } from '../../../redux/application/redux-store';
import { RouterSettings } from '../../../utils/router';
import { TRANSLATION_KEYS } from './localization/translation-keys';
import { useEventReceiver } from './hooks/useEventReceiver';
import { useClassNames } from './setting-page.classNames';

import { IAIApplicationLayout } from '../../../common/layouts/IAIApplicationLayout';
import { IAIWidgetIcon } from '../../../common/components/IAIWidget';
import ApplicationSettingsDrawer from './components/ApplicationSettingsDrawer/application-settings-drawer';
import EventReceiverDrawer from './components/EventReceiverDrawer/event-receiver-drawer';

const SettingPage: React.FunctionComponent = () => {
    const {
        settingPageContainer
    } = useClassNames();

    const {
        handleEventReceivers,
        eventReceivers,
        eventReceiversLoading,
        handleCreateEventReceiver,
        createEventReceiverLoading,
        handleUpdateEventReceiver,
        updateEventReceiverLoading
    } = useEventReceiver();

    useEffect(() => {
        handleEventReceivers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //#region Event-Receivers-Management

    const [eventReceiverDrawerVisible, { toggle: toggleEventReceiverDrawerVisible }] = useBoolean(false);

    const _handleUpsertEventReceiver = (dataItem: EventReceiver) => {
        const onUpsertSuccess = () => {
            toggleEventReceiverDrawerVisible();

            handleEventReceivers();
        };

        dataItem.id
            ?
            handleUpdateEventReceiver({ id: dataItem.id, eventReceiver: dataItem, onSuccess: onUpsertSuccess })
            :
            handleCreateEventReceiver({ eventReceiver: dataItem, onSuccess: onUpsertSuccess });
    }

    //#endregion

    const [settingsDrawerVisible, { toggle: toggleSettingsDrawerVisible }] = useBoolean(false);

    const {
        t: translate
    } = useTranslation();

    const SETTING_WIDGETS = [
        {
            icon: 'EngineeringGroup',
            title: translate(TRANSLATION_KEYS.WidgetGroupsHeading),
            tooltip: translate(TRANSLATION_KEYS.WidgetGroupsTooltip),
            to: RouterSettings.PATHS.dashboard.setting.groups,
            roles: [
                ProfileRoleEnum.Administrator
            ]
        },
        {
            icon: 'BullseyeTarget',
            title: translate(TRANSLATION_KEYS.WidgetTopicsHeading),
            tooltip: translate(TRANSLATION_KEYS.WidgetTopicsTooltip),
            to: RouterSettings.PATHS.dashboard.setting.topics,
            roles: [
                ProfileRoleEnum.Administrator
            ]
        },
        {
            icon: 'ArrangeBringToFront',
            title: translate(TRANSLATION_KEYS.WidgetAreaHeading),
            tooltip: translate(TRANSLATION_KEYS.WidgetAreaTooltip),
            to: RouterSettings.PATHS.dashboard.setting.areas,
            roles: [
                ProfileRoleEnum.Administrator
            ]
        },
        {
            icon: 'AllApps',
            title: translate(TRANSLATION_KEYS.WidgetCustomPropertyHeading),
            tooltip: translate(TRANSLATION_KEYS.WidgetCustomPropertyTooltip),
            to: RouterSettings.PATHS.dashboard.setting.customProperties,
            roles: [
                ProfileRoleEnum.Administrator
            ]
        },
        {
            icon: 'Flag',
            title: translate(TRANSLATION_KEYS.WidgetNoticeHeading),
            tooltip: translate(TRANSLATION_KEYS.WidgetNoticeTooltip),
            to: RouterSettings.PATHS.dashboard.setting.notices,
            roles: [
                ProfileRoleEnum.Administrator
            ]
        },
        {
            icon: 'ScheduleEventAction',
            title: translate(TRANSLATION_KEYS.WidgetReceiverHeading),
            tooltip: translate(TRANSLATION_KEYS.WidgetReceiverTooltip),
            roles: [
                ProfileRoleEnum.Administrator
            ],
            onClick: toggleEventReceiverDrawerVisible
        },
        {
            icon: 'Settings',
            title: translate(TRANSLATION_KEYS.WidgetSettingHeading),
            tooltip: translate(TRANSLATION_KEYS.WidgetSettingTooltip),
            roles: [
                ProfileRoleEnum.Administrator,
                ProfileRoleEnum.DepartmentAgent,
                ProfileRoleEnum.DepartmentManager,
                ProfileRoleEnum.User
            ],
            onClick: toggleSettingsDrawerVisible
        }
    ];

    const iaiAccountInfo = useSelector((state: RootState) => state.account);

    const _handleWidgetsByRole = () => (
        _.filter(SETTING_WIDGETS, entry => (
            _.some(entry.roles, entry => entry === iaiAccountInfo.role)
        ))
    );

    return (
        <IAIApplicationLayout
            icon={'Settings'}
            heading={translate(TRANSLATION_KEYS.Heading)}
            secondaryHeading={translate(TRANSLATION_KEYS.SecondaryHeading)}
        >
            <div className={`mt-3 ${settingPageContainer}`}>
                <Stack
                    horizontal
                    horizontalAlign="center"
                    tokens={{ childrenGap: 15 }}
                    wrap
                    styles={{ inner: { justifyContent: 'start' } }}
                >
                    {_.map(_handleWidgetsByRole(), (dataItem, dataIndex) => (
                        <IAIWidgetIcon key={dataIndex} {...dataItem} />
                    ))}
                </Stack>

                <EventReceiverDrawer
                    eventReceiver={eventReceivers && eventReceivers[0]}
                    disabled={(
                        eventReceiversLoading ||
                        createEventReceiverLoading ||
                        updateEventReceiverLoading
                    )}
                    drawerVisible={eventReceiverDrawerVisible}
                    onDrawerConfirm={_handleUpsertEventReceiver}
                    onDrawerDismiss={toggleEventReceiverDrawerVisible}
                />

                <ApplicationSettingsDrawer
                    drawerVisible={settingsDrawerVisible}
                    onDrawerDismiss={toggleSettingsDrawerVisible}
                />
            </div>
        </IAIApplicationLayout>
    );
}

export default SettingPage;
import { useDispatch } from 'react-redux';

import { initAccountInfo } from '../../redux/slices/Account/accountSlice';
import {
    initAgents,
    initAreas,
    initGroups,
    initDepartments,
    initTheme
} from '../../redux/slices/System/systemSlice';

import { LocalStorageHelper, SessionStorageHelper } from '../../utils/storage';

const persistingConfiguration = [
    {
        key: SessionStorageHelper.STORAGE_KEYS.IAI_SYSTEM_ACCOUNT,
        initializer: initAccountInfo
    },
    {
        key: SessionStorageHelper.STORAGE_KEYS.IAI_SYSTEM_AGENT,
        initializer: initAgents
    },
    {
        key: SessionStorageHelper.STORAGE_KEYS.IAI_SYSTEM_AREA,
        initializer: initAreas
    },
    {
        key: SessionStorageHelper.STORAGE_KEYS.IAI_SYSTEM_GROUP,
        initializer: initGroups
    },
    {
        key: SessionStorageHelper.STORAGE_KEYS.IAI_SYSTEM_DEPARTMENT,
        initializer: initDepartments
    },
    {
        key: LocalStorageHelper.STORAGE_KEYS.IAI_THEME,
        initializer: initTheme
    }
];

export const usePersistReduxStore = () => {
    const dispatch = useDispatch();

    const handlePersistingStore = () => {
        persistingConfiguration.forEach((configuration) => {
            let persistedSessionData: any = SessionStorageHelper.retrieveItem(configuration.key);
            if (persistedSessionData) {
                dispatch(configuration.initializer(persistedSessionData));
            }

            let persistedLocalData: any = LocalStorageHelper.retrieveItem(configuration.key);
            if (persistedLocalData) {
                dispatch(configuration.initializer(persistedLocalData));
            }
        });
    }

    return {
        handlePersistingStore
    }
}
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import _ from 'lodash';

import { ContextualMenuItemType } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';

import { Solution } from '../../api-client';
import { TRANSLATION_KEYS } from './localization/translation-keys';
import { useSolution } from '../../common/hooks/useSolution';
import { useClassNames } from './solution-page.classNames';

import { IAIApplicationLayout } from '../../common/layouts/IAIApplicationLayout';
import {
    IAIDetailsList,
    IAIContextualMenuColumn,
    IAIDatetimeColumn,
    IAIPreviewColumn,
    IAIStringColumn
} from '../../common/components/IAITable';
import DeleteDialog from './components/DeleteDialog/delete-dialog';
import FormDrawer from './components/FormDrawer/form-drawer';

const SolutionPage: React.FunctionComponent = () => {
    const {
        solutionPageContainer
    } = useClassNames();

    const {
        handleSolutions,
        solutions,
        solutionsLoading,
        handleCreateSolution,
        createSolutionLoading,
        handleUpdateSolution,
        updateSolutionLoading,
        handleDeleteSolution,
        deleteSolutionLoading
    } = useSolution();

    useEffect(() => {
        handleSolutions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const _handleSearchPredicate = (dataItem: Solution, searchKey: string) => (
        _.includes(dataItem.title?.toLowerCase(), searchKey) ||
        _.includes(dataItem.description?.toLowerCase(), searchKey)
    );

    //#region Form-Management

    const [solutionDrawerVisible, { toggle: toggleSolutionDrawerVisible }] = useBoolean(false);
    const [deleteSolutionVisible, { toggle: toggleDeleteSolutionVisible }] = useBoolean(false);
    const [delegateSolution, setDelegateSolution] = useState<Solution>();

    const _handleCreateAction = () => {
        setDelegateSolution(undefined);
        toggleSolutionDrawerVisible();
    }

    const _handleUpsertAction = (dataItem: Solution) => {
        setDelegateSolution(dataItem);
        toggleSolutionDrawerVisible();
    }

    const _handleDismissUpsertAction = () => {
        setDelegateSolution(undefined);
        toggleSolutionDrawerVisible();
    }

    const _handleDeleteAction = (dataItem: Solution) => {
        setDelegateSolution(dataItem);
        toggleDeleteSolutionVisible();
    }

    const _handleDismissDeleteAction = () => {
        setDelegateSolution(undefined);
        toggleDeleteSolutionVisible();
    }

    const _handleUpsertSolution = (dataItem: Solution) => {
        const onUpsertSuccess = () => {
            _handleDismissUpsertAction();

            handleSolutions();
        };

        dataItem.id
            ?
            handleUpdateSolution({ id: dataItem.id, solution: dataItem, onSuccess: onUpsertSuccess })
            :
            handleCreateSolution({ solution: dataItem, onSuccess: onUpsertSuccess });
    }

    const _handleDeleteSolution = () => {
        if (delegateSolution?.id) {
            handleDeleteSolution({
                id: delegateSolution.id,
                onSuccess: () => {
                    _handleDismissDeleteAction();

                    handleSolutions();
                }
            });
        }
    }

    //#endregion

    const {
        t: translate
    } = useTranslation();

    return (
        <IAIApplicationLayout
            icon={'Lightbulb'}
            heading={translate(TRANSLATION_KEYS.Heading)}
            secondaryHeading={translate(TRANSLATION_KEYS.SecondaryHeading)}
        >
            <div className={solutionPageContainer}>
                <IAIDetailsList
                    dataSource={solutions || []}
                    dataColumns={[
                        {
                            key: 'faq__preview',
                            fieldName: '',
                            name: '',
                            minWidth: 50,
                            maxWidth: 50,
                            onRender: (dataItem: Solution) => (
                                <IAIPreviewColumn
                                    heading={dataItem.title}
                                    content={parse(dataItem.description || '')}
                                    tooltip={translate(TRANSLATION_KEYS.ColumnPreviewTooltip)}
                                />
                            )
                        },
                        {
                            key: 'faq__title',
                            fieldName: 'title',
                            name: translate(TRANSLATION_KEYS.ColumnTitleLabel),
                            minWidth: 200,
                            isResizable: true,
                            onRender: (dataItem: Solution) => (
                                <IAIStringColumn align="start" text={dataItem.title} />
                            )
                        },
                        {
                            key: 'faq__department',
                            fieldName: 'department',
                            name: translate(TRANSLATION_KEYS.ColumnDepartmentLabel),
                            minWidth: 200,
                            isResizable: true,
                            onRender: (dataItem: Solution) => (
                                <IAIStringColumn align="start" text={dataItem.department?.displayName} />
                            )
                        },
                        {
                            key: 'faq__createdAt',
                            fieldName: 'createdAt',
                            name: translate(TRANSLATION_KEYS.ColumnCreatedAtLabel),
                            minWidth: 100,
                            maxWidth: 100,
                            onRender: (dataItem: Solution) => (
                                <IAIDatetimeColumn align="start" datetime={dataItem.createdAt} />
                            )
                        },
                        {
                            key: 'faq__actions',
                            fieldName: '',
                            name: '',
                            minWidth: 50,
                            maxWidth: 50,
                            onRender: (dataItem: Solution, dataIndex) => (
                                <IAIContextualMenuColumn
                                    menuItems={[
                                        {
                                            key: `menuItem__update_${dataIndex}`,
                                            iconProps: { iconName: 'EditCreate' },
                                            text: translate(TRANSLATION_KEYS.ColumnActionCommandUpdateLabel),
                                            title: translate(TRANSLATION_KEYS.ColumnActionCommandUpdateTooltip),
                                            onClick: () => _handleUpsertAction(dataItem)
                                        },
                                        { key: `menuItem__divider_${dataIndex}`, itemType: ContextualMenuItemType.Divider },
                                        {
                                            key: `menuItem__delete_${dataIndex}`,
                                            iconProps: { iconName: 'Delete' },
                                            text: translate(TRANSLATION_KEYS.ColumnActionCommandDeleteLabel),
                                            title: translate(TRANSLATION_KEYS.ColumnActionCommandDeleteTooltip),
                                            onClick: () => _handleDeleteAction(dataItem)
                                        }
                                    ]}
                                />
                            )
                        }
                    ]}
                    dataLoading={solutionsLoading}
                    commands={[
                        {
                            key: '',
                            disabled: solutionsLoading,
                            text: translate(TRANSLATION_KEYS.CommandCreateLabel),
                            title: translate(TRANSLATION_KEYS.CommandCreateTooltip),
                            iconProps: { iconName: 'Add' },
                            onClick: _handleCreateAction
                        }
                    ]}
                    emptyContentLabel={translate(TRANSLATION_KEYS.DetailsListEmptyContentLabel)}
                    onItemInvoked={(dataItem: Solution) => _handleUpsertAction(dataItem)}
                    onSearchPredicate={_handleSearchPredicate}
                />

                <FormDrawer
                    solution={delegateSolution}
                    disabled={solutionsLoading || createSolutionLoading || updateSolutionLoading}
                    drawerVisible={solutionDrawerVisible}
                    onDrawerConfirm={_handleUpsertSolution}
                    onDrawerDismiss={_handleDismissUpsertAction}
                />

                <DeleteDialog
                    disabled={solutionsLoading || deleteSolutionLoading}
                    modalVisible={deleteSolutionVisible}
                    onModalConfirm={_handleDeleteSolution}
                    onModalDismiss={_handleDismissDeleteAction}
                />
            </div>
        </IAIApplicationLayout>
    );
}

export default SolutionPage;
import { TicketStatusEnum, TicketPriorityEnum } from '../../api-client';
import { ModeEnumTranslations, ParameterName, PriorityEnumTranslations, StatusEnumTranslations } from '../constants/ticket-changelogs-constants';

export class DataEnchanter {

    public static textByStatusEnum = (status: string) => {
        switch (status) {
            case TicketStatusEnum.Open: return 'common.ticketStatus.statusOptionOpen';
            case TicketStatusEnum.Pending: return 'common.ticketStatus.statusOptionPending';
            case TicketStatusEnum.Working: return 'common.ticketStatus.statusOptionWorking';
            case TicketStatusEnum.Closed: return 'common.ticketStatus.statusOptionClosed';

            default: return 'common.unknown';
        }
    };

    public static textByPriorityEnum = (priority: string) => {
        switch (priority) {
            case TicketPriorityEnum.High:
                return 'common.ticketPriority.priorityOptionHigh';
            case TicketPriorityEnum.Low:
                return 'common.ticketPriority.priorityOptionLow';
            case TicketPriorityEnum.Medium:
                return 'common.ticketPriority.priorityOptionMedium';

            default:
                return 'common.unknown';
        }
    };

    public static getChangelogParameterPreText = (parameterName?: string) => {
        switch (parameterName) {
            case ParameterName.GENERICTICKET: return 'common.changelogInsert';
            case ParameterName.COMMENT: return 'common.changelogInsert';
            case ParameterName.MODE: return 'common.changelogUpdate';
            case ParameterName.AREA: return 'common.changelogUpdate';
            case ParameterName.DEPARTMENT: return 'common.changelogUpdate';
            case ParameterName.AGENT: return 'common.changelogUpdate';
            case ParameterName.STATUS: return 'common.changelogUpdate';
            case ParameterName.PRIORITY: return 'common.changelogUpdate';
            case ParameterName.NOTE: return 'common.changelogInsert';

            default: return '';
        }
    };

    public static getChangelogParameterNameText = (parameterName?: string) => {
        switch (parameterName) {
            case ParameterName.GENERICTICKET: return 'common.genericTicket';
            case ParameterName.COMMENT: return 'common.comment';
            case ParameterName.MODE: return 'common.mode';
            case ParameterName.AREA: return 'common.area';
            case ParameterName.DEPARTMENT: return 'common.department';
            case ParameterName.AGENT: return 'common.agent';
            case ParameterName.STATUS: return 'common.status';
            case ParameterName.PRIORITY: return 'common.priority';
            case ParameterName.NOTE: return 'common.note';

            default: return 'common.unknown';
        }
    };

    public static getChangelogOldAndNewValText = (parameterName?: string, value?: string): string => {
        const savedValue = value ?? '';
        switch (parameterName) {
            case ParameterName.MODE: return ModeEnumTranslations[savedValue] ?? 'common.unknown';
            case ParameterName.AREA: return  savedValue;
            case ParameterName.DEPARTMENT: return savedValue;
            case ParameterName.AGENT: return savedValue;
            case ParameterName.STATUS: return StatusEnumTranslations[savedValue] ?? 'common.unknown';
            case ParameterName.PRIORITY: return PriorityEnumTranslations[savedValue] ?? 'common.unknown';

            default: return 'common.unknown';
        }
    };

    public static fileSize = (size: any, systemUnit = false, decimalPoint = 1) => {
        const thresh = systemUnit ? 1000 : 1024;

        if (Math.abs(size) < thresh) {
            return size + ' B';
        }

        const units = systemUnit
            ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
            : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
        let u = -1;
        const r = 10 ** decimalPoint;

        do {
            size /= thresh;
            ++u;
        } while (Math.round(Math.abs(size) * r) / r >= thresh && u < units.length - 1);


        return size.toFixed(decimalPoint) + ' ' + units[u];
    };

    public static fileIcon = (fileName: string) => {
        if (!fileName) {
            return 'TextDocument';
        }

        const extension = fileName.split('.')?.pop()?.toLowerCase();

        switch (extension) {
            case 'pdf':
                return 'PDF';
            case 'png':
            case 'jpg':
            case 'jpeg':
            case 'gif':
                return 'FileImage';
            case 'xls':
            case 'xlsx':
            case 'csv':
                return 'ExcelDocument';
            case 'doc':
            case 'docx':
            case 'gdoc':
                return 'WordDocument';
            case 'ppt':
            case 'pptx':
                return 'PowerPointDocument';
            default:
                return 'TextDocument';
        }
    };

    public static fileToB64 = (file: any) => new Promise<string>((resolve: any, reject: any) => {
        const reader = new FileReader();

        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    public static uuid = () => {
        var d = new Date().getTime();
        var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g,
            c => {
                var r = (d + Math.random() * 16) % 16 | 0;
                d = Math.floor(d / 16);
                return (c === 'x' ? r : (r & 0x7 | 0x8)).toString(16);
            });

        return uuid;
    };

    public static colorblindByStatusEnum = (status: string) => {
        switch (status) {
            case TicketStatusEnum.Open: return ['#807448', '#936F2A', '#DB3638'];
            case TicketStatusEnum.Pending: return ['#8091CE', '#7492D8', '#2BA2AF'];
            case TicketStatusEnum.Working: return ['#EAD029', '#FFC663', '#f7C1D0'];
            case TicketStatusEnum.Closed: return ['#5F5400', '#6B5015', '#325A61'];

            default: return ['#000000'];
        }
    };

    public static colorByStatusEnum = (status: string) => {
        switch (status) {
            case TicketStatusEnum.Open: return '#DC3333';
            case TicketStatusEnum.Pending: return '#469CD6';
            case TicketStatusEnum.Working: return '#EAD124';
            case TicketStatusEnum.Closed: return '#136100';

            default: return '#000000';
        }
    };
}
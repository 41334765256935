import React, { useEffect, useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { DefaultButton, PrimaryButton, Stack } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';

import { TicketComment } from '../../../../../api-client';
import { TRANSLATION_KEYS } from './localization/translation-keys';
import { useTicketDetails } from '../../../../../pages/Ticket/TicketDetailsPage/hooks/useTicketDetails';
import { useMsTeams } from './components/MeetingModal/hooks/useMsTeams';

import { FormProvider, IAIEditor } from '../../../IAIForm';
import MeetingModal from './components/MeetingModal/meeting-modal';
import SolutionModal from './components/SolutionModal/solution-modal';
import { DetailContext } from '../../../../../pages/Ticket/TicketDetailsPage/ticket-details-page';
import { ApplicationSettings } from '../../../../../utils/application';

interface FormValuesProps extends TicketComment { }

interface IWebchatForm {
    isAdmin?: boolean;
    disabled?: boolean;
    onSendMessage: (message: TicketComment) => void;
}

const WebchatForm: React.FunctionComponent<IWebchatForm> = ({
    isAdmin,
    disabled,
    onSendMessage
}) => {
    const {
        t: translate
    } = useTranslation();

    const{
        handleSchedulerMeeting
    } = useMsTeams();

    const ticketDetail = useContext(DetailContext);

    //#region Form-Configuration

    const formDataSchema = Yup.object().shape({
        message: Yup.string().required(translate(TRANSLATION_KEYS.MessageErrorMessage))
    });

    const defaultValues = useMemo(
        () => ({
            message: ''
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const methods = useForm<FormValuesProps>({ resolver: yupResolver(formDataSchema), defaultValues });

    const {
        reset,
        handleSubmit,
        setValue,
        formState: { isSubmitting }
    } = methods;

    useEffect(() => {
        reset(defaultValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //#endregion

    const _handleSubmit = (data: FormValuesProps) => {
        let commentData = {
            ...data
        };

        onSendMessage(commentData);
        reset(defaultValues);
    }

    const [meetingModalVisible, { toggle: toggleMeetingModalVisible }] = useBoolean(false);
    const [solutionModalVisible, { toggle: toggleSolutionModalVisible }] = useBoolean(false);

    const disableCondition = disabled || isSubmitting;

    const enableTeamsScheduler = ApplicationSettings.IAI_APP_ENABLE_TEAMS_SCHEDULER;

    return (
        <FormProvider methods={methods}>
            <Stack tokens={{ childrenGap: 15 }}>
                <IAIEditor
                    name="message"
                    label={translate(TRANSLATION_KEYS.MessageLabel)}
                    placeholder={translate(TRANSLATION_KEYS.MessagePlaceholder)}
                    required
                    readOnly={disableCondition}
                />

                <Stack horizontal horizontalAlign={'space-between'} tokens={{ childrenGap: 15 }}>
                    <Stack horizontal tokens={{ childrenGap: 15 }}>
                        {isAdmin && (
                            <React.Fragment>
                                <DefaultButton
                                    disabled={disableCondition}
                                    iconProps={{ iconName: 'Lightbulb' }}
                                    title={translate(TRANSLATION_KEYS.SolutionModalTooltip)}
                                    styles={{ root: { maxWidth: 35, minWidth: 0 } }}
                                    onClick={toggleSolutionModalVisible}
                                />
                                <DefaultButton
                                    disabled={disableCondition}
                                    iconProps={{ iconName: 'TeamsLogo' }}
                                    title={translate(enableTeamsScheduler ? TRANSLATION_KEYS.TeamsMeetingTooltip : TRANSLATION_KEYS.MeetingModalTooltip)}
                                    styles={{ root: { maxWidth: 35, minWidth: 0 } }}
                                    onClick={() => {
                                      if(enableTeamsScheduler) {
                                        handleSchedulerMeeting(ticketDetail);
                                      }  else {
                                        toggleMeetingModalVisible();
                                      } 
                                    }}
                                />
                            </React.Fragment>
                        )}
                    </Stack>

                    <PrimaryButton
                        type="submit"
                        disabled={disableCondition}
                        iconProps={{ iconName: 'Send' }}
                        styles={{ root: { alignSelf: 'end' } }}
                        onClick={handleSubmit(_handleSubmit)}
                    >
                        {translate(TRANSLATION_KEYS.ConfirmText)}
                    </PrimaryButton>
                </Stack>
            </Stack>

            {isAdmin && (
                <React.Fragment>
                    <MeetingModal
                        modalVisible={meetingModalVisible}
                        onModalConfirm={(dataItem) => setValue('message', dataItem)}
                        onModalDismiss={toggleMeetingModalVisible}
                    />
                    <SolutionModal
                        modalVisible={solutionModalVisible}
                        onModalConfirm={(dataItem) => setValue('message', dataItem)}
                        onModalDismiss={toggleSolutionModalVisible}
                    />
                </React.Fragment>
            )}
        </FormProvider>
    );
}

export default WebchatForm;
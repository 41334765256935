import { useSelector } from 'react-redux';

import { getTheme, mergeStyleSets } from '@fluentui/react';

import { RootState } from '../../../../redux/application/redux-store';

interface IIAIWidgetClassNames {
    widgetContainer: string;
    widgetIcon: string;
}

export const useClassNames = (): IIAIWidgetClassNames => {
    const applicationTheme = getTheme();

    const iaiSystem = useSelector((state: RootState) => state.system);

    return mergeStyleSets({
        widgetContainer: {
            background: applicationTheme.palette.neutralLight,
            '&:hover': {
                background: iaiSystem.theme === 'light'
                    ? applicationTheme.palette.themeLighter
                    : applicationTheme.palette.themeTertiary
            },
            '&:hover .widget-icon': {
                color: iaiSystem.theme === 'light'
                    ? applicationTheme.palette.themePrimary
                    : applicationTheme.semanticColors.inputText
            }
        },
        widgetIcon: {
            color: applicationTheme.palette.themePrimary
        }
    });
}
import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import _ from 'lodash';

import {
    DefaultButton,
    PrimaryButton,
    Panel,
    PanelType,
    Separator,
    Stack
} from '@fluentui/react';

import { RootState } from '../../../../redux/application/redux-store';
import { Solution } from '../../../../api-client';
import { TRANSLATION_KEYS } from './localization/translation-keys';

import { FormProvider, IAIDropdown, IAITextField, IAIEditor } from '../../../../common/components/IAIForm';

interface FormValuesProps extends Solution {
    departmentKey: string;
};

interface IFormDrawer {
    solution?: Solution;
    disabled?: boolean;
    drawerVisible?: boolean;
    onDrawerConfirm: (dataItem: Solution) => void;
    onDrawerDismiss: () => void;
};

const FormDrawer: React.FunctionComponent<IFormDrawer> = ({
    solution,
    disabled,
    drawerVisible,
    onDrawerConfirm,
    onDrawerDismiss
}) => {
    const {
        t: translate
    } = useTranslation();

    //#region Form-Configuration

    const formDataSchema = Yup.object().shape({
        title: Yup.string().required(translate(TRANSLATION_KEYS.TitleErrorMessage)),
        departmentKey: Yup.string().required(translate(TRANSLATION_KEYS.DepartmentErrorMessage)),
        description: Yup.string().required(translate(TRANSLATION_KEYS.DescriptionErrorMessage))
    });

    const defaultValues = useMemo(
        () => ({
            title: solution?.title || '',
            departmentKey: solution?.department?.key || '',
            description: solution?.description || ''
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [solution]
    );

    const methods = useForm<FormValuesProps>({ resolver: yupResolver(formDataSchema), defaultValues });

    const {
        reset,
        handleSubmit,
        formState: { isSubmitting }
    } = methods;

    useEffect(() => {
        reset(defaultValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [solution]);

    //#endregion

    const _handleDismiss = () => {
        reset(defaultValues);
        onDrawerDismiss();
    }

    const iaiSystem = useSelector((state: RootState) => state.system);

    const _handleSubmit = (data: FormValuesProps) => {
        let solutionData = {
            ...solution,
            ...data,
            department: _.find(iaiSystem.departments, entry => entry.key === data.departmentKey)
        };

        onDrawerConfirm(solutionData);
    }

    const disableCondition = disabled || !iaiSystem.departmentsInitialized || isSubmitting;
    const departmentOptions = _.map(iaiSystem.departments, entry => ({ key: entry.key!, text: entry.text! }));

    return (
        <FormProvider methods={methods}>
            <Panel
                type={PanelType.medium}
                headerText={translate(TRANSLATION_KEYS.Heading)}
                isOpen={drawerVisible}
                isBlocking={true}
                isFooterAtBottom={true}
                onDismiss={_handleDismiss}
                onRenderFooterContent={() => (
                    <div className="text-end">
                        <DefaultButton
                            disabled={disableCondition}
                            iconProps={{ iconName: 'Cancel' }}
                            styles={{ root: { marginRight: 10 } }}
                            onClick={_handleDismiss}
                        >
                            {translate(TRANSLATION_KEYS.DismissText)}
                        </DefaultButton>
                        <PrimaryButton
                            type="submit"
                            disabled={disableCondition}
                            iconProps={{ iconName: 'CheckMark' }}
                            onClick={handleSubmit(_handleSubmit)}
                        >
                            {translate(TRANSLATION_KEYS.ConfirmText)}
                        </PrimaryButton>
                    </div>
                )}
            >
                <Separator />

                <Stack tokens={{ childrenGap: 15 }}>
                    <IAITextField
                        name="title"
                        label={translate(TRANSLATION_KEYS.TitleLabel)}
                        placeholder={translate(TRANSLATION_KEYS.TitlePlaceholder)}
                        required
                        disabled={disableCondition}
                    />

                    <IAIDropdown
                        name="departmentKey"
                        label={translate(TRANSLATION_KEYS.DepartmentLabel)}
                        placeholder={translate(TRANSLATION_KEYS.DepartmentPlaceholder)}
                        required
                        disabled={disableCondition}
                        options={departmentOptions}
                    />

                    <IAIEditor
                        name="description"
                        label={translate(TRANSLATION_KEYS.DescriptionLabel)}
                        placeholder={translate(TRANSLATION_KEYS.DescriptionPlaceholder)}
                        required
                        readOnly={disableCondition}
                    />
                </Stack>
            </Panel>
        </FormProvider>
    );
}

export default FormDrawer;
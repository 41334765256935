import React from 'react';

import { DataEnchanter } from '../../../../utils/data-enchanters';
import { useTranslation } from 'react-i18next';

interface IIAIPriorityColumn {
    align: 'start' | 'center' | 'end';
    priority?: string;
}

const IAIPriorityColumn: React.FunctionComponent<IIAIPriorityColumn> = ({
    align = 'start',
    priority
}) => {
    const {
        t: translate
    } = useTranslation();
    
    return (
    <div className={`text-${align}`}>
        {translate(DataEnchanter.textByPriorityEnum(priority || ''))}
    </div>
    );
};

export default IAIPriorityColumn;
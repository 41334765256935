import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import _ from 'lodash';

import { Solution } from '../../../../../../../api-client';
import { TRANSLATION_KEYS } from './localization/translation-keys';
import { useSolution } from '../../../../../../hooks/useSolution';

import {
    IAIDetailsList,
    IAIActionColumn,
    IAIDatetimeColumn,
    IAIPreviewColumn,
    IAIStringColumn
} from '../../../../../IAITable';
import { IAIModal } from '../../../../../IAIModal';

interface ISolutionModal {
    modalVisible?: boolean;
    onModalConfirm: (description?: string) => void;
    onModalDismiss: () => void;
};

const SolutionModal: React.FunctionComponent<ISolutionModal> = ({
    modalVisible,
    onModalConfirm,
    onModalDismiss
}) => {
    const {
        t: translate
    } = useTranslation();

    const {
        handleSolutions,
        solutions,
        solutionsLoading
    } = useSolution();

    useEffect(() => {
        handleSolutions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const _handleSearchPredicate = (dataItem: Solution, searchKey: string) => (
        _.includes(dataItem.title?.toLowerCase(), searchKey) ||
        _.includes(dataItem.description?.toLowerCase(), searchKey)
    );

    const _handleSelectAction = (dataItem: Solution) => {
        onModalConfirm(dataItem.description);
        onModalDismiss();
    }

    return (
        <IAIModal
            isOpen={modalVisible}
            heading={translate(TRANSLATION_KEYS.Heading)}
            secondaryHeading={translate(TRANSLATION_KEYS.SecondaryHeading)}
            isBlocking={true}
            onDismiss={onModalDismiss}
        >
            <IAIDetailsList
                dataSource={solutions || []}
                dataColumns={[
                    {
                        key: 'faq__preview',
                        fieldName: '',
                        name: '',
                        minWidth: 50,
                        maxWidth: 50,
                        onRender: (dataItem: Solution) => (
                            <IAIPreviewColumn
                                heading={dataItem.title}
                                content={parse(dataItem.description || '')}
                                tooltip={translate(TRANSLATION_KEYS.ColumnPreviewTooltip)}
                            />
                        )
                    },
                    {
                        key: 'faq__title',
                        fieldName: 'title',
                        name: translate(TRANSLATION_KEYS.ColumnTitleLabel),
                        minWidth: 200,
                        isResizable: true,
                        onRender: (dataItem: Solution) => (
                            <IAIStringColumn align="start" text={dataItem.title} />
                        )
                    },
                    {
                        key: 'faq__department',
                        fieldName: 'department',
                        name: translate(TRANSLATION_KEYS.ColumnDepartmentLabel),
                        minWidth: 200,
                        isResizable: true,
                        onRender: (dataItem: Solution) => (
                            <IAIStringColumn align="start" text={dataItem.department?.displayName} />
                        )
                    },
                    {
                        key: 'faq__createdAt',
                        fieldName: 'createdAt',
                        name: translate(TRANSLATION_KEYS.ColumnCreatedAtLabel),
                        minWidth: 100,
                        maxWidth: 100,
                        onRender: (dataItem: Solution) => (
                            <IAIDatetimeColumn align="start" datetime={dataItem.createdAt} />
                        )
                    },
                    {
                        key: 'faq__actions',
                        fieldName: '',
                        name: '',
                        minWidth: 50,
                        maxWidth: 50,
                        onRender: (dataItem: Solution) => (
                            <IAIActionColumn
                                iconName="CommentAdd"
                                tooltip={translate(TRANSLATION_KEYS.ColumnActionCommandAddTooltip)}
                                onActionClick={() => _handleSelectAction(dataItem)}
                            />
                        )
                    }
                ]}
                dataLoading={solutionsLoading}
                emptyContentLabel={translate(TRANSLATION_KEYS.DetailsListEmptyContentLabel)}
                onItemInvoked={(dataItem: Solution) => _handleSelectAction(dataItem)}
                onSearchPredicate={_handleSearchPredicate}
            />
        </IAIModal>
    );
}

export default SolutionModal;
import React, { useCallback, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { PrimaryButton, Spinner, Text } from '@fluentui/react';

import { _loginRequest, _msalConfig } from '../../providers/authentication/auth-provider';
import { useAuthContext } from '../../contexts/auth-context';
import { ApplicationSettings } from '../../utils/application';
import { RouterSettings } from '../../utils/router';
import { SessionStorageHelper as SSH } from '../../utils/storage';
import { TRANSLATION_KEYS } from './localization/translation-keys';
import { useLogin } from './hooks/useLogin';
import { useClassNames } from './login-page.classNames';

import './login-page.scss';
import * as MsTeams from "@microsoft/teams-js";

import { IAILoginLayout } from '../../common/layouts/IAILoginLayout';
import { getContextEntitySubUrl } from '../../providers/authentication/ms-teams-provider';

const LoginPage: React.FunctionComponent = () => {
    const {
        instance,
        inProgress,
    } = useMsal();

    const {
        isAuthenticated,
        contextType
    } = useAuthContext();

    const {
        handleGraphInfo,
        handleGraphAccountPhoto,
        handleAccountInfo,
        accountInfoLoading
    } = useLogin();

    const navigate = useNavigate();
    const location = useLocation();

    const onAccountSuccess = useCallback(() => {
        handleGraphInfo();
        handleGraphAccountPhoto();

        if(MsTeams.pages.isSupported()) {            
            getContextEntitySubUrl().then((subUrlResponse) => {
                    if(subUrlResponse) {
                        setTimeout(() => {
                            navigate(RouterSettings.PATHS.dashboard.ticket.dynamic(subUrlResponse));
                        }, 10)
                    } else {
                        setTimeout(() => {
                            navigate(RouterSettings.PATHS.dashboard.root);
                        }, 10)
                    }
                })
                .catch(() => {
                    navigate(RouterSettings.PATHS.dashboard.solution.root);
                });
        } else {
            const redirectUrl = SSH.retrieveItem(SSH.STORAGE_KEYS.IAI_SYSTEM_REDIRECT);
            if (!!redirectUrl && location.pathname !== redirectUrl) {
                SSH.deleteItem(SSH.STORAGE_KEYS.IAI_SYSTEM_REDIRECT);
                setTimeout(() => {
                    navigate(redirectUrl);
                }, 10)
            } else {
                setTimeout(() => {
                    navigate(RouterSettings.PATHS.dashboard.root);
                }, 10)
            }
        }
    }, []);

    useEffect(() => {
        if (!isAuthenticated) {
            if (location.pathname !== SSH.retrieveItem(SSH.STORAGE_KEYS.IAI_SYSTEM_REDIRECT) && location.pathname !== RouterSettings.ROUTES.find(x => x.routeKey === RouterSettings.ROUTES_KEYS.IAI_APP_LOGIN)?.path)
                SSH.createItem(SSH.STORAGE_KEYS.IAI_SYSTEM_REDIRECT, location.pathname)
        };

        isAuthenticated && handleAccountInfo({ onSuccess: onAccountSuccess });
    }, [isAuthenticated]);

    const {
        loginPageContainer,
        loginPageSpinner
    } = useClassNames();

    const {
        t: translate
    } = useTranslation();

    return (
        <IAILoginLayout>
            <div className={`login-page-container ${loginPageContainer}`}>
                <div className="row h-100 justify-content-center">
                    <div className="col-12 align-self-center">
                        <img
                            alt="Intranet AI - Service Desk"
                            src={`/application-themes/${ApplicationSettings.IAI_APP_THEME_ROOT}/app-logo.webp`}
                            className="login-page-logo"
                        />

                        {(
                            inProgress === InteractionStatus.Startup ||
                            inProgress === InteractionStatus.HandleRedirect ||
                            inProgress === InteractionStatus.Login ||
                            accountInfoLoading
                        ) &&
                            <React.Fragment>
                                <Text block variant="mediumPlus" className="login-page-heading">
                                    {translate(TRANSLATION_KEYS.LoginProgress)}
                                </Text>

                                <Spinner className={loginPageSpinner} />
                            </React.Fragment>
                        }

                        {(
                            inProgress !== InteractionStatus.Startup &&
                            inProgress !== InteractionStatus.HandleRedirect &&
                            inProgress !== InteractionStatus.Login &&
                            !accountInfoLoading
                        ) &&
                            <React.Fragment>
                                <Text block variant="mediumPlus" className="login-page-heading">
                                    {translate(TRANSLATION_KEYS.Heading)}
                                </Text>

                                <PrimaryButton
                                    className="login-page-button"
                                    disabled={isAuthenticated}
                                    iconProps={{ iconName: 'OfficeLogo' }}
                                    text={translate(TRANSLATION_KEYS.ButtonText)}
                                    onClick={() => instance.loginRedirect(_loginRequest)}
                                />
                            </React.Fragment>
                        }
                    </div>
                </div>
            </div>
        </IAILoginLayout>
    );
}

export default LoginPage;
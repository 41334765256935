import React, { useEffect, useState } from 'react';
import _ from 'lodash';

import {
    IColumn,
    ICommandBarItemProps,
    SelectionMode,
    ShimmeredDetailsList,
    Stack
} from '@fluentui/react';

import { useClassNames } from './iai-details-list.classNames';

import { IAIEmptyContent } from '../../IAIEmptyContent';
import IAICommandBar from './components/IAICommandBar/iai-command-bar';
import IAIPagination from './components/IAIPagination/iai-pagination';

interface IIAIDetailsList {
    dataSource: any[];
    dataColumns?: IColumn[];
    dataLoading?: boolean;
    commands?: ICommandBarItemProps[];
    hiddenCommands?: ICommandBarItemProps[];
    sideCommands?: ICommandBarItemProps[];
    emptyContentLabel: string;
    onItemInvoked?: (item?: any, index?: number, ev?: Event) => void;
    onSearchPredicate?: (item: any, searchKey: string) => boolean;
    // TODO: PAGING CONFIG
    // totalCount?: number;
    // hasPrev?: boolean;
    // hasNext?: boolean;
    // page: number;
    // limit: number;
};

const IAIDetailsList: React.FunctionComponent<IIAIDetailsList> = ({
    dataSource,
    dataColumns,
    dataLoading,
    commands,
    hiddenCommands,
    sideCommands,
    emptyContentLabel,
    onItemInvoked,
    onSearchPredicate
}) => {
    const [legacyDataset, setLegacyDataset] = useState<any[]>([...dataSource]);

    useEffect(() => {
        setLegacyDataset(dataSource);
    }, [dataSource]);

    const _handleDefaultSearch = (searchKey?: string) => {
        if (!searchKey || !onSearchPredicate) {
            setLegacyDataset(dataSource);
            return;
        }

        let filteredItems = _.filter(dataSource, entry => onSearchPredicate(entry, searchKey.toLowerCase()));

        setLegacyDataset(filteredItems);
    }

    const {
        columnCell
    } = useClassNames();

    return (
        <Stack>
            <IAICommandBar
                commands={commands}
                hiddenCommands={hiddenCommands}
                sideCommands={sideCommands}
                onSearch={_handleDefaultSearch}
            />

            <ShimmeredDetailsList
                setKey="IAI_DetailsList"
                items={legacyDataset}
                columns={_.map(dataColumns, entry => ({ ...entry, className: columnCell }))}
                selectionMode={SelectionMode.none}
                enableShimmer={dataLoading}
                ariaLabelForGrid="IAI details list"
                ariaLabelForShimmer="Content loading completed"
                /**/
                onColumnHeaderClick={() => { }}
                onItemInvoked={onItemInvoked}
            />

            {!legacyDataset.length && !dataLoading && (
                <IAIEmptyContent title={emptyContentLabel} />
            )}

            {/* <IAIPagination
                page={page}
                totalCount={totalCount}
                limit={limit}
                hasPrev={hasPrev}
                hasNext={hasNext}
                onFistPageClick={() => { }}
                onPrevPageClick={() => { }}
                onNextPageClick={() => { }}
                onLastPageClick={() => { }}
            /> */}
        </Stack>
    );
};

export default IAIDetailsList;
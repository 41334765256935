export const TRANSLATION_KEYS = {
    Heading: "homepage.heading",
    SecondaryHeading: "homepage.secondaryHeading",
    WidgetSectionHeading: "homepage.widgetSectionHeading",
    WidgetNewTicketHeading: "homepage.widgetNewTicketHeading",
    WidgetNewTicketTooltip: "homepage.widgetNewTicketTooltip",
    WidgetTicketHeading: "homepage.widgetTicketHeading",
    WidgetTicketTooltip: "homepage.widgetTicketTooltip",
    WidgetSolutionHeading: "homepage.widgetSolutionHeading",
    WidgetSolutionTooltip: "homepage.widgetSolutionTooltip",
    WidgetReportHeading: "homepage.widgetReportHeading",
    WidgetReportTooltip: "homepage.widgetReportTooltip",
    NoticeSectionHeading: "homepage.noticeSectionHeading",
    NoticeEmpty: "homepage.noticeEmpty"
};
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useMsal } from "@azure/msal-react";

import { Department } from '../../../../api-client';
import { IHookCallback } from '../../../../common/models';
import { DepartmentApiClient } from '../../../../providers/api-provider';
import { NotificationHelper } from '../../../../utils/notification';
import { useSystem } from '../../../../common/hooks/useSystem';

export const useTopic = () => {
    const {
        instance
    } = useMsal();

    const {
        t: translate
    } = useTranslation();

    const [topics, setTopics] = useState<Department[]>();
    const [topicsLoading, setTopicsLoading] = useState<boolean>(false);

    const handleTopics = async () => {
        setTopicsLoading(true);

        const clientApi = await DepartmentApiClient(instance);

        clientApi.getDepartments()
            .then((response) => {
                setTopics(response.data);
            })
            .catch((reason) => {
                setTopics([]);

                NotificationHelper.createNotification({
                    type: 'error',
                    title: translate('settingPage.topicPage.notification.retrieveAll.title'),
                    message: translate('settingPage.topicPage.notification.retrieveAll.message')
                });
            })
            .finally(() => {
                setTopicsLoading(false);
            });
    }

    // CRUD Operations

    const {
        handleSystemTopics
    } = useSystem();

    interface ICreateTopic extends IHookCallback {
        topic: Department;
    }

    const [createTopicLoading, setCreateTopicLoading] = useState<boolean>(false);

    const handleCreateTopic = async (params: ICreateTopic) => {
        setCreateTopicLoading(true);

        const clientApi = await DepartmentApiClient(instance);

        clientApi.insertDepartment(params.topic)
            .then((response) => {
                handleSystemTopics();

                params.onSuccess && params.onSuccess();

                NotificationHelper.createNotification({
                    type: 'success',
                    title: translate('settingPage.topicPage.notification.createSuccess.title'),
                    message: translate('settingPage.topicPage.notification.createSuccess.message')
                });
            })
            .catch((reason) => {
                params.onError && params.onError();

                NotificationHelper.createNotification({
                    type: 'error',
                    title: translate('settingPage.topicPage.notification.createError.title'),
                    message: translate('settingPage.topicPage.notification.createError.message')
                });
            })
            .finally(() => {
                setCreateTopicLoading(false);

                params.onAlways && params.onAlways();
            });
    }

    interface IRetrieveTopic extends IHookCallback {
        id: string;
        onRetrieveSuccess: (topic: Department) => void;
    }

    const [retrieveTopic, setRetrieveTopic] = useState<Department>();
    const [retrieveTopicLoading, setRetrieveTopicLoading] = useState<boolean>(false);

    const handleRetrieveTopic = async (params: IRetrieveTopic) => {
        setRetrieveTopicLoading(true);

        const clientApi = await DepartmentApiClient(instance);

        clientApi.getDepartment(params.id)
            .then((response: any) => {
                setRetrieveTopic(response.data);

                params.onRetrieveSuccess(response.data);
            })
            .catch((reason) => {
                setRetrieveTopic(undefined);

                params.onError && params.onError();

                NotificationHelper.createNotification({
                    type: 'error',
                    title: translate('settingPage.topicPage.notification.retrieve.title'),
                    message: translate('settingPage.topicPage.notification.retrieve.message')
                });
            })
            .finally(() => {
                setRetrieveTopicLoading(false);

                params.onAlways && params.onAlways();
            });
    }

    interface IUpdateTopic extends IHookCallback {
        id: string;
        topic: Department;
    }

    const [updateTopicLoading, setUpdateTopicLoading] = useState<boolean>(false);

    const handleUpdateTopic = async (params: IUpdateTopic) => {
        setUpdateTopicLoading(true);

        const clientApi = await DepartmentApiClient(instance);

        clientApi.updateDepartment(params.id, params.topic)
            .then((response) => {
                handleSystemTopics();

                params.onSuccess && params.onSuccess();

                NotificationHelper.createNotification({
                    type: 'success',
                    title: translate('settingPage.topicPage.notification.updateSuccess.title'),
                    message: translate('settingPage.topicPage.notification.updateSuccess.message')
                });
            })
            .catch((reason) => {
                params.onError && params.onError();

                NotificationHelper.createNotification({
                    type: 'error',
                    title: translate('settingPage.topicPage.notification.updateError.title'),
                    message: translate('settingPage.topicPage.notification.updateError.message')
                });
            })
            .finally(() => {
                setUpdateTopicLoading(false);

                params.onAlways && params.onAlways();
            });
    }

    interface IDeleteTopic extends IHookCallback {
        id: string;
    }

    const [deleteTopicLoading, setDeleteTopicLoading] = useState<boolean>(false);

    const handleDeleteTopic = async (params: IDeleteTopic) => {
        setDeleteTopicLoading(true);

        const clientApi = await DepartmentApiClient(instance);

        clientApi.deleteDepartment(params.id)
            .then((response) => {
                handleSystemTopics();

                params.onSuccess && params.onSuccess();

                NotificationHelper.createNotification({
                    type: 'success',
                    title: translate('settingPage.topicPage.notification.deleteSuccess.title'),
                    message: translate('settingPage.topicPage.notification.deleteSuccess.message')
                });
            })
            .catch((reason) => {
                params.onError && params.onError();

                NotificationHelper.createNotification({
                    type: 'error',
                    title: translate('settingPage.topicPage.notification.deleteError.title'),
                    message: translate('settingPage.topicPage.notification.deleteError.message')
                });
            })
            .finally(() => {
                setDeleteTopicLoading(false);

                params.onAlways && params.onAlways();
            });
    }

    return {
        handleTopics,
        topics,
        topicsLoading,
        handleCreateTopic,
        createTopicLoading,
        handleRetrieveTopic,
        retrieveTopic,
        retrieveTopicLoading,
        handleUpdateTopic,
        updateTopicLoading,
        handleDeleteTopic,
        deleteTopicLoading
    }
}
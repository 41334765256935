import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Pivot, PivotItem } from '@fluentui/react';

import { RootState } from '../../../redux/application/redux-store';
import { DateTimeEnchanter } from '../../../utils/data-enchanters';
import { TRANSLATION_KEYS } from './localization/translation-keys';
import { useTicket } from './hooks/useTicket';
import { useClassNames } from './ticket-page.classNames';

import { IAIApplicationLayout } from '../../../common/layouts/IAIApplicationLayout';
import TicketsPivot from './components/TicketsPivot/tickets-pivot';
import PersonalTicketsPivot from './components/PersonalTicketsPivot/personal-tickets-pivot';

const TicketPage: React.FunctionComponent = () => {
    const {
        pageContainer
    } = useClassNames();

    const {
        handleTickets,
        tickets,
        ticketsLoading,
        handlePersonalTickets,
        personalTickets,
        personalTicketsLoading,
        handleDeleteTicket,
        deleteTicketLoading
    } = useTicket();

    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    }

    const queryProvider = useQuery();

    const iaiCriteria = useSelector((state: RootState) => state.ticket.criteria);

    const [lastUpdate, setLastUpdate] = useState<string>();

    const _handleDefaultTicket = () => {
        let requestedStatus = queryProvider.get("status");
        if (requestedStatus) {
            requestedStatus !== 'all'
                ?
                handleTickets({ criteria: { ...iaiCriteria, status: requestedStatus } })
                :
                handleTickets({});
        }
        else {
            handleTickets({ criteria: iaiCriteria });
        }

        handlePersonalTickets({});

        setLastUpdate(new Date().toISOString());
    };

    useEffect(() => {
        _handleDefaultTicket();

        const interval = setInterval(() => {
            _handleDefaultTicket();
        }, 300000);

        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryProvider.get("status")]);

    const _handleDeleteTicket = (id: string, onSuccess: () => void) => {
        handleDeleteTicket({
            id: id,
            onSuccess: () => {
                handleTickets({ criteria: iaiCriteria });
                handlePersonalTickets({});
                
                onSuccess();
            }
        });
    }

    const {
        i18n,
        t: translate
    } = useTranslation();

    const iaiAccount = useSelector((state: RootState) => state.account);

    return (
        <IAIApplicationLayout
            icon={'Ticket'}
            heading={translate(TRANSLATION_KEYS.Heading)}
            secondaryHeading={`${translate(TRANSLATION_KEYS.SecondaryHeading)} ${DateTimeEnchanter.formatShortTime(lastUpdate, i18n.language)}`}
        >
            <div className={pageContainer}>
                <Pivot aria-label="Tickets pivot">
                    {iaiAccount.role !== 'user' && (
                        <PivotItem
                            headerText={translate(TRANSLATION_KEYS.TicketPivotHeading)}
                            itemCount={tickets?.length}
                            itemIcon="WorkforceManagement"
                        >
                            <TicketsPivot
                                dataSource={tickets || []}
                                dataLoading={ticketsLoading}
                                onApplyCriteria={() => handleTickets({ criteria: iaiCriteria })}
                                onClearCriteria={() => handleTickets({})}
                                deleteTicketLoading={deleteTicketLoading}
                                onDeleteTicket={_handleDeleteTicket}
                            />
                        </PivotItem>
                    )}

                    <PivotItem
                        headerText={translate(TRANSLATION_KEYS.PersonalTicketPivotHeading)}
                        itemCount={personalTickets?.length}
                        itemIcon="AccountManagement"
                    >
                        <PersonalTicketsPivot
                            dataSource={personalTickets || []}
                            dataLoading={personalTicketsLoading}
                        />
                    </PivotItem>
                </Pivot>
            </div>
        </IAIApplicationLayout>
    );
}

export default TicketPage;
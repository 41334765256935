import { useSelector } from 'react-redux';

import { getTheme, mergeStyleSets } from '@fluentui/react';

import { RootState } from '../../../redux/application/redux-store';

interface IIAIPreviewColumnClassNames {
    accordionContainer: string;
    accordionItemHeading: string;
    accordionItemContent: string;
}

export const useClassNames = (): IIAIPreviewColumnClassNames => {
    const applicationTheme = getTheme();

    const iaiSystem = useSelector((state: RootState) => state.system);

    return mergeStyleSets({
        accordionContainer: {
            'input:checked+.accordion-item-heading': {
                background: iaiSystem.theme === 'light'
                    ? applicationTheme.palette.themeLighter
                    : applicationTheme.palette.themeTertiary
            }
        },
        accordionItemHeading: {
            background: applicationTheme.palette.neutralLight,
            color: iaiSystem.theme === 'light'
                ? applicationTheme.palette.themePrimary
                : applicationTheme.semanticColors.inputText,
            '&:hover': {
                background: iaiSystem.theme === 'light'
                    ? applicationTheme.palette.themeLighter
                    : applicationTheme.palette.themeTertiary
            }
        },
        accordionItemContent: {
            background: applicationTheme.palette.neutralLight
        }
    });
}
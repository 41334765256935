import { useSelector } from 'react-redux';

import { getTheme, mergeStyleSets } from '@fluentui/react';

import { RootState } from '../../../../../../redux/application/redux-store';

interface IReactQuillEditor {
    error?: boolean;
}

interface IReactQuillEditorClassNames {
    reactQuillEditorContainer: string;
    errorMessageText: string;
}

export const useClassNames = ({ error }: IReactQuillEditor): IReactQuillEditorClassNames => {
    const applicationTheme = getTheme();

    const iaiSystem = useSelector((state: RootState) => state.system);

    return mergeStyleSets({
        reactQuillEditorContainer: {
            '& .ql-toolbar.ql-snow': {
                background: applicationTheme.semanticColors.inputBackground,
                borderColor: error
                    ? applicationTheme.semanticColors.errorText
                    : applicationTheme.semanticColors.inputText
            },
            '& .ql-toolbar.ql-snow .ql-picker-label': {
                color: applicationTheme.semanticColors.inputText
            },
            '& .ql-toolbar.ql-snow .ql-picker-label:hover': {
                color: applicationTheme.palette.themePrimary
            },
            '& .ql-toolbar.ql-snow .ql-picker-label:hover .ql-stroke': {
                stroke: applicationTheme.palette.themePrimary
            },
            '& .ql-toolbar.ql-snow .ql-stroke': {
                stroke: applicationTheme.semanticColors.inputText
            },
            '& .ql-toolbar.ql-snow .ql-fill': {
                fill: applicationTheme.semanticColors.inputText
            },
            '& .ql-toolbar.ql-snow button:hover .ql-stroke': {
                stroke: applicationTheme.palette.themePrimary
            },
            '& .ql-toolbar.ql-snow button:hover .ql-fill': {
                fill: applicationTheme.palette.themePrimary
            },
            '& .ql-toolbar.ql-snow .ql-active .ql-stroke': {
                stroke: applicationTheme.palette.themePrimary
            },
            '& .ql-toolbar.ql-snow .ql-active .ql-fill': {
                fill: applicationTheme.palette.themePrimary
            },
            '& .ql-container.ql-snow': {
                ...applicationTheme.fonts.medium,
                borderColor: error
                    ? applicationTheme.semanticColors.errorText
                    : applicationTheme.semanticColors.inputText
            },
            '& .ql-disabled': {
                background: applicationTheme.semanticColors.disabledBackground
            },
            '& .ql-editor': {
                color: applicationTheme.semanticColors.inputText,
                minHeight: 250
            },
            '& .ql-editor.ql-blank::before': {
                color: iaiSystem.theme === 'light'
                    ? applicationTheme.semanticColors.disabledText
                    : applicationTheme.semanticColors.inputText,
                fontStyle: 'normal'
            }
        },
        errorMessageText: {
            color: applicationTheme.semanticColors.errorText,
            paddingTop: 5
        }
    });
}
import { getTheme, mergeStyleSets } from '@fluentui/react';

interface ISettingNoticePageClassNames {
    settingNoticePageContainer: string;
}

export const useClassNames = (): ISettingNoticePageClassNames => {
    const applicationTheme = getTheme();

    return mergeStyleSets({
        settingNoticePageContainer: {
            background: applicationTheme.semanticColors.bodyBackground
        }
    });
}
export const TRANSLATION_KEYS = {
    Heading: "settingPage.areaPage.heading",
    SecondaryHeading: "settingPage.areaPage.secondaryHeading",
    BreadcrumbSettingHeading: "settingPage.heading",
    ColumnPreviewTooltip: "settingPage.areaPage.columnPreviewTooltip",
    ColumnTitleLabel: "settingPage.areaPage.columnTitleLabel",
    ColumnDepartmentLabel: "settingPage.areaPage.columnDepartmentLabel",
    ColumnCreatedAtLabel: "settingPage.areaPage.columnCreatedAtLabel",
    ColumnActionCommandUpdateLabel: "settingPage.areaPage.columnActionCommandUpdateLabel",
    ColumnActionCommandUpdateTooltip: "settingPage.areaPage.columnActionCommandUpdateTooltip",
    ColumnActionCommandDeleteLabel: "settingPage.areaPage.columnActionCommandDeleteLabel",
    ColumnActionCommandDeleteTooltip: "settingPage.areaPage.columnActionCommandDeleteTooltip",
    CommandCreateLabel: "settingPage.areaPage.commandCreateLabel",
    CommandCreateTooltip: "settingPage.areaPage.commandCreateTooltip",
    DetailsListEmptyContentLabel: "settingPage.areaPage.detailsListEmptyContentLabel",
};
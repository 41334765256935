import React, { createContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Separator, Text } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';

import { RootState } from '../../../redux/application/redux-store';
import { Ticket } from '../../../api-client';
import { TICKET } from '../../../utils/constants';
import { TRANSLATION_KEYS } from './localization/translation-keys';
import { RouterSettings } from '../../../utils/router';
import { DataEnchanter, DateTimeEnchanter } from '../../../utils/data-enchanters';
import { useTicketDetails } from './hooks/useTicketDetails';

import { IAIApplicationLayout } from '../../../common/layouts/IAIApplicationLayout';
import { IAIBreadcrumb } from '../../../common/components/IAIBreadcrumb';
import { IAIShimmer } from '../../../common/components/IAIShimmer';
import DetailsForm from './components/DetailsForm/details-form';
import Webchat from './components/Webchat/webchat';
import UpdateDialog from './components/UpdateDialog/update-dialog';

export const DetailContext = createContext({} as Ticket | undefined);

const TicketDetailsPage: React.FunctionComponent = () => {
    const {
        handleCreateTicket,
        createTicketLoading,
        handleRetrieveTicket,
        retrieveTicket,
        retrieveTicketLoading,
        handleUpdateTicket,
        updateTicketLoading,
        handleRetrieveChangeLogs,
        retrieveChangeLogs,
        retrieveChangeLogsLoading
    } = useTicketDetails();

    const routeParams = useParams();

    const manageAction = routeParams.ticketId !== TICKET.NEW;

    useEffect(() => {
        if (routeParams.ticketId && manageAction) {
            handleRetrieveTicket({ id: routeParams.ticketId });
            handleRetrieveChangeLogs({ id: routeParams.ticketId });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [routeParams.ticketId]);

    //#region Form-Management

    const [updateModalVisible, { toggle: toggleUpdateModalVisible }] = useBoolean(false);

    const _handleUpdateAction = () => {
        toggleUpdateModalVisible();
    }

    const _handleDismissUpdateAction = () => {
        toggleUpdateModalVisible();
    }

    const navigate = useNavigate();

    const _handleUpdateFallback = () => {
        navigate(RouterSettings.PATHS.dashboard.ticket.root);
    }

    const _handleUpsertTicket = (dataItem: Ticket) => {
        dataItem.id
            ?
            handleUpdateTicket({ id: dataItem.id, ticket: dataItem, onSuccess: _handleUpdateAction })
            :
            handleCreateTicket({ ticket: dataItem, onSuccess: _handleUpdateFallback });
    }

    //#endregion

    const iaiSystem = useSelector((state: RootState) => state.system);

    const {
        i18n,
        t: translate
    } = useTranslation();

    return (
        <DetailContext.Provider value={retrieveTicket}>
        <IAIApplicationLayout
            icon={'Ticket'}
            heading={(
                manageAction
                    ? translate(TRANSLATION_KEYS.UpdateHeading)
                    : translate(TRANSLATION_KEYS.CreateHeading)
            )}
            secondaryHeading={(
                manageAction
                    ? `${translate(TRANSLATION_KEYS.UpdateSecondaryHeading)} #${retrieveTicket?.readableId ? retrieveTicket.readableId : retrieveTicket?.id}.`
                    : translate(TRANSLATION_KEYS.CreateSecondaryHeading)
            )}
        >
            <div className="ticket-details-page-container">
                <IAIBreadcrumb
                    items={[
                        {
                            key: RouterSettings.ROUTES_KEYS.IAI_APP_TICKET,
                            text: translate(TRANSLATION_KEYS.BreadcrumbTicketHeading)
                        },
                        {
                            key: RouterSettings.ROUTES_KEYS.IAI_APP_TICKET_DETAILS,
                            text: manageAction
                                ? translate(TRANSLATION_KEYS.UpdateHeading)
                                : translate(TRANSLATION_KEYS.CreateHeading),
                            isCurrentItem: true
                        }
                    ]}
                />

                {manageAction && (
                    <React.Fragment>
                        <div className="row">
                            <div className="col-7">
                                <IAIShimmer type={'shimmer-heading'} loading={retrieveTicketLoading}>
                                    <Text block variant="xLargePlus">
                                        {`${translate(TRANSLATION_KEYS.RequestedByLabel)} ${retrieveTicket?.requestedBy}`}
                                    </Text>
                                    <Text block variant="mediumPlus">
                                        {`${translate(TRANSLATION_KEYS.CreatedAtLabel)} ${DateTimeEnchanter.formatCustomDate(retrieveTicket?.createdAt, i18n.language, { dateStyle: 'short', timeStyle: 'short' })}`}
                                    </Text>
                                </IAIShimmer>
                            </div>
                            <div className="col-5 text-end">
                                <IAIShimmer type={'shimmer-heading'} loading={retrieveTicketLoading}>
                                    <Text block variant="xLargePlus">
                                        {`${translate(TRANSLATION_KEYS.StatusLabel)} ${translate(DataEnchanter.textByStatusEnum(retrieveTicket?.status || ''))}`}
                                    </Text>

                                    {retrieveTicket?.status === 'closed' && (
                                        <Text block variant="mediumPlus">
                                            {`${translate(TRANSLATION_KEYS.ClosedAtLabel)} ${DateTimeEnchanter.formatCustomDate(retrieveTicket.closedAt, i18n.language, { dateStyle: 'short', timeStyle: 'short' })}`}
                                        </Text>
                                    )}
                                </IAIShimmer>
                            </div>
                        </div>

                        <Separator className="my-2" />
                    </React.Fragment>
                )}

                <div className="row">
                    <div className={`col-12 ${manageAction && 'col-md-7'}`}>
                        <DetailsForm
                            ticket={retrieveTicket}
                            changeLogs={retrieveChangeLogs}
                            dispatchManageAction={manageAction}
                            disabled={(
                                retrieveTicketLoading ||
                                retrieveChangeLogsLoading ||
                                createTicketLoading ||
                                updateTicketLoading ||
                                !iaiSystem.areasInitialized
                            )}
                            onFormConfirm={_handleUpsertTicket}
                        />
                    </div>

                    {manageAction && (
                        <div className="col-12 col-md-5">
                            <Webchat ticketId={routeParams.ticketId} />
                        </div>
                    )}
                </div>

                <UpdateDialog
                    disabled={retrieveTicketLoading || updateTicketLoading}
                    modalVisible={updateModalVisible}
                    onModalConfirm={_handleUpdateFallback}
                    onModalDismiss={_handleDismissUpdateAction}
                />
            </div>
        </IAIApplicationLayout>
        </DetailContext.Provider>
    );
}

export default TicketDetailsPage;
export const TRANSLATION_KEYS = {
    CreateHeading: "ticketDetailsPage.createHeading",
    CreateSecondaryHeading: "ticketDetailsPage.createSecondaryHeading",
    UpdateHeading: "ticketDetailsPage.updateHeading",
    UpdateSecondaryHeading: "ticketDetailsPage.updateSecondaryHeading",
    BreadcrumbTicketHeading: "ticketPage.heading",
    RequestedByLabel: "ticketDetailsPage.requestedByLabel",
    CreatedAtLabel: "ticketDetailsPage.createdAtLabel",
    StatusLabel: "ticketDetailsPage.statusLabel",
    ClosedAtLabel: "ticketDetailsPage.closedAtLabel"
};
import React from 'react';
import _ from 'lodash';

import { useId } from '@fluentui/react-hooks';

import { useClassNames } from './iai-accordion.classNames';

import './iai-accordion.scss';

interface IAIIAccordionItem {
    heading?: string;
    disabled?: boolean;
}

const IAIAccordionItem: React.FunctionComponent<IAIIAccordionItem> = ({
    heading,
    disabled,
    children
}) => {
    const {
        accordionItemHeading,
        accordionItemContent
    } = useClassNames();

    const accordionItemId = useId('iai_accordion_item__');

    return (
        <div className="accordion-wrapper">
            <input type="checkbox" id={accordionItemId} disabled={disabled} />
            <label className={`accordion-item-heading ${accordionItemHeading}`} htmlFor={accordionItemId}>
                {heading}
            </label>
            <div className={`accordion-item-content ${accordionItemContent}`}>
                {children}
            </div>
        </div>
    );
}

export default IAIAccordionItem;
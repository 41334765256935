import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useMsal } from "@azure/msal-react";

import { Announcement } from '../../../../api-client';
import { IHookCallback } from '../../../../common/models/IHookCallback';
import { AnnouncementApiClient } from '../../../../providers/api-provider';
import { NotificationHelper } from '../../../../utils/notification';
import { useSystem } from '../../../../common/hooks/useSystem';

export const useNotice = () => {
    const {
        instance
    } = useMsal();

    const {
        t: translate
    } = useTranslation();

    const [notices, setNotices] = useState<Announcement[]>();
    const [noticesLoading, setNoticesLoading] = useState<boolean>(false);

    const handleNotices = async () => {
        setNoticesLoading(true);

        const clientApi = await AnnouncementApiClient(instance);

        clientApi.getAnnouncements()
            .then((response: any) => {
                setNotices(response.data);
            })
            .catch((reason) => {
                setNotices([]);

                NotificationHelper.createNotification({
                    type: 'error',
                    title: translate('settingPage.noticePage.notification.retrieveAll.title'),
                    message: translate('settingPage.noticePage.notification.retrieveAll.message')
                });
            })
            .finally(() => {
                setNoticesLoading(false);
            });
    }

    // CRUD Operations

    const {
        handleSystemNotices
    } = useSystem();

    interface ICreateNotice extends IHookCallback {
        announcement: Announcement;
    }

    const [createNoticeLoading, setCreateNoticeLoading] = useState<boolean>(false);

    const handleCreateNotice = async (params: ICreateNotice) => {
        setCreateNoticeLoading(true);

        const clientApi = await AnnouncementApiClient(instance);

        clientApi.insertAnnouncement(params.announcement)
            .then((response) => {
                handleSystemNotices();

                params.onSuccess && params.onSuccess();

                NotificationHelper.createNotification({
                    type: 'success',
                    title: translate('settingPage.noticePage.notification.createSuccess.title'),
                    message: translate('settingPage.noticePage.notification.createSuccess.message')
                });
            })
            .catch((reason) => {
                params.onError && params.onError();

                NotificationHelper.createNotification({
                    type: 'error',
                    title: translate('settingPage.noticePage.notification.createError.title'),
                    message: translate('settingPage.noticePage.notification.createError.message')
                });
            })
            .finally(() => {
                setCreateNoticeLoading(false);

                params.onAlways && params.onAlways();
            });
    }

    interface IRetrieveNotice extends IHookCallback {
        id: string;
        onRetrieveSuccess: (announcement: Announcement) => void;
    }

    const [retrieveNotice, setRetrieveNotice] = useState<Announcement>();
    const [retrieveNoticeLoading, setRetrieveNoticeLoading] = useState<boolean>(false);

    const handleRetrieveNotice = async (params: IRetrieveNotice) => {
        setRetrieveNoticeLoading(true);

        const clientApi = await AnnouncementApiClient(instance);
        clientApi.getAnnouncement(params.id)
            .then((response: any) => {
                setRetrieveNotice(response.data);

                params.onRetrieveSuccess(response.data);
            })
            .catch((reason) => {
                setRetrieveNotice(undefined);

                params.onError && params.onError();

                NotificationHelper.createNotification({
                    type: 'error',
                    title: translate('settingPage.noticePage.notification.retrieve.title'),
                    message: translate('settingPage.noticePage.notification.retrieve.message')
                });
            })
            .finally(() => {
                setRetrieveNoticeLoading(false);

                params.onAlways && params.onAlways();
            });
    }

    interface IUpdateNotice extends IHookCallback {
        id: string;
        announcement: Announcement;
    }

    const [updateNoticeLoading, setUpdateNoticeLoading] = useState<boolean>(false);

    const handleUpdateNotice = async (params: IUpdateNotice) => {
        setUpdateNoticeLoading(true);

        const clientApi = await AnnouncementApiClient(instance);

        clientApi.updateAnnouncement(params.id, params.announcement)
            .then((response) => {
                handleSystemNotices();

                params.onSuccess && params.onSuccess();

                NotificationHelper.createNotification({
                    type: 'success',
                    title: translate('settingPage.noticePage.notification.updateSuccess.title'),
                    message: translate('settingPage.noticePage.notification.updateSuccess.message')
                });
            })
            .catch((reason) => {
                params.onError && params.onError();

                NotificationHelper.createNotification({
                    type: 'error',
                    title: translate('settingPage.noticePage.notification.updateError.title'),
                    message: translate('settingPage.noticePage.notification.updateError.message')
                });
            })
            .finally(() => {
                setUpdateNoticeLoading(false);

                params.onAlways && params.onAlways();
            });
    }

    interface IDeleteNotice extends IHookCallback {
        id: string;
    }

    const [deleteNoticeLoading, setDeleteNoticeLoading] = useState<boolean>(false);

    const handleDeleteNotice = async (params: IDeleteNotice) => {
        setDeleteNoticeLoading(true);

        const clientApi = await AnnouncementApiClient(instance);

        clientApi.deleteAnnouncement(params.id)
            .then((response) => {
                handleSystemNotices();

                params.onSuccess && params.onSuccess();

                NotificationHelper.createNotification({
                    type: 'success',
                    title: translate('settingPage.noticePage.notification.deleteSuccess.title'),
                    message: translate('settingPage.noticePage.notification.deleteSuccess.message')
                });
            })
            .catch((reason) => {
                params.onError && params.onError();

                NotificationHelper.createNotification({
                    type: 'error',
                    title: translate('settingPage.noticePage.notification.deleteError.title'),
                    message: translate('settingPage.noticePage.notification.deleteError.message')
                });
            })
            .finally(() => {
                setDeleteNoticeLoading(false);

                params.onAlways && params.onAlways();
            });
    }

    return {
        handleNotices,
        notices,
        noticesLoading,
        handleCreateNotice,
        createNoticeLoading,
        handleRetrieveNotice,
        retrieveNotice,
        retrieveNoticeLoading,
        handleUpdateNotice,
        updateNoticeLoading,
        handleDeleteNotice,
        deleteNoticeLoading
    }
}
export const TRANSLATION_KEYS = {
    Heading: "solutionPage.formDrawer.heading",
    ConfirmText: "common.saveText",
    DismissText: "common.dismissText",
    TitleLabel: "solutionPage.formDrawer.titleLabel",
    TitlePlaceholder: "solutionPage.formDrawer.titlePlaceholder",
    TitleErrorMessage: "solutionPage.formDrawer.titleErrorMessage",
    DepartmentLabel: "solutionPage.formDrawer.departmentLabel",
    DepartmentPlaceholder: "solutionPage.formDrawer.departmentPlaceholder",
    DepartmentErrorMessage: "solutionPage.formDrawer.departmentErrorMessage",
    DescriptionLabel: "solutionPage.formDrawer.descriptionLabel",
    DescriptionPlaceholder: "solutionPage.formDrawer.descriptionPlaceholder",
    DescriptionErrorMessage: "solutionPage.formDrawer.descriptionErrorMessage"
};
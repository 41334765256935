export const TRANSLATION_KEYS = {
    Heading: "settingPage.areaPage.formDrawer.heading",
    ConfirmText: "common.saveText",
    DismissText: "common.dismissText",
    TitleLabel: "settingPage.areaPage.formDrawer.titleLabel",
    TitlePlaceholder: "settingPage.areaPage.formDrawer.titlePlaceholder",
    TitleErrorMessage: "settingPage.areaPage.formDrawer.titleErrorMessage",
    DepartmentLabel: "settingPage.areaPage.formDrawer.departmentLabel",
    DepartmentPlaceholder: "settingPage.areaPage.formDrawer.departmentPlaceholder",
    DepartmentErrorMessage: "settingPage.areaPage.formDrawer.departmentErrorMessage"
};
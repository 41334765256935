import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import _ from 'lodash';

import { ContextualMenuItemType } from '@fluentui/react';

import { Ticket } from '../../../../../api-client';
import { RouterSettings } from '../../../../../utils/router';
import { TRANSLATION_KEYS } from './localization/translation-keys';

import {
    IAIDetailsList,
    IAIContextualMenuColumn,
    IAIDatetimeColumn,
    IAIPreviewColumn,
    IAIPriorityColumn,
    IAIStatusColumn,
    IAIStringColumn
} from '../../../../../common/components/IAITable';

interface IPersonalTicketsPivot {
    dataSource: Ticket[];
    dataLoading?: boolean;
}

const PersonalTicketsPivot: React.FunctionComponent<IPersonalTicketsPivot> = ({
    dataSource,
    dataLoading
}) => {
    const _handleSearchPredicate = (dataItem: Ticket, searchKey: string) => (
        _.includes(dataItem.id?.toLowerCase(), searchKey) ||
        _.includes(dataItem.subject?.toLowerCase(), searchKey) ||
        _.includes(dataItem.description?.toLowerCase(), searchKey) ||
        _.includes(dataItem.requestedBy?.toLowerCase(), searchKey)
    );

    //#region Form-Management

    const navigate = useNavigate();

    const _handleCreateAction = () => {
        navigate(RouterSettings.PATHS.dashboard.ticket.edit('00-0000'));
    }

    const _handleViewAction = (dataItem: Ticket) => {
        if (!dataItem.id) {
            return;
        }

        navigate(RouterSettings.PATHS.dashboard.ticket.view(dataItem.id));
    }

    //#endregion

    const {
        t: translate
    } = useTranslation();

    return (
        <div className={`personal-tickets-pivot-container`}>
            <IAIDetailsList
                dataSource={dataSource}
                dataColumns={[
                    {
                        key: 'tck__preview',
                        fieldName: '',
                        name: '',
                        minWidth: 50,
                        maxWidth: 50,
                        onRender: (dataItem: Ticket) => (
                            <IAIPreviewColumn
                                heading={dataItem.subject}
                                content={parse(dataItem.description || '')}
                                tooltip={translate(TRANSLATION_KEYS.ColumnPreviewTooltip)}
                            />
                        )
                    },
                    {
                        key: 'tck__subject',
                        fieldName: 'subject',
                        name: translate(TRANSLATION_KEYS.ColumnTitleLabel),
                        minWidth: 200,
                        isResizable: true,
                        onRender: (dataItem: Ticket) => (
                            <IAIStringColumn align="start" text={dataItem.subject} />
                        )
                    },
                    {
                        key: 'tck__status',
                        fieldName: 'status',
                        name: translate(TRANSLATION_KEYS.ColumnStatusLabel),
                        minWidth: 100,
                        maxWidth: 100,
                        onRender: (dataItem: Ticket) => (
                            <IAIStatusColumn align="start" status={dataItem.status} />
                        )
                    },
                    {
                        key: 'tck__department',
                        fieldName: 'department',
                        name: translate(TRANSLATION_KEYS.ColumnDepartmentLabel),
                        minWidth: 200,
                        isResizable: true,
                        onRender: (dataItem: Ticket) => (
                            <IAIStringColumn align="start" text={dataItem.department?.displayName} />
                        )
                    },
                    {
                        key: 'tck__priority',
                        fieldName: 'priority',
                        name: translate(TRANSLATION_KEYS.ColumnPriorityLabel),
                        minWidth: 100,
                        maxWidth: 100,
                        onRender: (dataItem: Ticket) => (
                            <IAIPriorityColumn align="start" priority={dataItem.priority} />
                        )
                    },
                    {
                        key: 'tck__createdAt',
                        fieldName: 'createdAt',
                        name: translate(TRANSLATION_KEYS.ColumnCreatedAtLabel),
                        minWidth: 100,
                        maxWidth: 100,
                        onRender: (dataItem: Ticket) => (
                            <IAIDatetimeColumn align="start" datetime={dataItem.createdAt} />
                        )
                    },
                    {
                        key: 'tck__actions',
                        fieldName: '',
                        name: '',
                        minWidth: 50,
                        maxWidth: 50,
                        onRender: (dataItem: Ticket, dataIndex) => (
                            <IAIContextualMenuColumn
                                menuItems={[
                                    {
                                        key: `menuItem__view_${dataIndex}`,
                                        iconProps: { iconName: 'View' },
                                        text: translate(TRANSLATION_KEYS.ColumnActionCommandViewLabel),
                                        title: translate(TRANSLATION_KEYS.ColumnActionCommandViewTooltip),
                                        onClick: () => _handleViewAction(dataItem)
                                    },
                                    // { key: `menuItem__divider_${dataIndex}`, itemType: ContextualMenuItemType.Divider },
                                    // {
                                    //     key: `menuItem__delete_${dataIndex}`,
                                    //     iconProps: { iconName: 'Delete' },
                                    //     text: translate(TRANSLATION_KEYS.ColumnActionCommandDeleteLabel),
                                    //     title: translate(TRANSLATION_KEYS.ColumnActionCommandDeleteTooltip),
                                    //     // onClick: () => _handleDeleteAction(dataItem)
                                    // }
                                ]}
                            />
                        )
                    }
                ]}
                dataLoading={dataLoading}
                commands={[
                    {
                        key: '',
                        disabled: dataLoading,
                        text: translate(TRANSLATION_KEYS.CommandCreateLabel),
                        title: translate(TRANSLATION_KEYS.CommandCreateTooltip),
                        iconProps: { iconName: 'Add' },
                        onClick: _handleCreateAction
                    }
                ]}
                emptyContentLabel={translate(TRANSLATION_KEYS.DetailsListEmptyContentLabel)}
                onItemInvoked={(dataItem: Ticket) => _handleViewAction(dataItem)}
                onSearchPredicate={_handleSearchPredicate}
            />
        </div>
    );
}

export default PersonalTicketsPivot;
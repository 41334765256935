import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import {
    DefaultButton,
    PrimaryButton,
    Panel,
    PanelType,
    Separator,
    Stack
} from '@fluentui/react';

import { Announcement } from '../../../../../api-client';
import { TRANSLATION_KEYS } from './localization/translation-keys';

import { FormProvider, IAIDatePicker, IAITextField, IAIEditor } from '../../../../../common/components/IAIForm';

interface FormValuesProps extends Announcement {
    availableFromDate?: Date;
    availableToDate?: Date;
}

interface IFormDrawer {
    notice?: Announcement;
    disabled?: boolean;
    drawerVisible?: boolean;
    onDrawerConfirm: (dataItem: Announcement) => void;
    onDrawerDismiss: () => void;
};

const FormDrawer: React.FunctionComponent<IFormDrawer> = ({
    notice,
    disabled,
    drawerVisible,
    onDrawerConfirm,
    onDrawerDismiss
}) => {
    const {
        t: translate
    } = useTranslation();

    //#region Form-Configuration

    const formDataSchema = Yup.object().shape({
        title: Yup.string().required(translate(TRANSLATION_KEYS.TitleErrorMessage)),
        description: Yup.string().required(translate(TRANSLATION_KEYS.DescriptionErrorMessage)),
        availableFromDate: Yup.date().required(translate(TRANSLATION_KEYS.AvailableFromErrorMessage)),
        availableToDate: Yup.date().required(translate(TRANSLATION_KEYS.AvailableToErrorMessage))
    });

    const defaultValues = useMemo(
        () => ({
            title: notice?.title || '',
            description: notice?.description || '',
            availableFromDate: notice?.availableFrom ? new Date(notice?.availableFrom) : new Date(),
            availableToDate: notice?.availableTo ? new Date(notice?.availableTo) : new Date()
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [notice]
    );

    const methods = useForm<FormValuesProps>({ resolver: yupResolver(formDataSchema), defaultValues });

    const {
        reset,
        handleSubmit,
        formState: { isSubmitting }
    } = methods;

    useEffect(() => {
        reset(defaultValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notice]);

    //#endregion

    const _handleDismiss = () => {
        reset(defaultValues);
        onDrawerDismiss();
    }

    const _handleSubmit = (data: FormValuesProps) => {
        let noticeData = {
            ...notice,
            ...data,
            availableFrom: data.availableFromDate?.toISOString(),
            availableTo: data.availableToDate?.toISOString()
        };

        onDrawerConfirm(noticeData);
    }

    const disableCondition = disabled || isSubmitting;

    return (
        <FormProvider methods={methods}>
            <Panel
                type={PanelType.medium}
                headerText={translate(TRANSLATION_KEYS.Heading)}
                isOpen={drawerVisible}
                isBlocking={true}
                isFooterAtBottom={true}
                onDismiss={_handleDismiss}
                onRenderFooterContent={() => (
                    <div className="text-end">
                        <DefaultButton
                            disabled={disableCondition}
                            iconProps={{ iconName: 'Cancel' }}
                            styles={{ root: { marginRight: 10 } }}
                            onClick={_handleDismiss}
                        >
                            {translate(TRANSLATION_KEYS.DismissText)}
                        </DefaultButton>
                        <PrimaryButton
                            type="submit"
                            disabled={disableCondition}
                            iconProps={{ iconName: 'CheckMark' }}
                            onClick={handleSubmit(_handleSubmit)}
                        >
                            {translate(TRANSLATION_KEYS.ConfirmText)}
                        </PrimaryButton>
                    </div>
                )}
            >
                <Separator />

                <Stack tokens={{ childrenGap: 15 }}>
                    <IAITextField
                        name="title"
                        label={translate(TRANSLATION_KEYS.TitleLabel)}
                        placeholder={translate(TRANSLATION_KEYS.TitlePlaceholder)}
                        required
                        disabled={disableCondition}
                    />

                    <IAIEditor
                        name="description"
                        label={translate(TRANSLATION_KEYS.DescriptionLabel)}
                        placeholder={translate(TRANSLATION_KEYS.DescriptionPlaceholder)}
                        required
                        readOnly={disableCondition}
                    />

                    <Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: 30 }}>
                        <IAIDatePicker
                            name="availableFromDate"
                            label={translate(TRANSLATION_KEYS.AvailableFromLabel)}
                            placeholder={translate(TRANSLATION_KEYS.AvailableFromPlaceholder)}
                            isRequired
                            disabled={disableCondition}
                            styles={{ root: { width: '100%' } }}
                        />

                        <IAIDatePicker
                            name="availableToDate"
                            label={translate(TRANSLATION_KEYS.AvailableToLabel)}
                            placeholder={translate(TRANSLATION_KEYS.AvailableToPlaceholder)}
                            isRequired
                            disabled={disableCondition}
                            styles={{ root: { width: '100%' } }}
                        />
                    </Stack>
                </Stack>
            </Panel>
        </FormProvider>
    );
}

export default FormDrawer;
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
    Dialog,
    DialogType,
    DialogFooter,
    DefaultButton,
    PrimaryButton
} from '@fluentui/react';

import { TRANSLATION_KEYS } from './localization/translation-keys';

interface IMessageDialog {
    disabled?: boolean;
    modalVisible?: boolean;
    onModalConfirm: () => void;
    onModalDismiss: () => void;
};

const MessageDialog: React.FunctionComponent<IMessageDialog> = ({
    disabled,
    modalVisible,
    onModalConfirm,
    onModalDismiss
}) => {
    const {
        t: translate
    } = useTranslation();

    return (
        <Dialog
            hidden={!modalVisible}
            onDismiss={onModalDismiss}
            dialogContentProps={{
                type: DialogType.largeHeader,
                title: translate(TRANSLATION_KEYS.Heading),
                subText: translate(TRANSLATION_KEYS.SecondaryHeading)
            }}
            modalProps={{ isBlocking: true }}
        >
            <DialogFooter>
                <DefaultButton
                    disabled={disabled}
                    onClick={onModalDismiss}
                    text={translate(TRANSLATION_KEYS.DismissText)}
                />
                <PrimaryButton
                    disabled={disabled}
                    onClick={onModalConfirm}
                    text={translate(TRANSLATION_KEYS.ConfirmText)}
                />
            </DialogFooter>
        </Dialog>
    );
}

export default MessageDialog;
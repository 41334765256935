import { mergeStyleSets } from '@fluentui/react';

import { DataEnchanter } from '../../../../utils/data-enchanters';

interface IIAIStatusColumn {
    status?: string;
}

interface IIAIStatusColumnClassNames {
    statusIcon: string;
}

export const useClassNames = ({ status }: IIAIStatusColumn): IIAIStatusColumnClassNames => {
    return mergeStyleSets({
        statusIcon: {
            color: DataEnchanter.colorByStatusEnum(status || ''),
            fontSize: '16px',
            marginRight: '5px'
        }
    });
}
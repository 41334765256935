export const TRANSLATION_KEYS = {
    Heading: "settingPage.settingsDrawer.heading",
    ConfirmText: "common.saveText",
    DismissText: "common.dismissText",
    LanguageLabel: "settingPage.settingsDrawer.languageLabel",
    LanguagePlaceholder: "settingPage.settingsDrawer.languagePlaceholder",
    LanguageErrorMessage: "settingPage.settingsDrawer.languageErrorMessage",
    LanguageOptionIt: "settingPage.settingsDrawer.languageOptionIt",
    LanguageOptionEn: "settingPage.settingsDrawer.languageOptionEn",
    ThemeLabel: "settingPage.settingsDrawer.themeLabel",
    ThemePlaceholder: "settingPage.settingsDrawer.themePlaceholder",
    ThemeErrorMessage: "settingPage.settingsDrawer.themeErrorMessage",
    ThemeOptionLight: "settingPage.settingsDrawer.themeOptionLight",
    ThemeOptionDark: "settingPage.settingsDrawer.themeOptionDark"
};
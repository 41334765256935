import { ProfileRoleEnum } from '../../api-client';

export interface IRouteSettings {
    routeKey: string;
    displayName?: string;
    icon?: string;
    needAuthentication?: boolean;
    path: string;
    sidenavRender?: boolean;
    style?: any;
    roles?: string[]
}

export class RouterSettings {
    public static ROUTES_KEYS = {
        IAI_APP_LOGIN: "IAI_APP_LOGIN",
        IAI_APP_DASHBOARD: "IAI_APP_DASHBOARD",
        IAI_APP_TICKET: "IAI_APP_TICKET",
        IAI_APP_TICKET_TYPE: "IAI_APP_TICKET_TYPE",
        IAI_APP_TICKET_DETAILS: "IAI_APP_TICKET_DETAILS",
        IAI_APP_TICKET_VIEW: "IAI_APP_TICKET_VIEW",
        IAI_APP_SOLUTION: "IAI_APP_SOLUTION",
        IAI_APP_REPORT: "IAI_APP_REPORT",
        IAI_APP_SETTING: "IAI_APP_SETTING",
        IAI_APP_SETTING_AREA: "IAI_APP_SETTING_AREA",
        IAI_APP_SETTING_GROUP: "IAI_APP_SETTING_GROUP",
        IAI_APP_SETTING_CUSTOM_PROPERTY: "IAI_APP_SETTING_CUSTOM_PROPERTY",
        IAI_APP_SETTING_NOTICE: "IAI_APP_SETTING_NOTICE",
        IAI_APP_SETTING_TOPIC: "IAI_APP_SETTING_TOPIC"
    };

    public static ROUTES: IRouteSettings[] = [
        {
            routeKey: RouterSettings.ROUTES_KEYS.IAI_APP_LOGIN,
            path: '/login'
        },
        {
            routeKey: RouterSettings.ROUTES_KEYS.IAI_APP_DASHBOARD,
            displayName: 'Homepage',
            icon: 'Home',
            needAuthentication: true,
            path: '/',
            sidenavRender: true,
            roles: [
                ProfileRoleEnum.Administrator,
                ProfileRoleEnum.DepartmentAgent,
                ProfileRoleEnum.DepartmentManager,
                ProfileRoleEnum.User
            ]
        },
        {
            routeKey: RouterSettings.ROUTES_KEYS.IAI_APP_TICKET,
            displayName: 'Ticket',
            icon: 'Ticket',
            needAuthentication: true,
            path: '/ticket',
            sidenavRender: true,
            roles: [
                ProfileRoleEnum.Administrator,
                ProfileRoleEnum.DepartmentAgent,
                ProfileRoleEnum.DepartmentManager,
                ProfileRoleEnum.User
            ]
        },
        {
            routeKey: RouterSettings.ROUTES_KEYS.IAI_APP_TICKET_TYPE,
            needAuthentication: true,
            path: '/ticket/:ticketType/type',
            roles: [
                ProfileRoleEnum.Administrator,
                ProfileRoleEnum.DepartmentAgent,
                ProfileRoleEnum.DepartmentManager,
                ProfileRoleEnum.User
            ]
        },
        {
            routeKey: RouterSettings.ROUTES_KEYS.IAI_APP_TICKET_DETAILS,
            needAuthentication: true,
            path: '/ticket/:ticketId',
            roles: [
                ProfileRoleEnum.Administrator,
                ProfileRoleEnum.DepartmentAgent,
                ProfileRoleEnum.DepartmentManager,
                ProfileRoleEnum.User
            ]
        },
        {
            routeKey: RouterSettings.ROUTES_KEYS.IAI_APP_TICKET_VIEW,
            needAuthentication: true,
            path: '/ticket/:ticketId/view',
            roles: [
                ProfileRoleEnum.Administrator,
                ProfileRoleEnum.DepartmentAgent,
                ProfileRoleEnum.DepartmentManager,
                ProfileRoleEnum.User
            ]
        },
        {
            routeKey: RouterSettings.ROUTES_KEYS.IAI_APP_SOLUTION,
            displayName: 'FAQ',
            icon: 'Lightbulb',
            needAuthentication: true,
            path: '/solution',
            sidenavRender: true,
            roles: [
                ProfileRoleEnum.Administrator,
                ProfileRoleEnum.DepartmentManager
            ]
        },
        {
            routeKey: RouterSettings.ROUTES_KEYS.IAI_APP_REPORT,
            displayName: 'Report',
            icon: 'LineChart',
            needAuthentication: true,
            path: '/report-stats',
            sidenavRender: true,
            roles: [
                ProfileRoleEnum.Administrator,
                ProfileRoleEnum.DepartmentManager
            ]
        },
        {
            routeKey: RouterSettings.ROUTES_KEYS.IAI_APP_SETTING,
            displayName: 'Impostazioni',
            icon: 'Settings',
            needAuthentication: true,
            path: '/setting',
            sidenavRender: true,
            roles: [
                ProfileRoleEnum.Administrator,
                ProfileRoleEnum.DepartmentAgent,
                ProfileRoleEnum.DepartmentManager,
                ProfileRoleEnum.User
            ]
        },
        {
            routeKey: RouterSettings.ROUTES_KEYS.IAI_APP_SETTING_AREA,
            displayName: 'Aree',
            icon: 'ArrangeBringToFront',
            needAuthentication: true,
            path: '/setting/areas',
            sidenavRender: false,
            roles: [
                ProfileRoleEnum.Administrator
            ]
        },
        {
            routeKey: RouterSettings.ROUTES_KEYS.IAI_APP_SETTING_GROUP,
            displayName: 'Gruppi Active Directory',
            icon: 'EngineeringGroup',
            needAuthentication: true,
            path: '/setting/groups',
            sidenavRender: false,
            roles: [
                ProfileRoleEnum.Administrator
            ]
        },
        {
            routeKey: RouterSettings.ROUTES_KEYS.IAI_APP_SETTING_CUSTOM_PROPERTY,
            displayName: 'Proprietà Personalizzate',
            icon: 'AllApps',
            needAuthentication: true,
            path: '/setting/custom-properties',
            sidenavRender: false,
            roles: [
                ProfileRoleEnum.Administrator
            ]
        },
        {
            routeKey: RouterSettings.ROUTES_KEYS.IAI_APP_SETTING_NOTICE,
            displayName: 'Notifiche',
            icon: 'Flag',
            needAuthentication: true,
            path: '/setting/notices',
            sidenavRender: false,
            roles: [
                ProfileRoleEnum.Administrator
            ]
        },
        {
            routeKey: RouterSettings.ROUTES_KEYS.IAI_APP_SETTING_TOPIC,
            displayName: 'Argomenti',
            icon: 'BullseyeTarget',
            needAuthentication: true,
            path: '/setting/topics',
            sidenavRender: false,
            roles: [
                ProfileRoleEnum.Administrator
            ]
        }
    ];

    public static PATHS = {
        login: {
            root: '/login'
        },
        dashboard: {
            root: '/',
            ticket: {
                root: '/ticket',
                new: '/ticket/00-0000',
                type: (type: string) => `/ticket/${type}/type`,
                view: (id: string) => `/ticket/${id}/view`,
                edit: (id: string) => `/ticket/${id}`,
                dynamic: (urlSegment: string) => `/ticket/${urlSegment}` //it can be edit or view
            },
            solution: {
                root: '/solution'
            },
            reportStats: {
                root: '/report-stats'
            },
            setting: {
                root: '/setting',
                areas: '/setting/areas',
                groups: '/setting/groups',
                customProperties: '/setting/custom-properties',
                notices: '/setting/notices',
                topics: '/setting/topics'
            }
        }
    };
}
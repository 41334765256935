export const TRANSLATION_KEYS = {
    ColumnPreviewTooltip: "ticketPage.ticketsPivot.columnPreviewTooltip",
    ColumnTitleLabel: "ticketPage.ticketsPivot.columnTitleLabel",
    ColumnStatusLabel: "ticketPage.ticketsPivot.columnStatusLabel",
    ColumnDepartmentLabel: "ticketPage.ticketsPivot.columnDepartmentLabel",
    ColumnPriorityLabel: "ticketPage.ticketsPivot.columnPriorityLabel",
    ColumnCreatedAtLabel: "ticketPage.ticketsPivot.columnCreatedAtLabel",
    ColumnActionCommandViewLabel: "ticketPage.ticketsPivot.columnActionCommandViewLabel",
    ColumnActionCommandViewTooltip: "ticketPage.ticketsPivot.columnActionCommandViewTooltip",
    ColumnActionCommandDeleteLabel: "ticketPage.ticketsPivot.columnActionCommandDeleteLabel",
    ColumnActionCommandDeleteTooltip: "ticketPage.ticketsPivot.columnActionCommandDeleteTooltip",
    CommandCreateLabel: "ticketPage.ticketsPivot.commandCreateLabel",
    CommandCreateTooltip: "ticketPage.ticketsPivot.commandCreateTooltip",
    DetailsListEmptyContentLabel: "ticketPage.ticketsPivot.detailsListEmptyContentLabel"
};
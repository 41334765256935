import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import _ from 'lodash';

import { IContextualMenuItem, ContextualMenuItemType, MessageBar, MessageBarType } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';

import { RootState } from '../../../../../redux/application/redux-store';
import { clearCriteria, keepCriteria } from '../../../../../redux/slices/Ticket/ticketSlice';
import { Ticket } from '../../../../../api-client';
import { RouterSettings } from '../../../../../utils/router';
import { TRANSLATION_KEYS } from './localization/translation-keys';
import { useTicketsPivot } from './hooks/useTicketsPivot';

import {
    IAIDetailsList,
    IAIContextualMenuColumn,
    IAIDatetimeColumn,
    IAIPreviewColumn,
    IAIPriorityColumn,
    IAIStatusColumn,
    IAIStringColumn
} from '../../../../../common/components/IAITable';
import { IAITicketsRefinementDrawer } from '../../../../../common/components/IAITicketsRefinementDrawer';
import ChangeLogsDrawer from './components/ChangeLogsDrawer/change-logs-drawer';
import DeleteDialog from './components/DeleteDialog/delete-dialog';

interface ITicketsPivot {
    dataSource: Ticket[];
    dataLoading?: boolean;
    onApplyCriteria: () => void;
    onClearCriteria: () => void;
    deleteTicketLoading?: boolean;
    onDeleteTicket: (id: string, onSuccess: () => void) => void;
}

const TicketsPivot: React.FunctionComponent<ITicketsPivot> = ({
    dataSource,
    dataLoading,
    onApplyCriteria,
    onClearCriteria,
    deleteTicketLoading,
    onDeleteTicket
}) => {
    const {
        handleRetrieveChangeLogs,
        retrieveChangeLogs,
        retrieveChangeLogsLoading
    } = useTicketsPivot();

    const _handleSearchPredicate = (dataItem: Ticket, searchKey: string) => (
        _.includes(dataItem.id?.toLowerCase(), searchKey) ||
        _.includes(dataItem.subject?.toLowerCase(), searchKey) ||
        _.includes(dataItem.description?.toLowerCase(), searchKey) ||
        _.includes(dataItem.requestedBy?.toLowerCase(), searchKey)
    );

    //#region Form-Management

    const [changeLogsDrawerVisible, { toggle: toggleChangeLogsDrawerVisible }] = useBoolean(false);
    const [deleteTicketModalVisible, { toggle: toggleDeleteTicketModalVisible }] = useBoolean(false);
    const [delegateTicket, setDelegateTicket] = useState<Ticket>();

    const navigate = useNavigate();

    const _handleCreateAction = () => navigate(RouterSettings.PATHS.dashboard.ticket.edit('00-0000'));

    const _handleDeleteAction = (dataItem: Ticket) => {
        setDelegateTicket(dataItem);
        toggleDeleteTicketModalVisible();
    }

    const _handleDismissDeleteAction = () => {
        setDelegateTicket(undefined);
        toggleDeleteTicketModalVisible();
    }

    const _handleRetrieveChangeLogs = (id: string) => {
        handleRetrieveChangeLogs({
            id: id,
            onRetrieveSuccess: (changeLogs) => toggleChangeLogsDrawerVisible()
        });
    }

    const _handleDeleteTicket = () => {
        if (delegateTicket?.id) {
            onDeleteTicket(delegateTicket.id, _handleDismissDeleteAction);
        }
    }

    //#endregion

    //#region Criteria-Management

    const iaiCriteria = useSelector((state: RootState) => state.ticket.criteria);

    const [refinementDrawerVisible, { toggle: toggleRefinementDrawerVisible }] = useBoolean(false);

    const dispatch = useDispatch();

    const _handleChangeTicketsCriteria = (name: string, value: any) => dispatch(keepCriteria({
        ...iaiCriteria,
        [name]: value
    }));

    const _handleClearTicketsCriteria = () => {
        dispatch(clearCriteria());
        onClearCriteria();
        toggleRefinementDrawerVisible();
    }

    //#endregion

    const {
        t: translate
    } = useTranslation();

    const iaiAccount = useSelector((state: RootState) => state.account);

    const _handleMenuItemsByRole = (dataItem: Ticket, dataIndex?: number): IContextualMenuItem[] => {
        let menuItems: IContextualMenuItem[] = [
            {
                key: `menuItem__edit_${dataIndex}`,
                iconProps: { iconName: 'EditCreate' },
                text: translate(TRANSLATION_KEYS.ColumnActionCommandEditLabel),
                title: translate(TRANSLATION_KEYS.ColumnActionCommandEditTooltip),
                onClick: () => navigate(RouterSettings.PATHS.dashboard.ticket.edit(dataItem.id!))
            }
        ];

        if (iaiAccount.role === 'administrator') {
            menuItems.push({
                key: `menuItem__changeLogs_${dataIndex}`,
                iconProps: { iconName: 'ViewAll' },
                text: translate(TRANSLATION_KEYS.ColumnActionCommandChangeLogsLabel),
                title: translate(TRANSLATION_KEYS.ColumnActionCommandChangeLogsTooltip),
                onClick: () => _handleRetrieveChangeLogs(dataItem.id!)
            });
        }

        menuItems.push({
            key: `menuItem__divider_${dataIndex}`,
            itemType: ContextualMenuItemType.Divider
        });

        if (iaiAccount.role === 'administrator' || iaiAccount.role === 'departmentManager') {
            menuItems.push({
                key: `menuItem__delete_${dataIndex}`,
                iconProps: { iconName: 'Delete' },
                text: translate(TRANSLATION_KEYS.ColumnActionCommandDeleteLabel),
                title: translate(TRANSLATION_KEYS.ColumnActionCommandDeleteTooltip),
                onClick: () => _handleDeleteAction(dataItem)
            });
        }

        return menuItems;
    }

    return (
        <div className={`tickets-pivot-container`}>
            {!!Object.keys(iaiCriteria || {}).length && (
                <MessageBar
                    messageBarType={MessageBarType.info}
                    styles={{ root: { marginTop: 15, marginBottom: 5 } }}
                >
                    {translate(TRANSLATION_KEYS.ActiveCriteriaMessage)}
                </MessageBar>
            )}

            <IAIDetailsList
                dataSource={dataSource}
                dataColumns={[
                    {
                        key: 'tck__preview',
                        fieldName: '',
                        name: '',
                        minWidth: 50,
                        maxWidth: 50,
                        onRender: (dataItem: Ticket) => (
                            <IAIPreviewColumn
                                heading={dataItem.subject}
                                content={parse(dataItem.description || '')}
                                tooltip={translate(TRANSLATION_KEYS.ColumnPreviewTooltip)}
                            />
                        )
                    },
                    {
                        key: 'tck__subject',
                        fieldName: 'subject',
                        name: translate(TRANSLATION_KEYS.ColumnTitleLabel),
                        minWidth: 200,
                        isResizable: true,
                        onRender: (dataItem: Ticket) => (
                            <IAIStringColumn align="start" text={dataItem.subject} />
                        )
                    },
                    {
                        key: 'tck__requestedBy',
                        fieldName: 'requestedBy',
                        name: translate(TRANSLATION_KEYS.ColumnRequestedByLabel),
                        minWidth: 100,
                        isResizable: true,
                        onRender: (dataItem: Ticket) => (
                            <IAIStringColumn align="start" text={dataItem.requestedBy} />
                        )
                    },
                    {
                        key: 'tck__status',
                        fieldName: 'status',
                        name: translate(TRANSLATION_KEYS.ColumnStatusLabel),
                        minWidth: 100,
                        maxWidth: 100,
                        onRender: (dataItem: Ticket) => (
                            <IAIStatusColumn align="start" status={dataItem.status} />
                        )
                    },
                    {
                        key: 'tck__department',
                        fieldName: 'department',
                        name: translate(TRANSLATION_KEYS.ColumnDepartmentLabel),
                        minWidth: 200,
                        isResizable: true,
                        onRender: (dataItem: Ticket) => (
                            <IAIStringColumn align="start" text={dataItem.department?.displayName} />
                        )
                    },
                    {
                        key: 'tck__agent',
                        fieldName: 'agent',
                        name: translate(TRANSLATION_KEYS.ColumnAgentLabel),
                        minWidth: 100,
                        isResizable: true,
                        onRender: (dataItem: Ticket) => (
                            <IAIStringColumn align="start" text={dataItem.agent?.displayName} />
                        )
                    },
                    {
                        key: 'tck__priority',
                        fieldName: 'priority',
                        name: translate(TRANSLATION_KEYS.ColumnPriorityLabel),
                        minWidth: 100,
                        maxWidth: 100,
                        onRender: (dataItem: Ticket) => (
                            <IAIPriorityColumn align="start" priority={dataItem.priority} />
                        )
                    },
                    {
                        key: 'tck__createdAt',
                        fieldName: 'createdAt',
                        name: translate(TRANSLATION_KEYS.ColumnCreatedAtLabel),
                        minWidth: 100,
                        maxWidth: 100,
                        onRender: (dataItem: Ticket) => (
                            <IAIDatetimeColumn align="start" datetime={dataItem.createdAt} />
                        )
                    },
                    {
                        key: 'tck__closedAt',
                        fieldName: 'closedAt',
                        name: translate(TRANSLATION_KEYS.ColumnClosedAtLabel),
                        minWidth: 100,
                        maxWidth: 100,
                        onRender: (dataItem: Ticket) => (
                            <IAIDatetimeColumn align="start" datetime={dataItem.closedAt} />
                        )
                    },
                    {
                        key: 'tck__actions',
                        fieldName: '',
                        name: '',
                        minWidth: 50,
                        maxWidth: 50,
                        onRender: (dataItem: Ticket, dataIndex) => (
                            <IAIContextualMenuColumn
                                menuItems={_handleMenuItemsByRole(dataItem, dataIndex)}
                            />
                        )
                    }
                ]}
                dataLoading={dataLoading}
                commands={[
                    {
                        key: '',
                        disabled: dataLoading,
                        text: translate(TRANSLATION_KEYS.CommandCreateLabel),
                        title: translate(TRANSLATION_KEYS.CommandCreateTooltip),
                        iconProps: { iconName: 'Add' },
                        onClick: _handleCreateAction
                    }
                ]}
                sideCommands={[
                    {
                        key: '',
                        disabled: dataLoading,
                        title: translate(TRANSLATION_KEYS.CommandFilterTooltip),
                        iconProps: { iconName: 'Filter' },
                        onClick: toggleRefinementDrawerVisible
                    }
                ]}
                emptyContentLabel={translate(TRANSLATION_KEYS.DetailsListEmptyContentLabel)}
                onItemInvoked={(dataItem: Ticket) => navigate(RouterSettings.PATHS.dashboard.ticket.edit(dataItem.id!))}
                onSearchPredicate={_handleSearchPredicate}
            />

            <IAITicketsRefinementDrawer
                criteria={iaiCriteria}
                disabled={dataLoading}
                drawerVisible={refinementDrawerVisible}
                onApplyCriteria={onApplyCriteria}
                onClearCriteria={_handleClearTicketsCriteria}
                onChangeCriteria={_handleChangeTicketsCriteria}
                onDrawerDismiss={toggleRefinementDrawerVisible}
            />

            <ChangeLogsDrawer
                changeLogs={retrieveChangeLogs}
                disabled={dataLoading || retrieveChangeLogsLoading}
                drawerVisible={changeLogsDrawerVisible}
                onDrawerDismiss={toggleChangeLogsDrawerVisible}
            />

            <DeleteDialog
                disabled={dataLoading || deleteTicketLoading}
                modalVisible={deleteTicketModalVisible}
                onModalConfirm={_handleDeleteTicket}
                onModalDismiss={_handleDismissDeleteAction}
            />
        </div>
    );
}

export default TicketsPivot;
import i18n from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import ENTranslations from '../../locales/en.json';
import ITTranslations from '../../locales/it.json';
import helpers from "../../utils/helpers/helpers";

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: false,
        fallbackLng: 'en',
        lng: helpers.getStoredI18Lang(),
        // keySeparator: false,
        interpolation: {
            escapeValue: false
        },
        resources: {
            en: { IAI_Translations: ENTranslations },
            it: { IAI_Translations: ITTranslations }
        },
        ns: ['IAI_Translations'],
        defaultNS: 'IAI_Translations',
        react: {
            bindI18n: false
        }
    });

export default i18n;
import { getTheme, mergeStyleSets } from '@fluentui/react';

interface ILoginPage {
    loginPageContainer: string;
    loginPageSpinner: string;
}

export const useClassNames = (): ILoginPage => {
    const applicationTheme = getTheme();

    return mergeStyleSets({
        loginPageContainer: {
            background: applicationTheme.semanticColors.bodyBackground,
        },
        loginPageSpinner: {
            selectors: {
                '.ms-Spinner-circle': {
                    width: 100,
                    height: 100,
                    borderWidth: 2.5,
                }
            }
        }
    });
}
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../../../../../redux/application/redux-store';
import { TicketComment } from '../../../../../api-client';
import { useViewWebchat } from './hooks/useViewWebchat';

import { IAIWebchat } from '../../../../../common/components/IAIWebchat';

interface IViewWebchat {
    disabled?: boolean;
    ticketId?: string;
}

const ViewWebchat: React.FunctionComponent<IViewWebchat> = ({ disabled, ticketId }) => {
    const {
        handleCreateTicketComment,
        createTicketCommentLoading,
        handleRetrieveTicketComments,
        ticketComments,
        ticketCommentsLoading
    } = useViewWebchat();

    useEffect(() => {
        !!ticketId && handleRetrieveTicketComments({ id: ticketId });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ticketId]);

    const iaiAccount = useSelector((state: RootState) => state.account);

    const _handleSubmit = (comment: TicketComment) => {
        !!ticketId && handleCreateTicketComment({
            id: ticketId,
            ticketComment: {
                ...comment,
                userInfo: { id: '00000000-0000-0000-0000-000000000000', displayName: iaiAccount.displayName }
            },
            onSuccess: () => handleRetrieveTicketComments({ id: ticketId })
        })
    }

    return (
        <IAIWebchat
            dataSource={ticketComments}
            disabled={disabled}
            loading={ticketCommentsLoading || createTicketCommentLoading}
            onSendMessage={_handleSubmit}
        />
    );
}

export default ViewWebchat;
import React, { ReactNode } from 'react';
import { FormProvider as Form, UseFormReturn } from 'react-hook-form';

interface IFormProvider {
    children: ReactNode;
    methods: UseFormReturn<any>;
    onSubmit?: VoidFunction;
}

const FormProvider: React.FunctionComponent<IFormProvider> = ({
    children,
    methods,
    onSubmit
}) => (
    <Form {...methods}>
        <form onSubmit={onSubmit}>
            {children}
        </form>
    </Form>
);

export default FormProvider;
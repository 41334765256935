import React, { useState } from 'react';
import { useFormContext, Controller } from 'react-hook-form';

import { IComboBoxOption, IComboBoxProps, ComboBox } from '@fluentui/react';

interface IIAIComboBox extends IComboBoxProps {
    name: string;
}

const IAIComboBox: React.FunctionComponent<IIAIComboBox> = ({
    name,
    ...other
}) => {
    const {
        control
    } = useFormContext();

    const [comboOptions, setComboOptions] = useState<IComboBoxOption[]>([...other.options]);

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <ComboBox
                    {...field}
                    errorMessage={error?.message}
                    selectedKey={field.value || null}
                    onChange={(_, option, dataIndex, value) => {
                        let selected = option?.selected;
                        if (other.allowFreeform && !option && value) {
                            selected = true;
                            option = { key: value, text: value };
                            setComboOptions(prevOptions => [...prevOptions, option!]);
                        }

                        if (option) {
                            field.onChange(
                                selected
                                    ? [...field.value, option!.key as string]
                                    : field.value.filter((k: any) => k !== option!.key)
                            );
                        }

                        // option && field.onChange(option.key)
                    }}
                    {...other}
                    options={comboOptions}
                />
            )}
        />
    );
}

export default IAIComboBox;
export const TRANSLATION_KEYS = {
    ConfirmText: "common.saveText",
    DismissText: "common.dismissText",
    ModeLabel: "ticketDetailsPage.detailsForm.modeLabel",
    ModePlaceholder: "ticketDetailsPage.detailsForm.modePlaceholder",
    ModeErrorMessage: "ticketDetailsPage.detailsForm.modeErrorMessage",
    ModeOptionLevel01: "common.ticketMode.modeOptionLevel01",
    ModeOptionLevel02: "common.ticketMode.modeOptionLevel02",
    ModeOptionLevel03: "common.ticketMode.modeOptionLevel03",
    AreaLabel: "ticketDetailsPage.detailsForm.areaLabel",
    AreaPlaceholder: "ticketDetailsPage.detailsForm.areaPlaceholder",
    AreaErrorMessage: "ticketDetailsPage.detailsForm.areaErrorMessage",
    DepartmentLabel: "ticketDetailsPage.detailsForm.departmentLabel",
    DepartmentPlaceholder: "ticketDetailsPage.detailsForm.departmentPlaceholder",
    DepartmentErrorMessage: "ticketDetailsPage.detailsForm.departmentErrorMessage",
    AgentLabel: "ticketDetailsPage.detailsForm.agentLabel",
    AgentPlaceholder: "ticketDetailsPage.detailsForm.agentPlaceholder",
    StatusLabel: "ticketDetailsPage.detailsForm.statusLabel",
    StatusPlaceholder: "ticketDetailsPage.detailsForm.statusPlaceholder",
    StatusErrorMessage: "ticketDetailsPage.detailsForm.statusErrorMessage",
    StatusOptionOpen: "common.ticketStatus.statusOptionOpen",
    StatusOptionWorking: "common.ticketStatus.statusOptionWorking",
    StatusOptionPending: "common.ticketStatus.statusOptionPending",
    StatusOptionClosed: "common.ticketStatus.statusOptionClosed",
    PriorityLabel: "ticketDetailsPage.detailsForm.priorityLabel",
    PriorityPlaceholder: "ticketDetailsPage.detailsForm.priorityPlaceholder",
    PriorityOptionLow: "common.ticketPriority.priorityOptionLow",
    PriorityOptionMedium: "common.ticketPriority.priorityOptionMedium",
    PriorityOptionHigh: "common.ticketPriority.priorityOptionHigh",
    SubjectLabel: "ticketDetailsPage.detailsForm.subjectLabel",
    SubjectPlaceholder: "ticketDetailsPage.detailsForm.subjectPlaceholder",
    SubjectErrorMessage: "ticketDetailsPage.detailsForm.subjectErrorMessage",
    DescriptionLabel: "ticketDetailsPage.detailsForm.descriptionLabel",
    DescriptionPlaceholder: "ticketDetailsPage.detailsForm.descriptionPlaceholder",
    DescriptionErrorMessage: "ticketDetailsPage.detailsForm.descriptionErrorMessage",
    NoteLabel: "ticketDetailsPage.detailsForm.noteLabel",
    NotePlaceholder: "ticketDetailsPage.detailsForm.notePlaceholder",
    selectOption: "common.selectOption"
};
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import {
    Callout,
    DefaultButton,
    PrimaryButton,
    Label,
    Stack,
    Text,
    TooltipHost
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';

import { TicketAttachmentFile } from '../../../../../../../api-client';
import { keepAttachments, clearAttachments } from '../../../../../../../redux/slices/Ticket/ticketSlice';
import { DataEnchanter } from '../../../../../../../utils/data-enchanters';
import { TRANSLATION_KEYS } from './localization/translation-keys';
import { useTicketAttachment } from './hooks/useTicketAttachment';
import { useClassNames } from './attachment.classNames';

import { IAIShimmer } from '../../../../../../../common/components/IAIShimmer';

interface IAttachment {
    ticketId?: string;
    disabled?: boolean;
}

const Attachment: React.FunctionComponent<IAttachment> = ({
    ticketId,
    disabled
}) => {
    const {
        handleRetrieveTicketAttachments,
        ticketAttachments,
        ticketAttachmentsLoading,
        handleRetrieveTicketAttachment,
        ticketAttachmentLoading,
        handleDeleteTicketAttachment,
        deleteTicketAttachmentLoading,
        handleUploadTicketAttachment,
        uploadTicketAttachmentLoading
    } = useTicketAttachment();

    useEffect(() => {
        !!ticketId && handleRetrieveTicketAttachments({ id: ticketId });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ticketId]);

    //#region Upload-Management

    const dispatch = useDispatch();

    const [attachmentsUpload, setAttachmentsUpload] = useState<any[]>([]);

    useEffect(() => {
        if (attachmentsUpload && !!attachmentsUpload.length) {
            dispatch(clearAttachments());

            _.each(attachmentsUpload, entry => {
                if (entry) {
                    DataEnchanter.fileToB64(entry).then((response) => {
                        let attachmentFile: TicketAttachmentFile = {
                            fileContent: response.split(',')[1],
                            fileName: entry.name,
                            fileSize: entry.size,
                            fileType: entry.type
                        };

                        if (ticketId) {
                            handleUploadTicketAttachment({
                                id: ticketId,
                                attachment: attachmentFile,
                                onSuccess: () => handleRetrieveTicketAttachments({ id: ticketId })
                            });
                        }
                        else {
                            dispatch(keepAttachments(attachmentFile));
                        }
                    });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attachmentsUpload]);

    const _handleDrop = useCallback((acceptedFiles) => {
        setAttachmentsUpload(acceptedFiles);
    }, []);

    const {
        getRootProps,
        getInputProps
    } = useDropzone({ onDrop: _handleDrop });

    //#endregion

    const {
        calloutContainer,
        calloutBeakCurtain
    } = useClassNames();

    const [attachmentCalloutVisible, { toggle: toggleAttachmentCalloutVisible }] = useBoolean(false);
    const [delegateAttachment, setDelegateAttachment] = useState<any>();
    const [attachmentRef, setAttachmentRef] = useState<number>(0);

    const _handleDownloadAction = () => (
        handleRetrieveTicketAttachment({
            id: ticketId!,
            attachmentId: delegateAttachment.id,
            fileName: delegateAttachment.fileName
        })
    )

    const _handleDeleteAction = () => {
        if (!!ticketId) {
            handleDeleteTicketAttachment({
                id: ticketId,
                attachmentId: delegateAttachment.id,
                onSuccess: () => {
                    handleRetrieveTicketAttachments({ id: ticketId });
                    toggleAttachmentCalloutVisible();
                }
            });
        }
        else {
            const _attachments = _.clone(attachmentsUpload);

            if (_attachments && !!_attachments.length) {
                const index: number = _attachments.indexOf(delegateAttachment, 0);
                if (index > -1) {
                    _attachments.splice(index, 1);

                    setAttachmentsUpload(_attachments);
                    toggleAttachmentCalloutVisible();
                }
            }
        }
    }

    const {
        t: translate
    } = useTranslation();

    return (
        <div className={`ticket-attachment-container`}>
            <Label className="mb-2">
                {translate(TRANSLATION_KEYS.AttachmentLabel)}
            </Label>

            <IAIShimmer type={'shimmer-heading'} loading={ticketAttachmentsLoading}>
                <Stack horizontal tokens={{ childrenGap: 15 }}>
                    {_.map(!!ticketId ? ticketAttachments : attachmentsUpload, (dataItem, dataIndex) => (
                        <TooltipHost
                            key={dataIndex}
                            content={`${dataItem.fileName || dataItem.name} (${DataEnchanter.fileSize(dataItem.fileSize || dataItem.size)})`}
                        >
                            <DefaultButton
                                id={`attachment_button_${!!ticketId ? dataIndex : (dataIndex + (ticketAttachments?.length || 0))}`}
                                iconProps={{ iconName: DataEnchanter.fileIcon(dataItem.fileName || dataItem.name) }}
                                styles={{ root: { maxWidth: 35, minWidth: 0 } }}
                                onClick={() => {
                                    setDelegateAttachment(dataItem);
                                    setAttachmentRef(!!ticketId ? dataIndex : (dataIndex + (ticketAttachments?.length || 0)));
                                    toggleAttachmentCalloutVisible();
                                }}
                            />
                        </TooltipHost>
                    ))}

                    <div {...getRootProps({ className: 'dropzone' })}>
                        <input {...getInputProps()} />

                        <PrimaryButton
                            disabled={disabled || deleteTicketAttachmentLoading || uploadTicketAttachmentLoading}
                            iconProps={{ iconName: 'Add' }}
                            styles={{ root: { maxWidth: 35, minWidth: 0 } }}
                        />
                    </div>
                </Stack>
            </IAIShimmer>

            {attachmentCalloutVisible && (
                <Callout
                    className={calloutContainer}
                    styles={{
                        beak: calloutBeakCurtain,
                        beakCurtain: calloutBeakCurtain,
                        calloutMain: calloutBeakCurtain
                    }}
                    role="dialog"
                    gapSpace={0}
                    target={`#attachment_button_${attachmentRef}`}
                    onDismiss={toggleAttachmentCalloutVisible}
                    setInitialFocus
                >
                    <Text block variant="xLarge" className="mb-3">
                        {`${delegateAttachment.fileName || delegateAttachment.name} (${DataEnchanter.fileSize(delegateAttachment.fileSize || delegateAttachment.size)})`}
                    </Text>
                    <Stack horizontal horizontalAlign={'end'} tokens={{ childrenGap: 15 }}>
                        {!!ticketId && (
                            <PrimaryButton
                                disabled={ticketAttachmentLoading}
                                iconProps={{ iconName: 'Download' }}
                                text={translate(TRANSLATION_KEYS.OpenText)}
                                onClick={_handleDownloadAction}
                            />
                        )}

                        <PrimaryButton
                            disabled={deleteTicketAttachmentLoading}
                            iconProps={{ iconName: 'Delete' }}
                            styles={{ root: { alignSelf: 'end' } }}
                            text={translate(TRANSLATION_KEYS.DeleteText)}
                            onClick={_handleDeleteAction}
                        />
                    </Stack>
                </Callout>
            )}
        </div>
    );
}

export default Attachment;
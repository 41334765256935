import { getTheme, mergeStyleSets } from '@fluentui/react';

interface ISettingPageClassNames {
    settingPageContainer: string;
}

export const useClassNames = (): ISettingPageClassNames => {
    const applicationTheme = getTheme();

    return mergeStyleSets({
        settingPageContainer: {
            background: applicationTheme.semanticColors.bodyBackground,
        }
    });
}
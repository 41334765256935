import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useMsal } from "@azure/msal-react";

import { Area } from '../../../../api-client';
import { IHookCallback } from '../../../../common/models';
import { AreaApiClient } from '../../../../providers/api-provider';
import { NotificationHelper } from '../../../../utils/notification';
import { useSystem } from '../../../../common/hooks/useSystem';

export const useArea = () => {
    const {
        instance
    } = useMsal();

    const {
        t: translate
    } = useTranslation();

    const [areas, setAreas] = useState<Area[]>();
    const [areasLoading, setAreasLoading] = useState<boolean>(false);

    const handleAreas = async () => {
        setAreasLoading(true);

        const clientApi = await AreaApiClient(instance);

        clientApi.getAreas()
            .then((response) => {
                setAreas(response.data);
            })
            .catch((reason) => {
                setAreas([]);

                NotificationHelper.createNotification({
                    type: 'error',
                    title: translate('settingPage.areaPage.notification.retrieveAll.title'),
                    message: translate('settingPage.areaPage.notification.retrieveAll.message')
                });
            })
            .finally(() => {
                setAreasLoading(false);
            });
    }

    // CRUD Operations

    const {
        handleSystemAreas
    } = useSystem();

    interface ICreateArea extends IHookCallback {
        area: Area;
    }

    const [createAreaLoading, setCreateAreaLoading] = useState<boolean>(false);

    const handleCreateArea = async (params: ICreateArea) => {
        setCreateAreaLoading(true);

        const clientApi = await AreaApiClient(instance);

        clientApi.insertArea(params.area)
            .then((response) => {
                handleSystemAreas();

                params.onSuccess && params.onSuccess();

                NotificationHelper.createNotification({
                    type: 'success',
                    title: translate('settingPage.areaPage.notification.createSuccess.title'),
                    message: translate('settingPage.areaPage.notification.createSuccess.message')
                });
            })
            .catch((reason) => {
                params.onError && params.onError();

                NotificationHelper.createNotification({
                    type: 'error',
                    title: translate('settingPage.areaPage.notification.createError.title'),
                    message: translate('settingPage.areaPage.notification.createError.message')
                });
            })
            .finally(() => {
                setCreateAreaLoading(false);

                params.onAlways && params.onAlways();
            });
    }

    interface IRetrieveArea extends IHookCallback {
        id: string;
        onRetrieveSuccess: (area: Area) => void;
    }

    const [retrieveArea, setRetrieveArea] = useState<Area>();
    const [retrieveAreaLoading, setRetrieveAreaLoading] = useState<boolean>(false);

    const handleRetrieveArea = async (params: IRetrieveArea) => {
        setRetrieveAreaLoading(true);

        const clientApi = await AreaApiClient(instance);

        clientApi.getArea(params.id)
            .then((response: any) => {
                setRetrieveArea(response.data);

                params.onRetrieveSuccess(response.data);
            })
            .catch((reason) => {
                setRetrieveArea(undefined);

                params.onError && params.onError();

                NotificationHelper.createNotification({
                    type: 'error',
                    title: translate('settingPage.areaPage.notification.retrieve.title'),
                    message: translate('settingPage.areaPage.notification.retrieve.message')
                });
            })
            .finally(() => {
                setRetrieveAreaLoading(false);
                params.onAlways && params.onAlways();
            });
    }

    interface IUpdateArea extends IHookCallback {
        id: string;
        area: Area;
    }

    const [updateAreaLoading, setUpdateAreaLoading] = useState<boolean>(false);

    const handleUpdateArea = async (params: IUpdateArea) => {
        setUpdateAreaLoading(true);

        const clientApi = await AreaApiClient(instance);

        clientApi.updateArea(params.id, params.area)
            .then((response) => {
                handleSystemAreas();

                params.onSuccess && params.onSuccess();

                NotificationHelper.createNotification({
                    type: 'success',
                    title: translate('settingPage.areaPage.notification.updateSuccess.title'),
                    message: translate('settingPage.areaPage.notification.updateSuccess.message')
                });
            })
            .catch((reason) => {
                params.onError && params.onError();

                NotificationHelper.createNotification({
                    type: 'error',
                    title: translate('settingPage.areaPage.notification.updateError.title'),
                    message: translate('settingPage.areaPage.notification.updateError.message')
                });
            })
            .finally(() => {
                setUpdateAreaLoading(false);

                params.onAlways && params.onAlways();
            });
    }

    interface IDeleteArea extends IHookCallback {
        id: string;
    }

    const [deleteAreaLoading, setDeleteAreaLoading] = useState<boolean>(false);

    const handleDeleteArea = async (params: IDeleteArea) => {
        setDeleteAreaLoading(true);

        const clientApi = await AreaApiClient(instance);

        clientApi.deleteArea(params.id)
            .then((response) => {
                handleSystemAreas();

                params.onSuccess && params.onSuccess();

                NotificationHelper.createNotification({
                    type: 'success',
                    title: translate('settingPage.areaPage.notification.deleteSuccess.title'),
                    message: translate('settingPage.areaPage.notification.deleteSuccess.message')
                });
            })
            .catch((reason) => {
                params.onError && params.onError();

                NotificationHelper.createNotification({
                    type: 'error',
                    title: translate('settingPage.areaPage.notification.deleteError.title'),
                    message: translate('settingPage.areaPage.notification.deleteError.message')
                });
            })
            .finally(() => {
                setDeleteAreaLoading(false);

                params.onAlways && params.onAlways();
            });
    }

    return {
        handleAreas,
        areas,
        areasLoading,
        handleCreateArea,
        createAreaLoading,
        handleRetrieveArea,
        retrieveArea,
        retrieveAreaLoading,
        handleUpdateArea,
        updateAreaLoading,
        handleDeleteArea,
        deleteAreaLoading
    }
}
import { useState } from 'react';

import { useMsal } from "@azure/msal-react";

import { TicketComment } from '../../../../../../api-client';
import { IHookCallback } from '../../../../../../common/models/IHookCallback';
import { ExternalPublicApiClient } from '../../../../../../providers/api-provider';
import { NotificationHelper } from '../../../../../../utils/notification';
import { useTranslation } from 'react-i18next';

export const useViewWebchat = () => {
    const {
        instance
    } = useMsal();

    const {
        t: translate
    } = useTranslation();

    interface ICreateTicketComment extends IHookCallback {
        id: string;
        ticketComment: TicketComment;
    }

    const [createTicketCommentLoading, setCreateTicketCommentLoading] = useState<boolean>(false);

    const handleCreateTicketComment = async (params: ICreateTicketComment) => {
        setCreateTicketCommentLoading(true);

        const clientApi = await ExternalPublicApiClient(instance);

        clientApi.insertTicketCommentByExternal(params.id, params.ticketComment)
            .then((response) => {
                params.onSuccess && params.onSuccess();

                NotificationHelper.createNotification({
                    type: 'success',
                    message: translate('system.notification.insertTicketCommentByExternalSuccess.title'),
                    title: translate('system.notification.insertTicketCommentByExternalSuccess.message')
                });
            })
            .catch((reason) => {
                params.onError && params.onError();

                NotificationHelper.createNotification({
                    type: 'error',
                    message: translate('system.notification.insertTicketCommentByExternal.title'),
                    title: translate('system.notification.insertTicketCommentByExternal.message')
                });
            })
            .finally(() => {
                setCreateTicketCommentLoading(false);

                params.onAlways && params.onAlways();
            });
    }

    interface IRetrieveTicketComments extends IHookCallback {
        id: string;
    }

    const [ticketComments, setTicketComments] = useState<TicketComment[]>();
    const [ticketCommentsLoading, setTicketCommentsLoading] = useState<boolean>(false);

    const handleRetrieveTicketComments = async (params: IRetrieveTicketComments) => {
        setTicketCommentsLoading(true);

        const clientApi = await ExternalPublicApiClient(instance);

        clientApi.getTicketCommentsByExternal(params.id)
            .then((response) => {
                setTicketComments(response.data);

                params.onSuccess && params.onSuccess();
            })
            .catch((reason) => {
                setTicketComments([]);

                params.onError && params.onError();

                NotificationHelper.createNotification({
                    type: 'error',
                    message: translate('system.notification.getTicketCommentsByExternal.title'),
                    title: translate('system.notification.getTicketCommentsByExternal.message')
                });
            })
            .finally(() => {
                setTicketCommentsLoading(false);

                params.onAlways && params.onAlways();
            });
    }

    return {
        handleCreateTicketComment,
        createTicketCommentLoading,
        handleRetrieveTicketComments,
        ticketComments,
        ticketCommentsLoading
    }
}
import React from 'react';

import { IContextualMenuItem, IconButton } from '@fluentui/react';

interface IIAIContextualMenuColumn {
    menuItems: IContextualMenuItem[];
}

const IAIContextualMenuColumn: React.FunctionComponent<IIAIContextualMenuColumn> = ({
    menuItems
}) => (
    <div className="text-center">
        <IconButton
            menuIconProps={{ iconName: 'CollapseMenu' }}
            menuProps={{
                items: menuItems
            }}
        />
    </div>
);

export default IAIContextualMenuColumn;
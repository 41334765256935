import React, { ReactNode, useEffect, useReducer } from 'react';

import { useIsAuthenticated } from "@azure/msal-react";

import { AuthActions, AuthTypes } from '../common/models/Auth';
import { isMsTeamsAuthenticated } from '../providers/authentication/ms-teams-provider';

interface IAuthState {
    isAuthenticated?: boolean;
    isInitialized?: boolean;
    contextType?: 'ms-online' | 'ms-teams';
}

interface IAuthContextState extends IAuthState {
    dismissApplication: () => void;
}

const _defaultState: IAuthState = {};

const AuthReducer = (state: IAuthState, action: AuthActions) => {
    switch (action.type) {
        case AuthTypes.Init:
            return {
                isAuthenticated: action.payload.isAuthenticated,
                isInitialized: true,
                contextType: action.payload.contextType
            };
        case AuthTypes.Signout:
            return {
                ...state,
                isAuthenticated: false,
                contextType: undefined
            };

        default:
            return state;
    }
};

const AuthContext = React.createContext<IAuthContextState | null>(null);

interface IAuthProvider {
    children: ReactNode;
};

export const AuthProvider = ({ children }: IAuthProvider) => {
    const [state, dispatch] = useReducer(AuthReducer, _defaultState);

    let isAuthenticated = useIsAuthenticated();

    const initialize = async () => {
        const msTeamsAuthenticated = await isMsTeamsAuthenticated();

        if (isAuthenticated || msTeamsAuthenticated) {
            dispatch({
                type: AuthTypes.Init,
                payload: {
                    isAuthenticated: true,
                    contextType: msTeamsAuthenticated ? 'ms-teams' : 'ms-online'
                }
            });
        }
        else {
            dispatch({ type: AuthTypes.Signout });
        }
    };

    useEffect(() => {
        initialize();
    }, [isAuthenticated]);

    const signoutApplication = () => {
        dispatch({ type: AuthTypes.Signout });
    };

    return (
        <AuthContext.Provider
            value={{
                ...state,
                dismissApplication: signoutApplication
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}

export const useAuthContext = () => {
    const context = React.useContext(AuthContext);

    if (!context) throw new Error('Auth context must be use inside AuthProvider');

    return context;
};
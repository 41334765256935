import { useState } from 'react';

import { useMsal } from '@azure/msal-react';

import { ExternalApiClient } from '../../../../../../../../providers/api-provider';
import { GraphApiClient } from '../../../../../../../../providers/graph-provider';
import { IHookCallback } from '../../../../../../../models';
import { Ticket } from '../../../../../../../../api-client';
import helpers from '../../../../../../../../utils/helpers/helpers';

export const useMsTeams = () => {
	const {
		instance
	} = useMsal();

	interface IOnlineMeeting extends IHookCallback {
		subject: string;
		startDateTime: string;
		endDateTime: string;
		createdBy: string;
		onOnlineMeetingCreated: (meetingCreated: any) => void;
	}

	const [graphOnlineMeeting, setGraphOnlineMeeting] = useState<any>();
	const [graphOnlineMeetingLoading, setGraphOnlineMeetingLoading] = useState<boolean>(false);

	const handleOnlineMeeting = async (params: IOnlineMeeting) => {
		
		setGraphOnlineMeetingLoading(true);

		const clientApi = await GraphApiClient(instance);

		clientApi.post('/me/events', {
			subject: params.subject,
			body: {
				contentType: 'HTML',
    			content: params.subject
			},
			start: {
				dateTime: params.startDateTime,
				timeZone: "Europe/Berlin"
			},
			end: {
				dateTime: params.endDateTime,
				timeZone: "Europe/Berlin"
			},
			location: {
				displayName: "Online"
			},
			attendees: [
				{
				  emailAddress: {
					address: params.createdBy
				  },
				  type: 'Required'
			}],
			AllowNewTimeProposals: true,
			IsOnlineMeeting: true,
		})
			.then((response) => {
				setGraphOnlineMeeting(response.data);

				params.onOnlineMeetingCreated(response.data);
			})
			.catch((reason) => {
				// CATCH REASON
			})
			.finally(() => {
				setGraphOnlineMeetingLoading(false);
			});
		
	};

	const handleOnlineMeetingClient = async (params: IOnlineMeeting) => {
		
			setGraphOnlineMeetingLoading(true);

			const clientApi = await ExternalApiClient(instance);

			clientApi.createMeetingByExternal({
				subject: params.subject,
				startDateTime: params.startDateTime,
				endDateTime: params.endDateTime,
			})
				.then((response) => {
					setGraphOnlineMeeting(response.data);

					params.onOnlineMeetingCreated(response.data);
				})
				.catch((reason) => {
					// CATCH REASON
				})
				.finally(() => {
					setGraphOnlineMeetingLoading(false);
				});
		
	};

	const handleSchedulerMeeting = async (ticket: Ticket | undefined) => {
		debugger

		const enableTeamsCalendarRedirect = true;
		if(ticket) {
			if(enableTeamsCalendarRedirect) {
				let startDate =  helpers.getRoundedDate(30);

				let endDate = new Date(startDate);
				endDate.setHours(endDate.getHours() + 1);

				var subject = encodeURIComponent(ticket?.subject!); //encoded
				var attendees = [ticket?.requestedBy!].join(','); //mail list comma separated
				var startTime = encodeURIComponent(startDate.toString()); //Date time encoded
				var endTime = encodeURIComponent(endDate.toString());; //same
				var content = encodeURIComponent(""); //econded
				var calendarUrl = `https://teams.microsoft.com/l/meeting/new?subject=${subject}&attendees=${attendees}&startTime=${startTime}&endTime=${endTime}&content=${content}`;

				window.open(calendarUrl, '_blank');
			}
		}
	};

	return {
		handleOnlineMeeting,
		handleOnlineMeetingClient,
		handleSchedulerMeeting,
		graphOnlineMeeting,
		graphOnlineMeetingLoading,
	};
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Announcement, Agent, Area, Group, Department } from '../../../api-client';
import { LocalStorageHelper, SessionStorageHelper } from '../../../utils/storage';

// Define a type for the slice state
interface SystemState {
    agents: Agent[];
    agentsInitialized?: boolean;
    areas: Area[];
    areasInitialized?: boolean;
    groups: Group[];
    groupsInitialized?: boolean;
    departments: Department[];
    departmentsInitialized?: boolean;
    notices: Announcement[];
    theme: string;
}

// Define the initial state using that type
const initialState: SystemState = {
    agents: [],
    agentsInitialized: false,
    areas: [],
    areasInitialized: false,
    groups: [],
    groupsInitialized: false,
    departments: [],
    departmentsInitialized: false,
    notices: [],
    theme: 'light'
};

export const systemSlice = createSlice({
    name: 'system',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        initAgents: (state, action: PayloadAction<Agent[]>) => {
            state.agents = action.payload;
            state.agentsInitialized = true;

            SessionStorageHelper.createItem(SessionStorageHelper.STORAGE_KEYS.IAI_SYSTEM_AGENT, action.payload);
            SessionStorageHelper.createItem(SessionStorageHelper.STORAGE_KEYS.IAI_SYSTEM_AGENT_INIT, true);
        },
        initAreas: (state, action: PayloadAction<Area[]>) => {
            state.areas = action.payload;
            state.areasInitialized = true;

            SessionStorageHelper.createItem(SessionStorageHelper.STORAGE_KEYS.IAI_SYSTEM_AREA, action.payload);
            SessionStorageHelper.createItem(SessionStorageHelper.STORAGE_KEYS.IAI_SYSTEM_AREA_INIT, true);
        },
        initGroups: (state, action: PayloadAction<Group[]>) => {
            state.groups = action.payload;
            state.groupsInitialized = true;

            SessionStorageHelper.createItem(SessionStorageHelper.STORAGE_KEYS.IAI_SYSTEM_GROUP, action.payload);
            SessionStorageHelper.createItem(SessionStorageHelper.STORAGE_KEYS.IAI_SYSTEM_GROUP_INIT, true);
        },
        initDepartments: (state, action: PayloadAction<Department[]>) => {
            state.departments = action.payload;
            state.departmentsInitialized = true;

            SessionStorageHelper.createItem(SessionStorageHelper.STORAGE_KEYS.IAI_SYSTEM_DEPARTMENT, action.payload);
            SessionStorageHelper.createItem(SessionStorageHelper.STORAGE_KEYS.IAI_SYSTEM_DEPARTMENT_INIT, true);
        },
        initNotices: (state, action: PayloadAction<Announcement[]>) => {
            state.notices = action.payload;

            // SessionStorageHelper.createItem(SessionStorageHelper.STORAGE_KEYS.IAI_SYSTEM_NOTICES, action.payload);
        },
        initTheme: (state, action: PayloadAction<string>) => {
            state.theme = action.payload;

            LocalStorageHelper.createItem(LocalStorageHelper.STORAGE_KEYS.IAI_THEME, action.payload);
        }
    }
});

export const {
    initAgents,
    initAreas,
    initGroups,
    initDepartments,
    initNotices,
    initTheme
} = systemSlice.actions;

export default systemSlice.reducer;
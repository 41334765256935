import React from 'react';
import ReactQuill, { ReactQuillProps } from 'react-quill';

import { Label, Stack, Text } from '@fluentui/react';

import { useClassNames } from './react-quill-editor.classNames';

export interface IReactQuillEditor extends ReactQuillProps {
    id?: string;
    label?: string;
    placeholder?: string;
    errorMessage?: string;
    required?: boolean;
}

const ReactQuillEditor: React.FunctionComponent<IReactQuillEditor> = ({
    id,
    label,
    placeholder,
    errorMessage,
    required,
    value,
    onChange,
    ...other
}) => {
    const {
        reactQuillEditorContainer,
        errorMessageText
    } = useClassNames({ error: !!errorMessage });

    return (
        <Stack className={reactQuillEditorContainer}>
            <Label required={required} htmlFor={id}>{label}</Label>
            <ReactQuill
                id={id}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                {...other}
            />

            {errorMessage && (
                <Text variant="small" className={errorMessageText} >
                    {errorMessage}
                </Text>
            )}
        </Stack>
    );
}

export default ReactQuillEditor;
import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import { Text, TooltipHost } from '@fluentui/react';

import { useClassNames } from './iai-widget.classNames';

import './iai-widget.scss';

interface IIAIWidget {
    title: string;
    description: string;
    tooltip: string;
    to?: string;
    onClick?: () => void;
}

const IAIWidget: React.FunctionComponent<IIAIWidget> = ({
    title,
    description,
    tooltip,
    to,
    onClick
}) => {
    const {
        widgetContainer,
        widgetDescription
    } = useClassNames();

    return (
        <TooltipHost content={tooltip}>
            {to && (
                <Link className={`iai-widget-container ${widgetContainer}`} to={to}>
                    <Text block variant="xxLargePlus" className={`widget-description ${widgetDescription}`}>
                        {description}
                    </Text>
                    <Text block variant="mediumPlus">
                        {title}
                    </Text>
                </Link>
            )}

            {onClick && (
                <div className={`iai-widget-container ${widgetContainer}`} onClick={onClick}>
                    <Text block variant="xxLargePlus" className={`widget-description ${widgetDescription}`}>
                        {description}
                    </Text>
                    <Text block variant="mediumPlus">
                        {title}
                    </Text>
                </div>
            )}
        </TooltipHost>
    );
}

export default IAIWidget;
import React from 'react';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';

import { IBreadcrumbItem, Breadcrumb } from '@fluentui/react';

import { RouterSettings } from '../../../utils/router';

import './iai-breadcrumb.scss';

interface IIAIBreadcrumb {
    items: IBreadcrumbItem[];
}

const IAIBreadcrumb: React.FunctionComponent<IIAIBreadcrumb> = ({
    items
}) => {
    const navigate = useNavigate();

    const _handleOnClick = (item?: IBreadcrumbItem) => {
        if (!item || item.isCurrentItem) {
            return;
        }

        let requestedRoute = _.find(RouterSettings.ROUTES, entry => entry.routeKey === item.key);
        if (!requestedRoute) {
            return;
        }

        navigate(requestedRoute.path);
    }

    return (
        <Breadcrumb
            items={_.map(items, entry => (
                {
                    ...entry,
                    onClick: entry.isCurrentItem
                        ? undefined
                        : (_, dataItem) => _handleOnClick(dataItem),
                    onRenderContent: (dataItem) => (
                        <span className={`iai-breadcrumb-item ${dataItem?.isCurrentItem && 'iai-breadcrumb-item-current'}`}>
                            {dataItem?.text}
                        </span>
                    )
                }
            ))}
            maxDisplayedItems={10}
            ariaLabel="IAI Service Desk Breadcrumb"
            styles={{ root: { margin: 0, marginBottom: 15 } }}
        />
    );
}

export default IAIBreadcrumb;
export const TRANSLATION_KEYS = {
    Heading: "settingPage.eventReceiverDrawer.heading",
    ConfirmText: "common.saveText",
    DismissText: "common.dismissText",
    OnCreatingLabel: "settingPage.eventReceiverDrawer.onCreatingLabel",
    OnCreatingPlaceholder: "settingPage.eventReceiverDrawer.onCreatingPlaceholder",
    OnCreatedLabel: "settingPage.eventReceiverDrawer.onCreatedLabel",
    OnCreatedPlaceholder: "settingPage.eventReceiverDrawer.onCreatedPlaceholder",
    OnUpdatingLabel: "settingPage.eventReceiverDrawer.onUpdatingLabel",
    OnUpdatingPlaceholder: "settingPage.eventReceiverDrawer.onUpdatingPlaceholder",
    OnUpdatedLabel: "settingPage.eventReceiverDrawer.onUpdatedLabel",
    OnUpdatedPlaceholder: "settingPage.eventReceiverDrawer.onUpdatedPlaceholder",
    OnDeletingLabel: "settingPage.eventReceiverDrawer.onDeletingLabel",
    OnDeletingPlaceholder: "settingPage.eventReceiverDrawer.onDeletingPlaceholder",
    OnDeletedLabel: "settingPage.eventReceiverDrawer.onDeletedLabel",
    OnDeletedPlaceholder: "settingPage.eventReceiverDrawer.onDeletedPlaceholder"
};
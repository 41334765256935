import { getTheme, mergeStyleSets } from '@fluentui/react';

interface IIAIDetailsListClassNames {
    columnCell: string;
}

export const useClassNames = (): IIAIDetailsListClassNames => {
    const applicationTheme = getTheme();

    return mergeStyleSets({
        columnCell: {
            alignSelf: 'center'
        }
    });
}
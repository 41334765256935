import { getTheme, mergeStyleSets } from '@fluentui/react';

interface IAILoginLayoutClassNames {
    loginLayoutContainer: string;
}

export const useClassNames = (): IAILoginLayoutClassNames => {
    const applicationTheme = getTheme();

    return mergeStyleSets({
        loginLayoutContainer: {
            background: applicationTheme.semanticColors.bodyBackground,
            height: '100vh'
        }
    });
}
export const TRANSLATION_KEYS = {
    ActiveCriteriaMessage: "ticketPage.ticketsPivot.activeCriteriaMessage",
    ColumnPreviewTooltip: "ticketPage.ticketsPivot.columnPreviewTooltip",
    ColumnTitleLabel: "ticketPage.ticketsPivot.columnTitleLabel",
    ColumnRequestedByLabel: "ticketPage.ticketsPivot.columnRequestedByLabel",
    ColumnStatusLabel: "ticketPage.ticketsPivot.columnStatusLabel",
    ColumnDepartmentLabel: "ticketPage.ticketsPivot.columnDepartmentLabel",
    ColumnAgentLabel: "ticketPage.ticketsPivot.columnAgentLabel",
    ColumnPriorityLabel: "ticketPage.ticketsPivot.columnPriorityLabel",
    ColumnCreatedAtLabel: "ticketPage.ticketsPivot.columnCreatedAtLabel",
    ColumnClosedAtLabel: "ticketPage.ticketsPivot.columnClosedAtLabel",
    ColumnActionCommandEditLabel: "ticketPage.ticketsPivot.columnActionCommandEditLabel",
    ColumnActionCommandEditTooltip: "ticketPage.ticketsPivot.columnActionCommandEditTooltip",
    ColumnActionCommandChangeLogsLabel: "ticketPage.ticketsPivot.columnActionCommandChangeLogsLabel",
    ColumnActionCommandChangeLogsTooltip: "ticketPage.ticketsPivot.columnActionCommandChangeLogsTooltip",
    ColumnActionCommandDeleteLabel: "ticketPage.ticketsPivot.columnActionCommandDeleteLabel",
    ColumnActionCommandDeleteTooltip: "ticketPage.ticketsPivot.columnActionCommandDeleteTooltip",
    CommandCreateLabel: "ticketPage.ticketsPivot.commandCreateLabel",
    CommandCreateTooltip: "ticketPage.ticketsPivot.commandCreateTooltip",
    CommandFilterTooltip: "ticketPage.ticketsPivot.commandFilterTooltip",
    DetailsListEmptyContentLabel: "ticketPage.ticketsPivot.detailsListEmptyContentLabel"
};
export const TRANSLATION_KEYS = {
    Heading: "settingPage.topicPage.heading",
    SecondaryHeading: "settingPage.topicPage.secondaryHeading",
    BreadcrumbSettingHeading: "settingPage.heading",
    ColumnPreviewTooltip: "settingPage.topicPage.columnPreviewTooltip",
    ColumnTitleLabel: "settingPage.topicPage.columnTitleLabel",
    ColumnGroupLabel: "settingPage.topicPage.columnGroupLabel",
    ColumnCreatedAtLabel: "settingPage.topicPage.columnCreatedAtLabel",
    ColumnActionCommandUpdateLabel: "settingPage.topicPage.columnActionCommandUpdateLabel",
    ColumnActionCommandUpdateTooltip: "settingPage.topicPage.columnActionCommandUpdateTooltip",
    ColumnActionCommandDeleteLabel: "settingPage.topicPage.columnActionCommandDeleteLabel",
    ColumnActionCommandDeleteTooltip: "settingPage.topicPage.columnActionCommandDeleteTooltip",
    CommandCreateLabel: "settingPage.topicPage.commandCreateLabel",
    CommandCreateTooltip: "settingPage.topicPage.commandCreateTooltip",
    DetailsListEmptyContentLabel: "settingPage.topicPage.detailsListEmptyContentLabel",
};
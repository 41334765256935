import { useState } from 'react';
import _ from 'lodash';

import { useMsal } from "@azure/msal-react";

import { Ticket, TicketFilteringCriteria } from '../../../../api-client';
import { IHookCallback } from '../../../../common/models/IHookCallback';
import { TicketApiClient } from '../../../../providers/api-provider';
import { NotificationHelper } from '../../../../utils/notification';
import { useTranslation } from 'react-i18next';
import helpers from '../../../../utils/helpers/helpers';

export const useTicket = () => {
    const {
        instance
    } = useMsal();

    const {
        t: translate
    } = useTranslation();

    interface ITickets extends IHookCallback {
        criteria?: TicketFilteringCriteria;
    }

    const [tickets, setTickets] = useState<Ticket[]>();
    const [ticketsLoading, setTicketsLoading] = useState<boolean>(false);

    const handleTickets = async (params: ITickets) => {
        setTicketsLoading(true);

        const clientApi = await TicketApiClient(instance, helpers.getStoredI18Lang());

        clientApi.getFilteredTickets(params.criteria)
            .then((response: any) => {
                var orderedTickets = _.orderBy(response.data, entry => entry.createdAt, 'desc');

                setTickets(orderedTickets);
            })
            .catch((reason) => {
                setTickets([]);

                NotificationHelper.createNotification({
                    type: 'error',
                    message: translate('system.notification.getFilteredTickets.title'),
                    title: translate('system.notification.getFilteredTickets.message')
                });
            })
            .finally(() => {
                setTicketsLoading(false);
            });
    }

    interface IPersonalTickets extends IHookCallback {
        criteria?: TicketFilteringCriteria;
    }

    const [personalTickets, setPersonalTickets] = useState<Ticket[]>();
    const [personalTicketsLoading, setPersonalTicketsLoading] = useState<boolean>(false);

    const handlePersonalTickets = async (params: IPersonalTickets) => {
        setPersonalTicketsLoading(true);

        const clientApi = await TicketApiClient(instance, helpers.getStoredI18Lang());

        clientApi.getPersonalTickets(params.criteria)
            .then((response: any) => {
                var orderedTickets = _.orderBy(response.data, entry => entry.createdAt, 'desc');

                setPersonalTickets(orderedTickets);
            })
            .catch((reason) => {
                setPersonalTickets([]);

                NotificationHelper.createNotification({
                    type: 'error',
                    message: translate('system.notification.getPersonalTickets.title'),
                    title: translate('system.notification.getPersonalTickets.message')
                });
            })
            .finally(() => {
                setPersonalTicketsLoading(false);
            });
    }

    // CRUD Operations

    interface IRetrieveTicket extends IHookCallback {
        id: string;
        onRetrieveSuccess: (ticket: Ticket) => void;
    }

    const [retrieveTicket, setRetrieveTicket] = useState<Ticket>();
    const [retrieveTicketLoading, setRetrieveTicketLoading] = useState<boolean>(false);

    const handleRetrieveTicket = async (params: IRetrieveTicket) => {
        setRetrieveTicketLoading(true);

        const clientApi = await TicketApiClient(instance, helpers.getStoredI18Lang());

        clientApi.getTicket(params.id)
            .then((response: any) => {
                setRetrieveTicket(response.data);

                params.onRetrieveSuccess(response.data);
            })
            .catch((reason) => {
                params.onError && params.onError();

                NotificationHelper.createNotification({
                    type: 'error',
                    message: translate('system.notification.getTicketUseTicket.title'),
                    title: translate('system.notification.getTicketUseTicket.message')
                });
            })
            .finally(() => {
                setRetrieveTicketLoading(false);

                params.onAlways && params.onAlways();
            });
    }

    interface IDeleteTicket extends IHookCallback {
        id: string;
    }

    const [deleteTicketLoading, setDeleteTicketLoading] = useState<boolean>(false);

    const handleDeleteTicket = async (params: IDeleteTicket) => {
        setDeleteTicketLoading(true);

        const clientApi = await TicketApiClient(instance, helpers.getStoredI18Lang());

        clientApi.deleteTicket(params.id)
            .then((response) => {
                params.onSuccess && params.onSuccess();

                NotificationHelper.createNotification({
                    type: 'success',
                    message: translate('system.notification.deleteTicketSuccess.title'),
                    title: translate('system.notification.deleteTicketSuccess.message')
                });
            })
            .catch((reason) => {
                params.onError && params.onError();

                NotificationHelper.createNotification({
                    type: 'error',
                    message: translate('system.notification.deleteTicket.title'),
                    title: translate('system.notification.deleteTicket.message')
                });
            })
            .finally(() => {
                setDeleteTicketLoading(false);

                params.onAlways && params.onAlways();
            });
    }

    return {
        handleTickets,
        tickets,
        ticketsLoading,
        handlePersonalTickets,
        personalTickets,
        personalTicketsLoading,
        handleRetrieveTicket,
        retrieveTicket,
        retrieveTicketLoading,
        handleDeleteTicket,
        deleteTicketLoading
    }
}
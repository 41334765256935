export const TRANSLATION_KEYS = {
    Heading: "settingPage.noticePage.heading",
    SecondaryHeading: "settingPage.noticePage.secondaryHeading",
    BreadcrumbSettingHeading: "settingPage.heading",
    ColumnPreviewTooltip: "settingPage.noticePage.columnPreviewTooltip",
    ColumnTitleLabel: "settingPage.noticePage.columnTitleLabel",
    ColumnAvailableFromLabel: "settingPage.noticePage.columnAvailableFromLabel",
    ColumnAvailableToLabel: "settingPage.noticePage.columnAvailableToLabel",
    ColumnActionCommandUpdateLabel: "settingPage.noticePage.columnActionCommandUpdateLabel",
    ColumnActionCommandUpdateTooltip: "settingPage.noticePage.columnActionCommandUpdateTooltip",
    ColumnActionCommandDeleteLabel: "settingPage.noticePage.columnActionCommandDeleteLabel",
    ColumnActionCommandDeleteTooltip: "settingPage.noticePage.columnActionCommandDeleteTooltip",
    CommandCreateLabel: "settingPage.noticePage.commandCreateLabel",
    CommandCreateTooltip: "settingPage.noticePage.commandCreateTooltip",
    DetailsListEmptyContentLabel: "settingPage.noticePage.detailsListEmptyContentLabel",
};
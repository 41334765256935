import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { IconButton } from '@fluentui/react';

import { Group } from '../../../api-client';
import { RouterSettings } from '../../../utils/router';
import { TRANSLATION_KEYS } from './localization/translation-keys';
import { useGroup } from './hooks/useGroup';
import { useClassNames } from './groups-page.classNames';

import { IAIApplicationLayout } from '../../../common/layouts/IAIApplicationLayout';
import { IAIBreadcrumb } from '../../../common/components/IAIBreadcrumb';
import { IAIDetailsList, IAIStringColumn } from '../../../common/components/IAITable';
import { IAIDialog } from '../../../common/components/IAIDialog';

const GroupsPage: React.FunctionComponent = () => {
    const {
        settingGroupPageContainer
    } = useClassNames();

    const {
        handleGroups,
        groups,
        groupsLoading,
        handleCreateGroup,
        createGroupLoading,
        handleDeleteGroup,
        deleteGroupLoading
    } = useGroup();

    useEffect(() => {
        handleGroups();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const _handleSearchPredicate = (dataItem: Group, searchKey: string) => (
        _.includes(dataItem.displayName?.toLowerCase(), searchKey)
    );

    const [delegatedGroup, setDelegatedGroup] = useState<Group>();
    const [deleteDialogVisible, setDeleteDialogVisible] = useState<boolean>(false);
    const [groupDepartmentAssociation, setGroupDepartmentAssociation] = useState<boolean>(false);

    const onOperationCompleted = () => {
        setDeleteDialogVisible(false);
        setDelegatedGroup(undefined);
        handleGroups();
    };

    const {
        t: translate
    } = useTranslation();

    return (
        <IAIApplicationLayout
            icon={'EngineeringGroup'}
            heading={translate(TRANSLATION_KEYS.Heading)}
            secondaryHeading={translate(TRANSLATION_KEYS.SecondaryHeading)}
        >
            <div className={settingGroupPageContainer}>
                <IAIBreadcrumb
                    items={[
                        {
                            key: RouterSettings.ROUTES_KEYS.IAI_APP_SETTING,
                            text: translate(TRANSLATION_KEYS.BreadcrumbSettingHeading)
                        },
                        {
                            key: RouterSettings.ROUTES_KEYS.IAI_APP_SETTING_GROUP,
                            text: translate(TRANSLATION_KEYS.Heading),
                            isCurrentItem: true
                        }
                    ]}
                />

                <IAIDetailsList
                    dataSource={groups || []}
                    dataColumns={[
                        {
                            key: 'grp__pair',
                            fieldName: '',
                            name: '',
                            minWidth: 50,
                            maxWidth: 50,
                            onRender: (dataItem: Group) => (
                                <div className="text-center">
                                    <IconButton
                                        iconProps={
                                            {
                                                iconName: dataItem.isDepartment
                                                    ? 'CheckboxCompositeReversed'
                                                    : 'Checkbox'
                                            }
                                        }
                                        onClick={() => {
                                            const isDepartment = dataItem.isDepartment;

                                            dataItem.isDepartment = !isDepartment;
                                            setGroupDepartmentAssociation(!isDepartment);
                                            setDelegatedGroup(dataItem);
                                            setDeleteDialogVisible(true);
                                        }}
                                    />
                                </div>
                            )
                        },
                        {
                            key: 'grp__displayName',
                            fieldName: 'displayName',
                            name: translate(TRANSLATION_KEYS.ColumnTitleLabel),
                            minWidth: 200,
                            isResizable: true,
                            onRender: (dataItem: Group) => (
                                <IAIStringColumn align="start" text={dataItem.displayName} />
                            )
                        },
                        {
                            key: 'grp__mailAddress',
                            fieldName: 'mailAddress',
                            name: translate(TRANSLATION_KEYS.ColumnEmailLabel),
                            minWidth: 200,
                            isResizable: true,
                            onRender: (dataItem: Group) => (
                                <IAIStringColumn align="start" text={dataItem.mailAddress} />
                            )
                        }
                    ]}
                    dataLoading={groupsLoading}
                    emptyContentLabel={translate(TRANSLATION_KEYS.DetailsListEmptyContentLabel)}
                    onSearchPredicate={_handleSearchPredicate}
                />

                <IAIDialog
                    dialogProps={{
                        hidden: !deleteDialogVisible,
                        onDismissed: onOperationCompleted
                    }}
                    title={groupDepartmentAssociation === true
                        ? 'Associa Gruppo / Dipartimento'
                        : 'Dissocia Gruppo / Dipartimento'
                    }
                    subText={groupDepartmentAssociation === true
                        ? `Stai per associare Gruppo / Dipartimento. Vuoi continuare?`
                        : `Stai per eliminare l'associazione Gruppo / Dipartimento. Vuoi continuare?`
                    }
                    disabled={deleteGroupLoading}
                    confirmText={groupDepartmentAssociation === true
                        ? 'Associa'
                        : 'Dissocia'
                    }
                    dismissText={'Annulla'}
                    onConfirm={() => {
                        if (delegatedGroup && delegatedGroup.id) {
                            if (groupDepartmentAssociation) {
                                handleCreateGroup({
                                    group: delegatedGroup,
                                    onSuccess: onOperationCompleted,
                                    onError: onOperationCompleted,
                                    onAlways: onOperationCompleted
                                })
                            } else {
                                handleDeleteGroup({
                                    id: delegatedGroup.id,
                                    onSuccess: onOperationCompleted,
                                    onError: onOperationCompleted,
                                    onAlways: onOperationCompleted
                                });
                            }
                        }
                    }}
                    onDismiss={onOperationCompleted}
                />
            </div>
        </IAIApplicationLayout>
    );
}

export default GroupsPage;
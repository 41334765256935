import React, { useEffect, useState } from 'react';
import _ from 'lodash';

import { ContextualMenuItemType } from '@fluentui/react';
import { IconButton } from '@fluentui/react/lib/Button';

// Interfaces > Utils > Hooks > ClassNames
import { Property } from '../../../api-client';
import { SortingComparer } from '../../../utils/data-comparer';
import { DateTimeEnchanter } from '../../../utils/data-enchanters';

import { useProperties } from './components/PropertiesFormDrawer/hooks/useProperties';
import { useClassNames } from './properties.classNames';

import { IAIApplicationLayout } from '../../../common/layouts/IAIApplicationLayout';
import { IAICalloutColumn } from '../../../common/components/IAIDataTable/components/IAIColumns/IAICalloutColumn';
import { IAIDateColumn } from '../../../common/components/IAIDataTable/components/IAIColumns/IAIDateColumn';
import { IAIDataTable } from '../../../common/components/IAIDataTable';
import { IAIDialog } from '../../../common/components/IAIDialog';
import { PropertiesFormDrawer } from './components/PropertiesFormDrawer';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_KEYS } from './Localization/translation-keys';

const PropertiesPage: React.FunctionComponent = () => {
    const {
        handleProperties,
        properties,
        propertiesLoading,
        handleCreateProperties,
        createPropertiesLoading,
        handleRetrieveProperties,
        retrieveProperties,
        retrievePropertiesLoading,
        handleUpdateProperties,
        updatePropertiesLoading,
        handleDeleteProperties,
        deletePropertiesLoading
    } = useProperties();

    const {
        pageContainer,
        calloutSecondaryHeading,
        calloutText
    } = useClassNames();

    useEffect(() => {
        handleProperties();
    }, []);

    const _handleSearchProperties = (entry: Property, searchValue: string): boolean => (
        entry.displayName?.toLowerCase().search(searchValue) !== -1
    );

    const [drawerVisible, setDrawerVisible] = useState<boolean>(false);

    const _handleSubmit = (properties: Property, announcementId?: string) => {
        !announcementId
            ?
            handleCreateProperties({
                properties: properties,
                onSuccess: () => {
                    setDrawerVisible(false);
                    handleProperties();
                }
            })
            :
            handleUpdateProperties({
                id: announcementId,
                properties: properties,
                onSuccess: () => {
                    setDrawerVisible(false);
                    handleProperties();
                }
            });
    }
    
    const {
        t: translate
    } = useTranslation();

    const [deleteDialogVisible, setDeleteDialogVisible] = useState<boolean>(false);

    const [delegatedProperties, setDelegatedProperties] = useState<Property>();

    return (
        <IAIApplicationLayout
            icon={'AllApps'}
            heading={translate(TRANSLATION_KEYS.heading)}
            secondaryHeading={translate(TRANSLATION_KEYS.headingDescription)}
        >
            <div className={pageContainer}>
                <IAIDataTable
                    columns={[
                        {
                            key: 'property_preview__',
                            name: '',
                            minWidth: 50,
                            maxWidth: 50,
                            onRender: (element: any) => (
                                <IAICalloutColumn
                                    title={element.displayName}
                                    tooltipContent={translate(TRANSLATION_KEYS.tooltipContentTitle)}
                                    contentLoading={retrievePropertiesLoading}
                                    content={
                                        retrieveProperties &&
                                        <div className="col-12">
                                            <span className={calloutSecondaryHeading}>
                                                {`${translate(TRANSLATION_KEYS.tooltipContent)} ${DateTimeEnchanter.formatStandardDate(retrieveProperties.createdAt, 'it-IT')}`}
                                            </span>
                                            <span className={calloutText}>
                                                {/* {retrieveProperties.displayName} */}
                                                <hr />
                                                <div>
                                                    {_.map(retrieveProperties.values, (value, dataIndex) => (
                                                        <div key={dataIndex}>{value}</div>
                                                    ))}
                                                </div>
                                            </span>
                                        </div>
                                    }
                                    onEngage={() => handleRetrieveProperties({
                                        id: element.id,
                                        onRetrieveSuccess: (properties) => setDelegatedProperties(properties)
                                    })}
                                    onDismiss={() => setDelegatedProperties(undefined)}
                                />
                            )
                        },
                        {
                            key: 'property_displayName__',
                            fieldName: 'displayName',
                            name: translate(TRANSLATION_KEYS.tableName),
                            minWidth: 50,
                            isResizable: true,
                            sorter: SortingComparer.stringComparer
                        },
                        // {
                        //     key: 'property_values__',
                        //     fieldName: 'values',
                        //     name: 'Valori',
                        //     minWidth: 250,
                        //     isResizable: true,
                        //     onRender: (element: any) => (
                        //         <div>
                        //             {
                        //                 element && element.values.length > 0 ?
                        //                     _.map(element.values, (value, dataIndex) => (
                        //                         <div key={dataIndex}>{value}</div>
                        //                     ))
                        //                 : ''
                        //             }
                        //         </div>
                        //     )
                        // },
                        {
                            key: 'property_action__',
                            fieldName: '',
                            name: '',
                            minWidth: 50,
                            maxWidth: 50,
                            onRender: (element: any, dataIndex?: number) => (
                                <div className="text-center">
                                    <IconButton
                                        menuIconProps={{ iconName: 'CollapseMenu' }}
                                        menuProps={{
                                            items: [
                                                {
                                                    key: `${dataIndex}_menu_header__`,
                                                    itemType: ContextualMenuItemType.Header,
                                                    text: translate(TRANSLATION_KEYS.tableEditActions)
                                                },
                                                {
                                                    key: `${dataIndex}_menu_update__`,
                                                    iconProps: { iconName: 'EditCreate' },
                                                    text: translate(TRANSLATION_KEYS.tableEditEdit),
                                                    onClick: () => {
                                                        handleRetrieveProperties({
                                                            id: element.id,
                                                            onRetrieveSuccess: (properties) => {
                                                                setDelegatedProperties(properties);
                                                                setDrawerVisible(true);
                                                            }
                                                        });
                                                    }
                                                },
                                                {
                                                    key: `${dataIndex}_menu_delete__`,
                                                    iconProps: { iconName: 'Delete' },
                                                    text: translate(TRANSLATION_KEYS.tableEditDelete),
                                                    onClick: () => {
                                                        setDelegatedProperties(element);
                                                        setDeleteDialogVisible(true);
                                                    }
                                                }
                                            ]
                                        }}
                                    />
                                </div>
                            )
                        }
                    ]}
                    dataSource={properties || []}
                    dataLoading={propertiesLoading}
                    dataTableCommands={[{
                        disabled: propertiesLoading,
                        text: translate(TRANSLATION_KEYS.newProperty),
                        iconProps: { iconName: 'Add' },
                        onClick: () => {
                            setDelegatedProperties(undefined)
                            setDrawerVisible(true)
                        }
                    }]}
                    searchable
                    onSearch={_handleSearchProperties}
                />

                <IAIDialog
                    dialogProps={{
                        hidden: !deleteDialogVisible,
                        onDismissed: () => setDelegatedProperties(undefined)
                    }}
                    title={translate(TRANSLATION_KEYS.deleteProperty)}
                    subText={`${translate(TRANSLATION_KEYS.deletePropertyMsg)} "${delegatedProperties?.displayName}". ${translate(TRANSLATION_KEYS.proceedDelete)}`}
                    disabled={deletePropertiesLoading || retrievePropertiesLoading}
                    confirmText={translate(TRANSLATION_KEYS.DeleteText)}
                    dismissText={translate(TRANSLATION_KEYS.DismissText)}
                    onConfirm={() => {
                        if (delegatedProperties && delegatedProperties.id) {
                            handleDeleteProperties({
                                id: delegatedProperties.id,
                                onSuccess: () => {
                                    setDeleteDialogVisible(false);
                                    handleProperties();
                                }
                            });
                        }
                    }}
                    onDismiss={() => setDeleteDialogVisible(false)}
                />

                <PropertiesFormDrawer
                    disabled={retrievePropertiesLoading || createPropertiesLoading || updatePropertiesLoading}
                    drawerVisible={drawerVisible}
                    properties={delegatedProperties}
                    onDrawerDismiss={() => setDrawerVisible(false)}
                    onSubmit={_handleSubmit}
                />
            </div>
        </IAIApplicationLayout>
    );
}

export default PropertiesPage;
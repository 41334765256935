import React, { useEffect } from 'react';
import _ from 'lodash';

import { IconButton, IIconProps, Stack } from '@fluentui/react';
import { IButtonProps, DefaultButton } from '@fluentui/react/lib/Button';
import { SearchBox } from '@fluentui/react/lib/SearchBox';
import { useBoolean } from '@fluentui/react-hooks';

import { useClassNames } from './iai-data-table-command-bar.classNames';
import { useTranslation } from 'react-i18next';

interface IIAIDataTableCommandBar {
    commands?: IButtonProps[];
    disabled?: boolean;
    searchable?: boolean;
    onSearch: (ev?: React.ChangeEvent<HTMLInputElement>, newValue?: string) => void;
    filterable?: boolean;
    filterActive?: boolean;
    onFilter?: () => void;
}

const IAIDataTableCommandBar: React.FunctionComponent<IIAIDataTableCommandBar> = ({
    commands,
    disabled,
    searchable,
    onSearch,
    filterable,
    filterActive,
    onFilter
}) => {
    const filterIcon: IIconProps = { iconName: 'Filter' };

    const {
        commandBarContainer,
        commandBarFill
    } = useClassNames();

    const _handleFilter = (): void => {
        if (onFilter) onFilter();
    };

    const {
        t: translate
    } = useTranslation();

    return (
        <Stack className={commandBarContainer} horizontal>
            {_.map(commands, (entry, entryKey) => (
                <div key={entryKey}>
                    <DefaultButton {...entry} />
                </div>
            ))}

            <div className={commandBarFill} />

            {searchable &&
                <div style={{ width: 250 }}>
                    <SearchBox disabled={disabled} placeholder={translate("common.search") + "..."} underlined={true} onChange={onSearch} />
                </div>
            }

            {filterable &&
                <div>
                    <DefaultButton style={{ marginLeft: '25px', minWidth: '32px', padding: 0 }}
                        toggle
                        iconProps={filterIcon}
                        onClick={_handleFilter}
                        allowDisabledFocus
                        disabled={disabled}
                    >
                        {filterActive &&
                            <span className="position-absolute top-0 start-100 translate-middle bg-danger border border-light rounded-circle" style={{ padding: '.3rem' }}>
                                <span className="visually-hidden">
                                    {translate("common.activeFIlters")}
                                </span>
                            </span>
                        }
                    </DefaultButton>
                </div>
            }
        </Stack>
    );
}

export default IAIDataTableCommandBar;
import { useSelector } from 'react-redux';

import { getTheme, mergeStyleSets } from '@fluentui/react';

import { RootState } from '../../../../../../../redux/application/redux-store';

interface IAttachmentClassNames {
    calloutContainer: string;
    calloutBeakCurtain: string;
}

export const useClassNames = (): IAttachmentClassNames => {
    const applicationTheme = getTheme();

    const iaiSystem = useSelector((state: RootState) => state.system);

    return mergeStyleSets({
        calloutContainer: {
            padding: 20,
            width: 500,
            maxWidth: '90%'
        },
        calloutBeakCurtain: {
            background: iaiSystem.theme === 'light'
                ? applicationTheme.palette.themeLighter
                : applicationTheme.palette.themeTertiary
        }
    });
}
import React, { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import _ from 'lodash';

import {
    DefaultButton,
    PrimaryButton,
    Separator,
    Stack
} from '@fluentui/react';

import { RootState } from '../../../../../redux/application/redux-store';
import { RouterSettings } from '../../../../../utils/router';
import {
    Ticket,
    TicketModeEnum,
    TicketPriorityEnum,
    TicketStatusEnum,
    TicketChangeLog,
    Property,
    TicketAllOfProperties
} from '../../../../../api-client';
import { TRANSLATION_KEYS } from './localization/translation-keys';
import { useDepartmentMembers } from './hooks/useDepartmentMembers';

import { FormProvider, IAIDropdown, IAITextField, IAIEditor } from '../../../../../common/components/IAIForm';
import ChangeLogsAccordion from './components/ChangeLogsAccordion/change-logs-accordion';
import Attachment from './components/Attachment/attachment';
import { useProperties } from '../../../../Setting/SettingPropertyPage/components/PropertiesFormDrawer/hooks/useProperties';

interface FormValuesProps extends Ticket {
    areaKey: string;
    departmentKey: string;
    agentKey: string;
}

interface IDetailsForm {
    ticket?: Ticket;
    disabled?: boolean;
    dispatchManageAction?: boolean;
    changeLogs?: TicketChangeLog[];
    onFormConfirm: (dataItem: Ticket) => void;
}

const DetailsForm: React.FunctionComponent<IDetailsForm> = ({
    ticket,
    disabled,
    dispatchManageAction,
    changeLogs,
    onFormConfirm
}) => {
    const {
        t: translate
    } = useTranslation();

    const {
        handleProperties,
        properties,
        handleRetrieveProperties,
        retrieveProperties,
    } = useProperties();

    //#region Form-Configuration

    const formDataSchema = Yup.object().shape({
        mode: Yup.string().required(translate(TRANSLATION_KEYS.ModeErrorMessage)),
        areaKey: Yup.string().required(translate(TRANSLATION_KEYS.AreaErrorMessage)),
        departmentKey: Yup.string().required(translate(TRANSLATION_KEYS.DepartmentErrorMessage)),
        agentKey: Yup.string(),
        status: Yup.string().required(translate(TRANSLATION_KEYS.StatusErrorMessage)),
        priority: Yup.string(),
        subject: Yup.string().required(translate(TRANSLATION_KEYS.SubjectErrorMessage)),
        description: Yup.string().required(translate(TRANSLATION_KEYS.DescriptionErrorMessage)),
        note: Yup.string(),
        properties: Yup.array().of(Yup.object().shape({
            id: Yup.string(),
            value: Yup.string()
        }))
    });

    const defaultValues = useMemo(
        () => ({
            mode: ticket?.mode || TicketModeEnum.Level01,
            areaKey: ticket?.area?.key || '',
            departmentKey: ticket?.department?.key || '',
            agentKey: ticket?.agent?.key || '',
            status: ticket?.status || TicketStatusEnum.Open,
            priority: ticket?.priority || TicketPriorityEnum.Medium,
            subject: ticket?.subject || '',
            description: ticket?.description || '',
            note: ticket?.note || '',
            properties: ticket?.properties ?? []
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [ticket]
    );

    const methods = useForm<FormValuesProps>({ resolver: yupResolver(formDataSchema), defaultValues });

    const {
        reset,
        watch,
        setValue,
        getValues,
        handleSubmit,
        formState: { isSubmitting }
    } = methods;

    useEffect(() => {
        reset(defaultValues);
        handleProperties();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ticket]);

    //#endregion

    //#region Dropdown-Management

    const iaiSystem = useSelector((state: RootState) => state.system);

    const areaOptions = useMemo(
        () => (
            _.map(iaiSystem.areas, entry => ({ key: entry.key!, text: entry.text! }))
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [iaiSystem.areas]
    );

    const watchArea = watch('areaKey');

    const departmentOptions = useMemo(
        () => {
            const selectedArea = _.find(iaiSystem.areas, entry => entry.key === watchArea);

            return (
                _.map(selectedArea?.departments, entry => ({ key: entry.key!, text: entry.text! }))
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [watchArea]
    );

    const watchDepartment = watch('departmentKey');

    useEffect(() => {
        watchDepartment && handleRetrieveMembers({ id: watchDepartment });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchDepartment]);

    const {
        handleRetrieveMembers,
        retrieveMembers,
        retrieveMembersLoading
    } = useDepartmentMembers();

    const agentOptions = useMemo(
        () => (
            watchDepartment
                ? _.map(retrieveMembers, entry => ({ key: entry.key!, text: entry.text! }))
                : []
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [retrieveMembers, watchDepartment]
    );

    const propertiesOption = useCallback((propertyId: string | undefined) => {
        const property = properties?.find(x => x.id === propertyId);

        return property?.values?.map((val: any) => {
            return { key: val?.key, text: val?.text };
        }) ?? [];
    }, [properties]);

    //#endregion

    const iaiAccount = useSelector((state: RootState) => state.account);

    const _changeFormUnauthorize = () => (
        ticket &&
        ticket.status === 'closed' &&
        (iaiAccount.role === 'departmentAgent' || iaiAccount.role === 'user')
    );

    const navigate = useNavigate();

    const _handleDismiss = () => {
        navigate(RouterSettings.PATHS.dashboard.ticket.root);
    }

    const _handleSubmit = (data: FormValuesProps) => {
        const selectedArea = _.find(iaiSystem.areas, entry => entry.key === data.areaKey);

        let ticketData = {
            ...ticket,
            ...data,
            area: {
                tenantId: selectedArea?.tenantId,
                id: selectedArea?.id,
                displayName: selectedArea?.displayName,
                key: selectedArea?.key,
                text: selectedArea?.text
            },
            department: _.find(selectedArea?.departments, entry => entry.key === data.departmentKey),
            agent: _.find(retrieveMembers, entry => entry.key === data.agentKey)
        };

        onFormConfirm(ticketData);
    }

    const disableCondition = disabled || _changeFormUnauthorize() || isSubmitting;

    function updateProperties(arr: TicketAllOfProperties[], id: string | undefined, updatedData: Partial<TicketAllOfProperties>): TicketAllOfProperties[] {
        return arr.map((item) => (item.id === id ? { ...item, ...updatedData } : item));
    }

    return (
        <FormProvider methods={methods}>
            
            <Stack tokens={{ childrenGap: 15 }}>
                {/* {dispatchManageAction && (
                    <div className="row">
                        <div className="col-12 col-md-4">
                            <IAIDropdown
                                name="mode"
                                label={translate(TRANSLATION_KEYS.ModeLabel)}
                                placeholder={translate(TRANSLATION_KEYS.ModePlaceholder)}
                                required
                                disabled={disableCondition}
                                options={[
                                    { key: TicketModeEnum.Level01, text: translate(TRANSLATION_KEYS.ModeOptionLevel01) },
                                    { key: TicketModeEnum.Level02, text: translate(TRANSLATION_KEYS.ModeOptionLevel02) },
                                    { key: TicketModeEnum.Level03, text: translate(TRANSLATION_KEYS.ModeOptionLevel03) }
                                ]}
                            />
                        </div>
                    </div>
                )}
                */}
                <div className="row">
                    <div className="col-12 col-md-4">
                        <IAIDropdown
                            name="areaKey"
                            label={translate(TRANSLATION_KEYS.AreaLabel)}
                            placeholder={translate(TRANSLATION_KEYS.AreaPlaceholder)}
                            required
                            disabled={disableCondition || retrieveMembersLoading || !areaOptions.length}
                            options={areaOptions}
                            onChange={(ev, option) => {
                                if (option) {
                                    setValue('areaKey', option.key as string);
                                    setValue('departmentKey', '');
                                    setValue('agentKey', '');

                                    const selectedAreaDepartments = _.find(iaiSystem.areas, entry => entry.key === option.key)?.departments || [];

                                    selectedAreaDepartments.length === 1 &&
                                        setValue('departmentKey', selectedAreaDepartments[0].key!);
                                }
                            }}
                        />
                    </div>
                    <div className="col-12 col-md-4">
                        <IAIDropdown
                            name="departmentKey"
                            label={translate(TRANSLATION_KEYS.DepartmentLabel)}
                            placeholder={translate(TRANSLATION_KEYS.DepartmentPlaceholder)}
                            required
                            disabled={disableCondition || retrieveMembersLoading || !departmentOptions.length}
                            options={departmentOptions}
                            onChange={(ev, option) => {
                                if (option) {
                                    setValue('departmentKey', option.key as string);
                                    setValue('agentKey', '');
                                }
                            }}
                        />
                    </div>

                    {dispatchManageAction && (
                        <div className="col-12 col-md-4">
                            <IAIDropdown
                                name="agentKey"
                                label={translate(TRANSLATION_KEYS.AgentLabel)}
                                placeholder={translate(TRANSLATION_KEYS.AgentPlaceholder)}
                                disabled={disableCondition || retrieveMembersLoading || !agentOptions.length}
                                options={agentOptions}
                            />
                        </div>
                    )}
                </div>

                <div className="row">
                    {dispatchManageAction && (
                        <div className="col-12 col-md-4">
                            <IAIDropdown
                                name="status"
                                label={translate(TRANSLATION_KEYS.StatusLabel)}
                                placeholder={translate(TRANSLATION_KEYS.StatusPlaceholder)}
                                required
                                disabled={disableCondition}
                                options={[
                                    { key: TicketStatusEnum.Open, text: translate(TRANSLATION_KEYS.StatusOptionOpen) },
                                    { key: TicketStatusEnum.Working, text: translate(TRANSLATION_KEYS.StatusOptionWorking) },
                                    { key: TicketStatusEnum.Pending, text: translate(TRANSLATION_KEYS.StatusOptionPending) },
                                    { key: TicketStatusEnum.Closed, text: translate(TRANSLATION_KEYS.StatusOptionClosed) }
                                ]}
                            />
                        </div>
                    )}

                    <div className="col-12 col-md-4">
                        <IAIDropdown
                            name="priority"
                            label={translate(TRANSLATION_KEYS.PriorityLabel)}
                            placeholder={translate(TRANSLATION_KEYS.PriorityPlaceholder)}
                            disabled={disableCondition}
                            options={[
                                { key: TicketPriorityEnum.Low, text: translate(TRANSLATION_KEYS.PriorityOptionLow) },
                                { key: TicketPriorityEnum.Medium, text: translate(TRANSLATION_KEYS.PriorityOptionMedium) },
                                { key: TicketPriorityEnum.High, text: translate(TRANSLATION_KEYS.PriorityOptionHigh) }
                            ]}
                        />
                    </div>
                </div>

                <Separator />

                {
                    properties && properties?.length > 0 ?
                        <Stack tokens={{ childrenGap: 15 }}>
                            <div className="row">
                                {
                                    properties?.map((property: Property) => {

                                        return <div key={`property-${property?.id}`} className='col-12 col-md-4'>
                                            <IAIDropdown
                                                name={`${property?.id}`}
                                                label={property?.displayName ?? ""}
                                                placeholder={translate(TRANSLATION_KEYS.selectOption)}
                                                isPropertyField
                                                disabled={disableCondition}
                                                options={propertiesOption(property.id)}
                                                onChange={(event, option) => {
                                                    const valuesProvided = [...getValues('properties') ?? []];
                                                    const updatedData = { id: property?.id, value: option?.text };
                                                    const item = valuesProvided?.find(x => x.id === property?.id);
                                                    
                                                    if (item) {
                                                        if (option?.text) {
                                                            setValue('properties', updateProperties(valuesProvided, property?.id, updatedData))
                                                        }
                                                    } else {
                                                        if (option?.text) {
                                                            setValue('properties', [...valuesProvided, updatedData]);
                                                        }
                                                    }
                                                }}
                                            />
                                        </div>
                                    })
                                }
                            </div>
                        </Stack> : null
                }

                <Separator />
            </Stack>

            <Stack tokens={{ childrenGap: 15 }}>
                <IAITextField
                    name="subject"
                    label={translate(TRANSLATION_KEYS.SubjectLabel)}
                    placeholder={translate(TRANSLATION_KEYS.SubjectPlaceholder)}
                    required
                    disabled={disableCondition}
                />

                <IAIEditor
                    name="description"
                    label={translate(TRANSLATION_KEYS.DescriptionLabel)}
                    placeholder={translate(TRANSLATION_KEYS.DescriptionPlaceholder)}
                    required
                    readOnly={disableCondition}
                />

                {dispatchManageAction && (
                    <Stack tokens={{ childrenGap: 25 }}>
                        <IAITextField
                            name="note"
                            label={translate(TRANSLATION_KEYS.NoteLabel)}
                            placeholder={translate(TRANSLATION_KEYS.NotePlaceholder)}
                            multiline
                            rows={5}
                            resizable={false}
                            disabled={disableCondition}
                        />

                        {iaiAccount.role === 'administrator' && (
                            <ChangeLogsAccordion changeLogs={changeLogs} disabled={disableCondition} />
                        )}
                    </Stack>
                )}

                <Separator />

                <Attachment ticketId={ticket?.id} disabled={disableCondition} />

                <Separator />

                <Stack horizontal horizontalAlign={'end'} tokens={{ childrenGap: 15 }}>
                    <DefaultButton
                        disabled={disableCondition}
                        iconProps={{ iconName: 'Cancel' }}
                        styles={{ root: { marginRight: 10 } }}
                        onClick={_handleDismiss}
                    >
                        {translate(TRANSLATION_KEYS.DismissText)}
                    </DefaultButton>
                    <PrimaryButton
                        type="submit"
                        disabled={disableCondition}
                        iconProps={{ iconName: 'CheckMark' }}
                        onClick={handleSubmit(_handleSubmit)}
                    >
                        {translate(TRANSLATION_KEYS.ConfirmText)}
                    </PrimaryButton>
                </Stack>
            </Stack>
        </FormProvider>
    );
}

export default DetailsForm;
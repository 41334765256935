import React from 'react';
import { useFormContext, Controller, useWatch } from 'react-hook-form';

import { IDropdownProps, Dropdown } from '@fluentui/react';

interface IIAIDropdown extends IDropdownProps {
    name: string;
    isPropertyField?: boolean;
}

const IAIDropdown: React.FunctionComponent<IIAIDropdown> = ({
    name,
    isPropertyField,
    ...other
}) => {
    const {
        control,
    } = useFormContext();

    const properties = useWatch({
        control, 
        name: 'properties'
    });

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => {
                let value = field.value || null

                if(isPropertyField) {
                    value = properties?.find((x: any) => x.id === name)?.value;
                }
                return (
                    <Dropdown
                        {...field}
                        errorMessage={error?.message}
                        selectedKey={value}
                        onChange={(_, option) => option && field.onChange(option.key)}
                        {...other}
                    />
                )
            }}
        />
    );
}

export default IAIDropdown;
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import _ from 'lodash';

import { ContextualMenuItemType } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';

import { Announcement } from '../../../api-client';
import { RouterSettings } from '../../../utils/router';
import { TRANSLATION_KEYS } from './localization/translation-keys';
import { useNotice } from './hooks/useNotice';
import { useClassNames } from './setting-notice-page.classNames';

import { IAIApplicationLayout } from '../../../common/layouts/IAIApplicationLayout';
import { IAIBreadcrumb } from '../../../common/components/IAIBreadcrumb';
import {
    IAIDetailsList,
    IAIContextualMenuColumn,
    IAIDatetimeColumn,
    IAIPreviewColumn,
    IAIStringColumn
} from '../../../common/components/IAITable';
import DeleteDialog from './components/DeleteDialog/delete-dialog';
import FormDrawer from './components/FormDrawer/form-drawer';

const SettingNoticePage: React.FunctionComponent = () => {
    const {
        settingNoticePageContainer
    } = useClassNames();

    const {
        handleNotices,
        notices,
        noticesLoading,
        handleCreateNotice,
        createNoticeLoading,
        handleUpdateNotice,
        updateNoticeLoading,
        handleDeleteNotice,
        deleteNoticeLoading
    } = useNotice();

    useEffect(() => {
        handleNotices();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const _handleSearchPredicate = (dataItem: Announcement, searchKey: string) => (
        _.includes(dataItem.title?.toLowerCase(), searchKey) ||
        _.includes(dataItem.description?.toLowerCase(), searchKey)
    );

    //#region Form-Management

    const [noticeDrawerVisible, { toggle: toggleNoticeDrawerVisible }] = useBoolean(false);
    const [deleteNoticeVisible, { toggle: toggleDeleteNoticeVisible }] = useBoolean(false);
    const [delegateNotice, setDelegateNotice] = useState<Announcement>();

    const _handleCreateAction = () => {
        setDelegateNotice(undefined);
        toggleNoticeDrawerVisible();
    }

    const _handleUpsertAction = (dataItem: Announcement) => {
        setDelegateNotice(dataItem);
        toggleNoticeDrawerVisible();
    }

    const _handleDismissUpsertAction = () => {
        setDelegateNotice(undefined);
        toggleNoticeDrawerVisible();
    }

    const _handleDeleteAction = (dataItem: Announcement) => {
        setDelegateNotice(dataItem);
        toggleDeleteNoticeVisible();
    }

    const _handleDismissDeleteAction = () => {
        setDelegateNotice(undefined);
        toggleDeleteNoticeVisible();
    }

    const _handleUpsertNotice = (dataItem: Announcement) => {
        const onUpsertSuccess = () => {
            _handleDismissUpsertAction();

            handleNotices();
        };

        dataItem.id
            ?
            handleUpdateNotice({ id: dataItem.id, announcement: dataItem, onSuccess: onUpsertSuccess })
            :
            handleCreateNotice({ announcement: dataItem, onSuccess: onUpsertSuccess });
    }

    const _handleDeleteNotice = () => {
        if (delegateNotice?.id) {
            handleDeleteNotice({
                id: delegateNotice.id,
                onSuccess: () => {
                    _handleDismissDeleteAction();

                    handleNotices();
                }
            });
        }
    }

    //#endregion

    const {
        t: translate
    } = useTranslation();

    return (
        <IAIApplicationLayout
            icon={'Flag'}
            heading={translate(TRANSLATION_KEYS.Heading)}
            secondaryHeading={translate(TRANSLATION_KEYS.SecondaryHeading)}
        >
            <div className={settingNoticePageContainer}>
                <IAIBreadcrumb
                    items={[
                        {
                            key: RouterSettings.ROUTES_KEYS.IAI_APP_SETTING,
                            text: translate(TRANSLATION_KEYS.BreadcrumbSettingHeading)
                        },
                        {
                            key: RouterSettings.ROUTES_KEYS.IAI_APP_SETTING_NOTICE,
                            text: translate(TRANSLATION_KEYS.Heading),
                            isCurrentItem: true
                        }
                    ]}
                />

                <IAIDetailsList
                    dataSource={notices || []}
                    dataColumns={[
                        {
                            key: 'not__preview',
                            fieldName: '',
                            name: '',
                            minWidth: 50,
                            maxWidth: 50,
                            onRender: (dataItem: Announcement) => (
                                <IAIPreviewColumn
                                    heading={dataItem.title}
                                    content={parse(dataItem.description || '')}
                                    tooltip={translate(TRANSLATION_KEYS.ColumnPreviewTooltip)}
                                />
                            )
                        },
                        {
                            key: 'not__title',
                            fieldName: 'title',
                            name: translate(TRANSLATION_KEYS.ColumnTitleLabel),
                            minWidth: 200,
                            isResizable: true,
                            onRender: (dataItem: Announcement) => (
                                <IAIStringColumn align="start" text={dataItem.title} />
                            )
                        },
                        {
                            key: 'not__availableFrom',
                            fieldName: 'availableFrom',
                            name: translate(TRANSLATION_KEYS.ColumnAvailableFromLabel),
                            minWidth: 100,
                            maxWidth: 100,
                            onRender: (dataItem: Announcement) => (
                                <IAIDatetimeColumn align="start" datetime={dataItem.availableFrom} />
                            )
                        },
                        {
                            key: 'not__availableTo',
                            fieldName: 'availableTo',
                            name: translate(TRANSLATION_KEYS.ColumnAvailableToLabel),
                            minWidth: 100,
                            maxWidth: 100,
                            onRender: (dataItem: Announcement) => (
                                <IAIDatetimeColumn align="start" datetime={dataItem.availableTo} />
                            )
                        },
                        {
                            key: 'not__actions',
                            fieldName: '',
                            name: '',
                            minWidth: 50,
                            maxWidth: 50,
                            onRender: (dataItem: Announcement, dataIndex) => (
                                <IAIContextualMenuColumn
                                    menuItems={[
                                        {
                                            key: `menuItem__update_${dataIndex}`,
                                            iconProps: { iconName: 'EditCreate' },
                                            text: translate(TRANSLATION_KEYS.ColumnActionCommandUpdateLabel),
                                            title: translate(TRANSLATION_KEYS.ColumnActionCommandUpdateTooltip),
                                            onClick: () => _handleUpsertAction(dataItem)
                                        },
                                        { key: `menuItem__divider_${dataIndex}`, itemType: ContextualMenuItemType.Divider },
                                        {
                                            key: `menuItem__delete_${dataIndex}`,
                                            iconProps: { iconName: 'Delete' },
                                            text: translate(TRANSLATION_KEYS.ColumnActionCommandDeleteLabel),
                                            title: translate(TRANSLATION_KEYS.ColumnActionCommandDeleteTooltip),
                                            onClick: () => _handleDeleteAction(dataItem)
                                        }
                                    ]}
                                />
                            )
                        }
                    ]}
                    dataLoading={noticesLoading}
                    commands={[
                        {
                            key: '',
                            disabled: noticesLoading,
                            text: translate(TRANSLATION_KEYS.CommandCreateLabel),
                            title: translate(TRANSLATION_KEYS.CommandCreateTooltip),
                            iconProps: { iconName: 'Add' },
                            onClick: _handleCreateAction
                        }
                    ]}
                    emptyContentLabel={translate(TRANSLATION_KEYS.DetailsListEmptyContentLabel)}
                    onItemInvoked={(dataItem: Announcement) => _handleUpsertAction(dataItem)}
                    onSearchPredicate={_handleSearchPredicate}
                />

                <FormDrawer
                    notice={delegateNotice}
                    disabled={noticesLoading || createNoticeLoading || updateNoticeLoading}
                    drawerVisible={noticeDrawerVisible}
                    onDrawerConfirm={_handleUpsertNotice}
                    onDrawerDismiss={_handleDismissUpsertAction}
                />

                <DeleteDialog
                    disabled={noticesLoading || deleteNoticeLoading}
                    modalVisible={deleteNoticeVisible}
                    onModalConfirm={_handleDeleteNotice}
                    onModalDismiss={_handleDismissDeleteAction}
                />
            </div>
        </IAIApplicationLayout>
    );
}

export default SettingNoticePage;
import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';

import ReactQuillEditor, { IReactQuillEditor } from './components/ReactQuillEditor/react-quill-editor';

interface IIAIEditor extends IReactQuillEditor {
    name: string;
}

const IAIEditor: React.FunctionComponent<IIAIEditor> = ({
    name,
    ...other
}) => {
    const {
        control
    } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <ReactQuillEditor
                    id={name}
                    value={field.value}
                    errorMessage={error?.message}
                    onChange={field.onChange}
                    {...other}
                />
            )}
        />
    );
}

export default IAIEditor;
import { getTheme, mergeStyleSets } from '@fluentui/react';

interface ISettingGroupPageClassNames {
    settingGroupPageContainer: string;
}

export const useClassNames = (): ISettingGroupPageClassNames => {
    const applicationTheme = getTheme();

    return mergeStyleSets({
        settingGroupPageContainer: {
            background: applicationTheme.semanticColors.bodyBackground
        }
    });
}
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { loadTheme } from '@fluentui/react';
import { initializeIcons } from '@fluentui/font-icons-mdl2';

import { RootState } from '../../../redux/application/redux-store';
import { ApplicationSettings } from '../../../utils/application';
import { SessionStorageHelper } from '../../../utils/storage';
import { useAuthContext } from '../../../contexts/auth-context';
import { useSystem } from '../../hooks/useSystem';

import './application-template.scss';

import { IAIToastContainer } from '../../components/IAIToastContainer';
import { getContextEntitySubUrl } from '../../../providers/authentication/ms-teams-provider';
import { RouterSettings } from '../../../utils/router';
import { useNavigate } from 'react-router-dom';
import * as MsTeams from "@microsoft/teams-js";

const ApplicationTemplate: React.FunctionComponent = (props) => {
    const {
        isAuthenticated,
        contextType
    } = useAuthContext();

    const {
        handleSystemAgents,
        handleSystemAreas,
        handleSystemGroups,
        handleSystemNotices,
        handleSystemTopics
    } = useSystem();

    const navigate = useNavigate();

    initializeIcons();

    useEffect(() => {
        if (isAuthenticated) {
            handleSystemNotices();

            let agentsInit: boolean = SessionStorageHelper.retrieveItem(SessionStorageHelper.STORAGE_KEYS.IAI_SYSTEM_AGENT_INIT);
            !agentsInit && handleSystemAgents();

            let areasInit: boolean = SessionStorageHelper.retrieveItem(SessionStorageHelper.STORAGE_KEYS.IAI_SYSTEM_AREA_INIT);
            !areasInit && handleSystemAreas();

            let groupsInit: boolean = SessionStorageHelper.retrieveItem(SessionStorageHelper.STORAGE_KEYS.IAI_SYSTEM_GROUP_INIT);
            !groupsInit && handleSystemGroups();

            let departmentsInit: boolean = SessionStorageHelper.retrieveItem(SessionStorageHelper.STORAGE_KEYS.IAI_SYSTEM_DEPARTMENT_INIT);
            !departmentsInit && handleSystemTopics();

            if(MsTeams.pages.isSupported()) {
                getContextEntitySubUrl().then((subUrlResponse) => { 
                    if(subUrlResponse) {
                        setTimeout(() => {
                            navigate(RouterSettings.PATHS.dashboard.ticket.dynamic(subUrlResponse));
                        }, 10)
                    }
                });
            }    
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated]);

    const iaiSystem = useSelector((state: RootState) => state.system);

    useEffect(() => {
        iaiSystem.theme === 'dark'
            ? loadTheme(ApplicationSettings.IAI_APP_THEME_DARK)
            : loadTheme(ApplicationSettings.IAI_APP_THEME);
    }, [iaiSystem.theme]);

    return (
        <React.Fragment>
            {props.children}

            <IAIToastContainer />
        </React.Fragment>
    );
}

export default ApplicationTemplate;
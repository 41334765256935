import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { useMsal } from "@azure/msal-react";

import {
    AnnouncementApiClient,
    AreaApiClient,
    DepartmentApiClient,
    SystemApiClient
} from '../../providers/api-provider';
import { initAgents, initAreas, initDepartments, initGroups, initNotices } from '../../redux/slices/System/systemSlice';
import { NotificationHelper } from '../../utils/notification';
import helpers from '../../utils/helpers/helpers';

export const useSystem = () => {
    const {
        instance
    } = useMsal();

    const {
        t: translate
    } = useTranslation();

    const dispatch = useDispatch();

    const [systemAgentsLoading, setSystemAgentsLoading] = useState<boolean>(false);

    const handleSystemAgents = async () => {
        setSystemAgentsLoading(true);

        const clientApi = await SystemApiClient(instance);

        clientApi.getAgents()
            .then((response) => {
                dispatch(initAgents(response.data));
            })
            .catch((reason) => {
                NotificationHelper.createNotification({
                    type: 'error',
                    title: translate('system.notification.systemAgents.title'),
                    message: translate('system.notification.systemAgents.message')
                });
            })
            .finally(() => {
                setSystemAgentsLoading(false);
            });
    }

    const [systemAreasLoading, setSystemAreasLoading] = useState<boolean>(false);

    const handleSystemAreas = async () => {
        setSystemAreasLoading(true);

        const clientApi = await AreaApiClient(instance);

        clientApi.getAreas()
            .then((response) => {
                dispatch(initAreas(response.data));
            })
            .catch((reason) => {
                NotificationHelper.createNotification({
                    type: 'error',
                    title: translate('system.notification.systemAreas.title'),
                    message: translate('system.notification.systemAreas.message')
                });
            })
            .finally(() => {
                setSystemAreasLoading(false);
            });
    }

    const [systemGroupsLoading, setSystemGroupsLoading] = useState<boolean>(false);

    const handleSystemGroups = async () => {
        setSystemGroupsLoading(true);

        const clientApi = await SystemApiClient(instance);

        clientApi.getGroupsEnabled()
            .then((response) => {
                dispatch(initGroups(response.data));
            })
            .catch((reason) => {
                NotificationHelper.createNotification({
                    type: 'error',
                    title: translate('system.notification.systemGroups.title'),
                    message: translate('system.notification.systemGroups.message')
                });
            })
            .finally(() => {
                setSystemGroupsLoading(false);
            });
    }

    const [systemNoticesLoading, setSystemNoticesLoading] = useState<boolean>(false);

    const handleSystemNotices = async () => {
        setSystemNoticesLoading(true);

        const clientApi = await AnnouncementApiClient(instance);

        clientApi.getAnnouncements()
            .then((response) => {
                dispatch(initNotices(response.data));
            })
            .catch((reason) => {
                NotificationHelper.createNotification({
                    type: 'error',
                    title: translate('system.notification.systemNotices.title'),
                    message: translate('system.notification.systemNotices.message')
                });
            })
            .finally(() => {
                setSystemNoticesLoading(false);
            });
    }

    const [systemTopicsLoading, setSystemTopicsLoading] = useState<boolean>(false);

    const handleSystemTopics = async () => {
        setSystemTopicsLoading(true);

        const clientApi = await DepartmentApiClient(instance);

        clientApi.getDepartments()
            .then((response) => {
                dispatch(initDepartments(response.data));
            })
            .catch((reason) => {
                NotificationHelper.createNotification({
                    type: 'error',
                    title: translate('system.notification.systemTopics.title'),
                    message: translate('system.notification.systemTopics.message')
                });
            })
            .finally(() => {
                setSystemTopicsLoading(false);
            });
    }

    return {
        handleSystemAgents,
        systemAgentsLoading,
        handleSystemAreas,
        systemAreasLoading,
        handleSystemGroups,
        systemGroupsLoading,
        handleSystemNotices,
        systemNoticesLoading,
        handleSystemTopics,
        systemTopicsLoading
    }
}
import { useState } from 'react';

import { useMsal } from "@azure/msal-react";

import { EventReceiver } from '../../../../api-client';
import { IHookCallback } from '../../../../common/models';
import { EventReceiverApiClient } from '../../../../providers/api-provider';
import { NotificationHelper } from '../../../../utils/notification';
import { useTranslation } from 'react-i18next';

export const useEventReceiver = () => {
    const {
        instance
    } = useMsal();

    const {
        t: translate
    } = useTranslation();

    const [eventReceivers, setEventReceivers] = useState<EventReceiver[]>();
    const [eventReceiversLoading, setEventReceiversLoading] = useState<boolean>(false);

    const handleEventReceivers = async () => {
        setEventReceiversLoading(true);

        const clientApi = await EventReceiverApiClient(instance);

        clientApi.getEventReceivers()
            .then((response) => {
                setEventReceivers(response.data);
            })
            .catch((reason) => {
                setEventReceivers([]);

                NotificationHelper.createNotification({
                    type: 'error',
                    message: translate('system.notification.getEventReceivers.title'),
                    title: translate('system.notification.getEventReceivers.message')
                });
            })
            .finally(() => {
                setEventReceiversLoading(false);
            });
    }

    interface ICreateEventReceiver extends IHookCallback {
        eventReceiver: EventReceiver;
    }

    const [createEventReceiverLoading, setCreateEventReceiverLoading] = useState<boolean>(false);

    const handleCreateEventReceiver = async (params: ICreateEventReceiver) => {
        setCreateEventReceiverLoading(true);

        const clientApi = await EventReceiverApiClient(instance);

        clientApi.insertEventReceiver(params.eventReceiver)
            .then((response) => {
                params.onSuccess && params.onSuccess();

                NotificationHelper.createNotification({
                    type: 'success',
                    message: translate('system.notification.insertEventReceiverSuccess.title'),
                    title: translate('system.notification.insertEventReceiverSuccess.message')
                });
            })
            .catch((reason) => {
                params.onError && params.onError();

                NotificationHelper.createNotification({
                    type: 'error',
                    message: translate('system.notification.insertEventReceiver.title'),
                    title: translate('system.notification.insertEventReceiver.message')
                });
            })
            .finally(() => {
                setCreateEventReceiverLoading(false);

                params.onAlways && params.onAlways();
            });
    }

    interface IUpdateEventReceiver extends IHookCallback {
        id: string;
        eventReceiver: EventReceiver;
    }

    const [updateEventReceiverLoading, setUpdateEventReceiverLoading] = useState<boolean>(false);

    const handleUpdateEventReceiver = async (params: IUpdateEventReceiver) => {
        setUpdateEventReceiverLoading(true);

        const clientApi = await EventReceiverApiClient(instance);

        clientApi.updateEventReceiver(params.id, params.eventReceiver)
            .then((response) => {
                params.onSuccess && params.onSuccess();

                NotificationHelper.createNotification({
                    type: 'success',
                    message: translate('system.notification.updateEventReceiverSuccess.title'),
                    title: translate('system.notification.updateEventReceiverSuccess.message')
                });
            })
            .catch((reason) => {
                params.onError && params.onError();

                NotificationHelper.createNotification({
                    type: 'error',
                    message: translate('system.notification.updateEventReceiver.title'),
                    title: translate('system.notification.updateEventReceiver.message')
                });
            })
            .finally(() => {
                setUpdateEventReceiverLoading(false);

                params.onAlways && params.onAlways();
            });
    }

    return {
        handleEventReceivers,
        eventReceivers,
        eventReceiversLoading,
        handleCreateEventReceiver,
        createEventReceiverLoading,
        handleUpdateEventReceiver,
        updateEventReceiverLoading
    }
}
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import moment from 'moment';

import {
    DefaultButton,
    PrimaryButton,
    Separator,
    Stack,
    TimePicker
} from '@fluentui/react';

import { TRANSLATION_KEYS } from './localization/translation-keys';
import { useAuthContext } from '../../../../../../../contexts/auth-context';
import { useMsTeams } from './hooks/useMsTeams';

import { FormProvider, IAIDatePicker, IAITextField } from '../../../../../IAIForm';
import { IAIModal } from '../../../../../IAIModal';
import { useTicketDetails } from '../../../../../../../pages/Ticket/TicketDetailsPage/hooks/useTicketDetails';

interface FormValuesProps {
    subject: string;
    meetingDate: Date;
    startTime: Date;
    endTime: Date;
}

interface IMeetingModal {
    modalVisible?: boolean;
    onModalConfirm: (description?: string) => void;
    onModalDismiss: () => void;
}

const MeetingModal: React.FunctionComponent<IMeetingModal> = ({
    modalVisible,
    onModalConfirm,
    onModalDismiss
}) => {
    const {
        t: translate
    } = useTranslation();

    const {
        retrieveTicket,
    } = useTicketDetails();
    
    //#region Form-Configuration

    const formDataSchema = Yup.object().shape({
        subject: Yup.string().required(translate(TRANSLATION_KEYS.SubjectErrorMessage)),
        meetingDate: Yup.string().required(translate(TRANSLATION_KEYS.MeetingDateErrorMessage)),
        startTime: Yup.string().required(translate(TRANSLATION_KEYS.StartTimeErrorMessage)),
        endTime: Yup.string().required(translate(TRANSLATION_KEYS.EndTimeErrorMessage))
    });

    const defaultValues = useMemo(
        () => ({
            subject: '',
            meetingDate: new Date(),
            startTime: new Date(),
            endTime: new Date()
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const methods = useForm<FormValuesProps>({ resolver: yupResolver(formDataSchema), defaultValues });

    const {
        reset,
        handleSubmit,
        setValue,
        formState: { isSubmitting }
    } = methods;

    useEffect(() => {
        reset(defaultValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //#endregion

    const _handleDismiss = () => {
        reset(defaultValues);
        onModalDismiss();
    }

    const {
        contextType
    } = useAuthContext();

    const {
        handleOnlineMeeting,
        handleOnlineMeetingClient,
        graphOnlineMeetingLoading
    } = useMsTeams();

    const _handleSubmit = (data: FormValuesProps) => {
        const meetingDate = moment(data.meetingDate).format('YYYY-MM-DD');

        let meetingData = {
            subject: data.subject,
            startDateTime: moment(`${meetingDate} ${moment(data.startTime).format('HH:mm')}`)
                .toDate()
                .toISOString(),
            endDateTime: moment(`${meetingDate} ${moment(data.endTime).format('HH:mm')}`)
                .toDate()
                .toISOString(),
            createdBy: retrieveTicket?.requestedBy ?? ""
        };

        const onCreationSuccess = (meeting: any) => {
            if (!meeting || !meeting?.onlineMeeting) {
                return;
            }

            const onlineMeeting = meeting?.onlineMeeting;

            let meetingMessage = '';
            meetingMessage = meetingMessage + `<div>`;
            meetingMessage = meetingMessage + `${translate(TRANSLATION_KEYS.MeetingScheduleIntroText).replace('#PARAM#', meetingData.subject)}`;
            meetingMessage = meetingMessage + `<br />`;
            meetingMessage = meetingMessage + `<b>${meetingDate}</b> ${translate(TRANSLATION_KEYS.MeetingScheduleFromToText).replace("#START#", moment(meetingData.startDateTime).format("HH:mm")).replace("#END#", moment(meetingData.endDateTime).format("HH:mm"))}`;
            meetingMessage = meetingMessage + `</div>`;
            meetingMessage = meetingMessage + `<br />`;
            meetingMessage = meetingMessage + `<div>`;
            meetingMessage = meetingMessage + `${translate(TRANSLATION_KEYS.MeetingSchedulePressLink)}`;
            meetingMessage = meetingMessage + `<br />`;
            meetingMessage = meetingMessage + `<a href="${onlineMeeting?.joinUrl}" target="_blank">${translate(TRANSLATION_KEYS.MeetingScheduleLinkText)}</a>`;
            meetingMessage = meetingMessage + `</div>`;

            onModalConfirm(meetingMessage);

            _handleDismiss();
        }

        switch (contextType) {
            case 'ms-online': {
                handleOnlineMeeting({
                    ...meetingData,
                    onOnlineMeetingCreated: onCreationSuccess
                });
                break;
            }
            case 'ms-teams': {
                handleOnlineMeetingClient({
                    ...meetingData,
                    onOnlineMeetingCreated: onCreationSuccess
                });
                break;
            }

            default: break;
        }
    }

    const disableCondition = graphOnlineMeetingLoading || isSubmitting;

    return (
        <IAIModal
            isOpen={modalVisible}
            heading={translate(TRANSLATION_KEYS.Heading)}
            secondaryHeading={translate(TRANSLATION_KEYS.SecondaryHeading)}
            isBlocking={true}
            onDismiss={_handleDismiss}
        >
            <FormProvider methods={methods}>
                <Stack tokens={{ childrenGap: 15 }}>
                    <div className="row">
                        <div className="col-12 col-md-4">
                            <IAIDatePicker
                                name="meetingDate"
                                label={translate(TRANSLATION_KEYS.MeetingDateLabel)}
                                placeholder={translate(TRANSLATION_KEYS.MeetingDatePlaceholder)}
                                isRequired
                                disabled={disableCondition}
                                styles={{ root: { width: '100%' } }}
                            />
                        </div>
                        <div className="col-12 col-md-4">
                            <TimePicker
                                label={translate(TRANSLATION_KEYS.StartTimeLabel)}
                                placeholder={translate(TRANSLATION_KEYS.StartTimePlaceholder)}
                                allowFreeform
                                timeRange={{ start: 8, end: 19 }}
                                increments={30}
                                disabled={disableCondition}
                                styles={{ root: { width: '100%' },
                                callout: {
                                    position: "absolute",
                                    minHeight: "200px",
                                    height: "250px"
                                }}}
                                onChange={(ev, time) => setValue('startTime', time)}
                                dropdownWidth={100}
                            />
                        </div>
                        <div className="col-12 col-md-4">
                            <TimePicker
                                label={translate(TRANSLATION_KEYS.EndTimeLabel)}
                                placeholder={translate(TRANSLATION_KEYS.EndTimePlaceholder)}
                                allowFreeform
                                timeRange={{ start: 8, end: 19 }}
                                increments={30}
                                disabled={disableCondition}
                                styles={{ root: { width: '100%' }, callout: {
                                    position: "absolute",
                                    minHeight: "200px",
                                    height: "250px"
                                } }}
                                onChange={(ev, time) => setValue('endTime', time)}
                                dropdownWidth={100}
                            />
                        </div>
                    </div>

                    <IAITextField
                        name="subject"
                        label={translate(TRANSLATION_KEYS.SubjectLabel)}
                        placeholder={translate(TRANSLATION_KEYS.SubjectPlaceholder)}
                        required
                        disabled={disableCondition}
                    />

                    <Separator />

                    <Stack horizontal horizontalAlign={'end'} tokens={{ childrenGap: 15 }}>
                        <DefaultButton
                            disabled={disableCondition}
                            iconProps={{ iconName: 'Cancel' }}
                            styles={{ root: { marginRight: 10 } }}
                            onClick={_handleDismiss}
                        >
                            {translate(TRANSLATION_KEYS.DismissText)}
                        </DefaultButton>
                        <PrimaryButton
                            type="submit"
                            disabled={disableCondition}
                            iconProps={{ iconName: 'CheckMark' }}
                            onClick={handleSubmit(_handleSubmit)}
                        >
                            {translate(TRANSLATION_KEYS.ConfirmText)}
                        </PrimaryButton>
                    </Stack>
                </Stack>
            </FormProvider>
        </IAIModal>
    );
}

export default MeetingModal;
import React from 'react';
import { TRANSLATION_KEYS } from '../../../../Localization/translation-keys';

import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { useTranslation } from 'react-i18next';

interface IPropertiesFormFooter {
    disabled?: boolean;
    onDismiss: () => void;
    onSubmit: () => void;
}

const PropertiesFormFooter: React.FunctionComponent<IPropertiesFormFooter> = ({ disabled, onDismiss, onSubmit }) => { 
    
    const {
        t: translate
    } = useTranslation();

    return (
    <div className="properties-form-footer-container">
        <PrimaryButton disabled={disabled} onClick={onSubmit} styles={{ root: { marginRight: 10 } }}>
            {translate(TRANSLATION_KEYS.ConfirmText)}
        </PrimaryButton>

        <DefaultButton disabled={disabled} onClick={onDismiss}>
            {translate(TRANSLATION_KEYS.DismissText)}
        </DefaultButton>
    </div>
    );
}

export default PropertiesFormFooter;
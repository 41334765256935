export const TRANSLATION_KEYS = {
    Heading: "reportPage.heading",
    SecondaryHeading: "reportPage.secondaryHeading",
    WidgetAllHeading: "reportPage.widgetAllHeading",
    WidgetAllTooltip: "reportPage.widgetAllTooltip",
    WidgetOpenHeading: "reportPage.widgetOpenHeading",
    WidgetOpenTooltip: "reportPage.widgetOpenTooltip",
    WidgetPendingHeading: "reportPage.widgetPendingHeading",
    WidgetPendingTooltip: "reportPage.widgetPendingTooltip",
    WidgetWorkingHeading: "reportPage.widgetWorkingHeading",
    WidgetWorkingTooltip: "reportPage.widgetWorkingTooltip",
    WidgetClosedHeading: "reportPage.widgetClosedHeading",
    WidgetClosedTooltip: "reportPage.widgetClosedTooltip",
    ChartOpenLabel: "reportPage.chartOpenLabel",
    ChartPendingLabel: "reportPage.chartPendingLabel",
    ChartWorkingLabel: "reportPage.chartWorkingLabel",
    ChartClosedLabel: "reportPage.chartClosedLabel",
    ApplyText: "common.applyText",
    ClearText: "common.clearText",
    FromDateLabel: "reportPage.reportCriteria.fromDateLabel",
    FromDatePlaceholder: "reportPage.reportCriteria.fromDatePlaceholder",
    ToDateLabel: "reportPage.reportCriteria.toDateLabel",
    ToDatePlaceholder: "reportPage.reportCriteria.toDatePlaceholder",
    DepartmentLabel: "reportPage.reportCriteria.departmentLabel",
    DepartmentPlaceholder: "reportPage.reportCriteria.departmentPlaceholder",
    AgentLabel: "reportPage.reportCriteria.agentLabel",
    AgentPlaceholder: "reportPage.reportCriteria.agentPlaceholder"
};
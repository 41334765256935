import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { useMsal } from "@azure/msal-react";

import { IHookCallback } from '../../../common/models/IHookCallback';
import { GraphApiClient } from '../../../providers/graph-provider';
import { SystemApiClient } from '../../../providers/api-provider';
import { initAccountInfo, initGraphInfo, initGraphPhoto } from '../../../redux/slices/Account/accountSlice';
import { NotificationHelper } from '../../../utils/notification';
import helpers from '../../../utils/helpers/helpers';

export const useLogin = () => {
    const {
        instance
    } = useMsal();

    const {
        t: translate
    } = useTranslation();

    const dispatch = useDispatch();

    interface IRetrieveAccountInfo extends IHookCallback { }

    const [accountInfoLoading, setAccountInfoLoading] = useState<boolean>(false);

    const handleAccountInfo = async (params: IRetrieveAccountInfo) => {
        setAccountInfoLoading(true);

        const clientApi = await SystemApiClient(instance);

        clientApi.getUserProfile()
            .then((response) => {
                dispatch(initAccountInfo({ role: response.data.role, tenantId: response.data.tenantId }));

                params.onSuccess && params.onSuccess();
            })
            .catch((reason) => {
                NotificationHelper.createNotification({
                    type: 'error',
                    title: translate('loginPage.notification.accountInfo.title'),
                    message: translate('loginPage.notification.accountInfo.message')
                });

                params.onError && params.onError();
            })
            .finally(() => {
                setAccountInfoLoading(false);

                params.onAlways && params.onAlways();
            });
    }

    const [graphInfoLoading, setGraphInfoLoading] = useState<boolean>(false);

    const handleGraphInfo = async () => {
        setGraphInfoLoading(true);

        const clientApi = await GraphApiClient(instance);

        clientApi.get('/me?$select=id,displayName,mail,jobTitle,department')
            .then((response) => {
                dispatch(initGraphInfo({
                    id: response.data.id,
                    displayName: response.data.displayName,
                    mail: response.data.mail,
                    jobTitle: response.data.jobTitle,
                    department: response.data.department
                }));
            })
            .catch((reason) => {
                // console.dir(reason);
            })
            .finally(() => {
                setGraphInfoLoading(false);
            });
    }

    const [graphPhotoLoading, setGraphPhotoLoading] = useState<boolean>(false);

    const handleGraphAccountPhoto = async () => {
        setGraphPhotoLoading(true);

        const clientApi = await GraphApiClient(instance);

        clientApi.get('/me/photo/$value', { responseType: 'blob' })
            .then((response) => {
                const url = window.URL || window.webkitURL;

                dispatch(initGraphPhoto(url.createObjectURL(response.data)));
            })
            .catch((reason) => {
                // console.dir(reason);
            })
            .finally(() => {
                setGraphPhotoLoading(false);
            });
    }

    return {
        handleAccountInfo,
        accountInfoLoading,
        handleGraphInfo,
        graphInfoLoading,
        handleGraphAccountPhoto,
        graphPhotoLoading
    }
}
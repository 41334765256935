import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useMsal } from "@azure/msal-react";

import { Group } from '../../../../api-client';
import { SystemApiClient } from '../../../../providers/api-provider';
import { NotificationHelper } from '../../../../utils/notification';
import { useSystem } from '../../../../common/hooks/useSystem';
import { IHookCallback } from '../../../../common/models';
import helpers from '../../../../utils/helpers/helpers';

export const useGroup = () => {
    const {
        instance
    } = useMsal();

    const {
        t: translate
    } = useTranslation();

    const [groups, setGroups] = useState<Group[]>();
    const [groupsLoading, setGroupsLoading] = useState<boolean>(false);

    const handleGroups = async () => {
        setGroupsLoading(true);

        const clientApi = await SystemApiClient(instance);

        clientApi.getGroups()
            .then((response: any) => {
                setGroups(response.data);
            })
            .catch((reason: any) => {
                setGroups([]);

                NotificationHelper.createNotification({
                    type: 'error',
                    title: translate('settingPage.groupPage.notification.retrieveAll.title'),
                    message: translate('settingPage.groupPage.notification.retrieveAll.message')
                });
            })
            .finally(() => {
                setGroupsLoading(false);
            });
    }

    // CRUD Operations

    const {
        handleSystemGroups
    } = useSystem();

    interface ICreateGroup extends IHookCallback {
        group: Group;
    }

    const [createGroupLoading, setCreateGroupLoading] = useState<boolean>(false);

    const handleCreateGroup = async (params: ICreateGroup) => {
        setCreateGroupLoading(true);

        const clientApi = await SystemApiClient(instance);

        clientApi.insertGroup(params.group)
            .then((response: any) => {
                handleSystemGroups();

                params.onSuccess && params.onSuccess();

                NotificationHelper.createNotification({
                    type: 'success',
                    title: translate('settingPage.groupPage.notification.createSuccess.title'),
                    message: translate('settingPage.groupPage.notification.createSuccess.message')
                });
            })
            .catch((reason: any) => {
                params.onError && params.onError();

                NotificationHelper.createNotification({
                    type: 'error',
                    title: translate('settingPage.groupPage.notification.createError.title'),
                    message: translate('settingPage.groupPage.notification.createError.message')
                });
            })
            .finally(() => {
                setCreateGroupLoading(false);

                params.onAlways && params.onAlways();
            });
    }

    interface IDeleteGroup extends IHookCallback {
        id: string;
    }

    const [deleteGroupLoading, setDeleteGroupLoading] = useState<boolean>(false);

    const handleDeleteGroup = async (params: IDeleteGroup) => {
        setDeleteGroupLoading(true);

        const clientApi = await SystemApiClient(instance);

        clientApi.deleteGroup(params.id)
            .then((response: any) => {
                handleSystemGroups();

                params.onSuccess && params.onSuccess();

                NotificationHelper.createNotification({
                    type: 'success',
                    title: translate('settingPage.groupPage.notification.deleteSuccess.title'),
                    message: translate('settingPage.groupPage.notification.deleteSuccess.message')
                });
            })
            .catch((reason: any) => {
                params.onError && params.onError();

                NotificationHelper.createNotification({
                    type: 'error',
                    title: translate('settingPage.groupPage.notification.deleteError.title'),
                    message: translate('settingPage.groupPage.notification.deleteError.message')
                });
            })
            .finally(() => {
                setDeleteGroupLoading(false);

                params.onAlways && params.onAlways();
            });
    }

    return {
        handleGroups,
        groups,
        groupsLoading,
        handleCreateGroup,
        createGroupLoading,
        handleDeleteGroup,
        deleteGroupLoading
    }
} 
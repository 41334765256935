import React from 'react';

import { Shimmer } from '@fluentui/react/lib/Shimmer';

import { IShimmer } from '../../../../models';

import './iai-comment-shimmer.scss';

const IAICommentShimmer: React.FunctionComponent<IShimmer> = ({ style }) => {
    const _handleShimmerElements = (): JSX.Element => (
        <div className={`iai-comment-shimmer-container`} />
    );

    return (
        <Shimmer
            customElementsGroup={_handleShimmerElements()}
            styles={{ shimmerWrapper: { borderRadius: '10px' } }}
        />
    );
}

export default IAICommentShimmer;
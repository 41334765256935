import React, { useEffect } from 'react';
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import { MessageBar, MessageBarType, Separator, Text } from '@fluentui/react';

import { RouterSettings } from '../../../utils/router';
import { DataEnchanter, DateTimeEnchanter } from '../../../utils/data-enchanters';
import { TRANSLATION_KEYS } from './localization/translation-keys';
import { useTicketView } from './hooks/useTicketView';

import { IAIApplicationLayout } from '../../../common/layouts/IAIApplicationLayout';
import { IAIBreadcrumb } from '../../../common/components/IAIBreadcrumb';
import { IAIShimmer } from '../../../common/components/IAIShimmer';
import ViewForm from './components/ViewForm/view-form';
import ViewWebchat from './components/ViewWebchat/view-webchat';

const TicketViewPage: React.FunctionComponent = () => {
    const {
        handleRetrieveTicket,
        retrieveTicket,
        retrieveTicketLoading
    } = useTicketView();

    const routeParams = useParams();

    
    useEffect(() => {
        !!routeParams.ticketId && handleRetrieveTicket({
            id: routeParams.ticketId,
            onRetrieveSuccess: () => { }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [routeParams.ticketId]);
    
    const {
        i18n,
        t: translate
    } = useTranslation();

    return (
        <IAIApplicationLayout
            icon={'Ticket'}
            heading={translate(TRANSLATION_KEYS.Heading)}
            //secondaryHeading={`${translate(TRANSLATION_KEYS.SecondaryHeading)} #${routeParams.ticketId}.`}
            secondaryHeading={`${translate(TRANSLATION_KEYS.SecondaryHeading)} #${retrieveTicket?.readableId ? retrieveTicket.readableId : retrieveTicket?.id}.`}

        >
            <div className="ticket-view-page-container">
                <IAIBreadcrumb
                    items={[
                        {
                            key: RouterSettings.ROUTES_KEYS.IAI_APP_TICKET,
                            text: translate(TRANSLATION_KEYS.BreadcrumbTicketHeading)
                        },
                        {
                            key: RouterSettings.ROUTES_KEYS.IAI_APP_TICKET_VIEW,
                            text: translate(TRANSLATION_KEYS.Heading),
                            isCurrentItem: true
                        }
                    ]}
                />

                <div className="row">
                    <div className="col-7">
                        <IAIShimmer type={'shimmer-heading'} loading={retrieveTicketLoading}>
                            <Text block variant="xLargePlus">
                                {`${translate(TRANSLATION_KEYS.RequestedByLabel)} ${retrieveTicket?.requestedBy}`}
                            </Text>
                            <Text block variant="mediumPlus">
                                {`${translate(TRANSLATION_KEYS.CreatedAtLabel)} ${DateTimeEnchanter.formatCustomDate(retrieveTicket?.createdAt, i18n.language, { dateStyle: 'short', timeStyle: 'short' })}`}
                            </Text>
                        </IAIShimmer>
                    </div>
                    <div className="col-5 text-end">
                        <IAIShimmer type={'shimmer-heading'} loading={retrieveTicketLoading}>
                            <Text block variant="xLargePlus">
                                {`${translate(TRANSLATION_KEYS.StatusLabel)} ${translate(DataEnchanter.textByStatusEnum(retrieveTicket?.status || ''))}`}
                            </Text>

                            {retrieveTicket?.status === 'closed' && (
                                <Text block variant="mediumPlus">
                                    {`${translate(TRANSLATION_KEYS.ClosedAtLabel)} ${DateTimeEnchanter.formatCustomDate(retrieveTicket.closedAt, i18n.language, { dateStyle: 'short', timeStyle: 'short' })}`}
                                </Text>
                            )}
                        </IAIShimmer>
                    </div>

                    <Separator className="my-2" />

                    {retrieveTicket?.status === 'closed' && (
                        <MessageBar
                            messageBarType={MessageBarType.info}
                            styles={{ root: { marginTop: 15, marginBottom: 5 } }}
                        >
                            {translate(TRANSLATION_KEYS.StatusCloseMessage)}
                        </MessageBar>
                    )}

                    <div className="col-12 col-md-7">
                        <ViewForm ticket={retrieveTicket} />
                    </div>
                    <div className="col-12 col-md-5">
                        <ViewWebchat
                            ticketId={routeParams.ticketId}
                            disabled={retrieveTicket?.status === 'closed'}
                        />
                    </div>
                </div>
            </div>
        </IAIApplicationLayout>
    );
}

export default TicketViewPage;
import * as MsTeams from "@microsoft/teams-js";
import { NavigateFunction } from "react-router-dom";
import { RouterSettings } from "../../utils/router";

export const isMsTeamsAuthenticated = async (): Promise<boolean> => {
	try {
		await MsTeams.app.initialize();

		const context = await MsTeams.app.getContext();

		return !!context.user;
	}
	catch
	{
		return false;
	}
}

export const aquireMsTeamsToken = async (): Promise<string> => {
	try {
		await MsTeams.app.initialize();

		return await MsTeams.authentication.getAuthToken({});;
	}
	catch
	{
		return '';
	}
}

export const getContextEntitySubUrl = async (): Promise<string> => {
	try{
		var context = await MsTeams.app.getContext();
		
		var entitySubUrl = context?.page?.subPageId;

		return entitySubUrl ?? "";
	} 
	catch
	{
		return "";
	}
}
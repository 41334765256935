import * as React from 'react';

import { ApplicationSettings } from '../../../utils/application';
import { useClassNames } from './iai-login-layout.classNames';

import './iai-login-layout.scss';

const IAILoginLayout: React.FunctionComponent = (props) => {
    const {
        loginLayoutContainer
    } = useClassNames();

    return (
        <div className={`login-layout-container ${loginLayoutContainer}`}>
            <div className="row h-100 g-0">
                <div className="d-none d-md-block col-md-6 col-xl-8">
                    <div className="layout-bg-container h-100">
                        <video
                            autoPlay
                            loop
                            muted
                            poster={`/application-themes/${ApplicationSettings.IAI_APP_THEME_ROOT}/login-page-background.webp`}
                            preload="none"
                            className="layout-bg-video"
                        >
                            <source
                                src={`/application-themes/${ApplicationSettings.IAI_APP_THEME_ROOT}/login-page-background.webm`}
                                type="video/webm"
                            />
                            <source
                                src={`/application-themes/${ApplicationSettings.IAI_APP_THEME_ROOT}/login-page-background.mp4`}
                                type="video/mp4"
                            />
                        </video>

                        {/* <img
                            alt="Intranet AI - Service Desk"
                            src={`/application-themes/${ApplicationSettings.IAI_APP_THEME_ROOT}/login-page-background.webp`}
                            className="layout-bg-image"
                        /> */}

                        <div className="layout-bg-overlay" />
                    </div>
                </div>
                <div className="col-12 col-md-6 col-xl-4">
                    <div className="layout-child-container h-100">
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default IAILoginLayout;
import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import _ from 'lodash';

import { MsalProvider } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";

// Interfaces > Utils > Hooks > ClassNames
import { AuthProvider } from './contexts/auth-context';
import { IRouteSettings, RouterSettings } from './utils/router';
import { usePersistReduxStore } from './common/hooks/usePersistReduxStore';

import { withAuthentication } from './wrappers/withAuthentication';
import { ApplicationTemplate } from './common/templates/ApplicationTemplate';

import { IAIRoleGuard } from './common/guards/IAIRoleGuard';
import { LoginPage } from './pages/Login';
import { Homepage } from './pages/Homepage';
import { TicketPage } from './pages/Ticket/TicketPage';
import { TicketDetailsPage } from './pages/Ticket/TicketDetailsPage';
import { TicketViewPage } from './pages/Ticket/TicketViewPage';
import { SolutionPage } from './pages/Solution';
import { ReportPage } from './pages/Report';
import { SettingPage } from './pages/Setting/SettingPage';
import { GroupsPage } from './pages/Setting/SettingGroupsPage';
import { SettingAreaPage } from './pages/Setting/SettingAreaPage';
import { Properties } from './pages/Setting/SettingPropertyPage';
import { SettingNoticePage } from './pages/Setting/SettingNoticePage';
import { SettingTopicPage } from './pages/Setting/SettingTopicPage';

type IApp = {
	msalInstance: IPublicClientApplication
}

const App: React.FunctionComponent<IApp> = ({ msalInstance }) => {
	const {
		handlePersistingStore
	} = usePersistReduxStore();

	const _handleRouteComponent: any = (routeSettings: IRouteSettings) => {
		switch (routeSettings.routeKey) {
			case RouterSettings.ROUTES_KEYS.IAI_APP_LOGIN: {
				return LoginPage;
			}
			case RouterSettings.ROUTES_KEYS.IAI_APP_DASHBOARD: {
				return routeSettings.needAuthentication
					? withAuthentication(Homepage)
					: Homepage;
			}
			case RouterSettings.ROUTES_KEYS.IAI_APP_TICKET: {
				return routeSettings.needAuthentication
					? withAuthentication(TicketPage)
					: TicketPage;
			}
			case RouterSettings.ROUTES_KEYS.IAI_APP_TICKET_TYPE: {
				return routeSettings.needAuthentication
					? withAuthentication(TicketPage)
					: TicketPage;
			}
			case RouterSettings.ROUTES_KEYS.IAI_APP_TICKET_DETAILS: {
				return routeSettings.needAuthentication
					? withAuthentication(TicketDetailsPage)
					: TicketDetailsPage;
			}
			case RouterSettings.ROUTES_KEYS.IAI_APP_TICKET_VIEW: {
				return routeSettings.needAuthentication
					? withAuthentication(TicketViewPage)
					: TicketViewPage;
			}
			case RouterSettings.ROUTES_KEYS.IAI_APP_SOLUTION: {
				return routeSettings.needAuthentication
					? withAuthentication(SolutionPage)
					: SolutionPage;
			}
			case RouterSettings.ROUTES_KEYS.IAI_APP_REPORT: {
				return routeSettings.needAuthentication
					? withAuthentication(ReportPage)
					: ReportPage;
			}
			case RouterSettings.ROUTES_KEYS.IAI_APP_SETTING: {
				return routeSettings.needAuthentication
					? withAuthentication(SettingPage)
					: SettingPage;
			}
			case RouterSettings.ROUTES_KEYS.IAI_APP_SETTING_AREA: {
				return routeSettings.needAuthentication
					? withAuthentication(SettingAreaPage)
					: SettingAreaPage;
			}
			case RouterSettings.ROUTES_KEYS.IAI_APP_SETTING_GROUP: {
				return routeSettings.needAuthentication
					? withAuthentication(GroupsPage)
					: GroupsPage;
			}
			case RouterSettings.ROUTES_KEYS.IAI_APP_SETTING_CUSTOM_PROPERTY: {
				return routeSettings.needAuthentication
					? withAuthentication(Properties)
					: Properties;
			}
			case RouterSettings.ROUTES_KEYS.IAI_APP_SETTING_NOTICE: {
				return routeSettings.needAuthentication
					? withAuthentication(SettingNoticePage)
					: SettingNoticePage;
			}
			case RouterSettings.ROUTES_KEYS.IAI_APP_SETTING_TOPIC: {
				return routeSettings.needAuthentication
					? withAuthentication(SettingTopicPage)
					: SettingTopicPage;
			}

			default: {
				return null;
			}
		}
	}

	useEffect(() => {
		handlePersistingStore();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<MsalProvider instance={msalInstance}>
			<AuthProvider>
				<ApplicationTemplate>
					<Routes>
						{_.map(RouterSettings.ROUTES, (entryData, entryIndex) => {
							const RouteComponent = _handleRouteComponent(entryData);

							return (
								<Route
									key={entryIndex}
									path={entryData.path}
									element={
										<IAIRoleGuard roles={entryData.roles || []}>
											<RouteComponent />
										</IAIRoleGuard>
									}
								/>
							)
						})}
					</Routes>
				</ApplicationTemplate>
			</AuthProvider>
		</MsalProvider>
	);
}

export default App;
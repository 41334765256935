export const TRANSLATION_KEYS = {
    Heading: "settingPage.topicPage.formDrawer.heading",
    ConfirmText: "common.saveText",
    DismissText: "common.dismissText",
    TitleLabel: "settingPage.topicPage.formDrawer.titleLabel",
    TitlePlaceholder: "settingPage.topicPage.formDrawer.titlePlaceholder",
    TitleErrorMessage: "settingPage.topicPage.formDrawer.titleErrorMessage",
    GroupLabel: "settingPage.topicPage.formDrawer.groupLabel",
    GroupPlaceholder: "settingPage.topicPage.formDrawer.groupPlaceholder",
    GroupErrorMessage: "settingPage.topicPage.formDrawer.groupErrorMessage"
};
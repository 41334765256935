import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import _ from 'lodash';

import {
    DatePicker,
    DefaultButton,
    ComboBox,
    PrimaryButton,
    Separator,
    Stack
} from '@fluentui/react';
import { Pie } from 'react-chartjs-2';
import {
    ArcElement,
    BarElement,
    Chart as ChartJS,
    Legend,
    Title,
    Tooltip
} from 'chart.js';

import { RootState } from '../../redux/application/redux-store';
import { initCriteria, clearCriteria } from '../../redux/slices/Ticket/ticketSlice';
import { TicketFilteringCriteria, TicketStatusEnum } from '../../api-client';
import { TRANSLATION_KEYS } from './localization/translation-keys';
import { datePickerStrings } from '../../utils/constants';
import { DateTimeEnchanter } from '../../utils/data-enchanters';
import { useReport } from './hooks/useReport';
import { useClassNames } from './report-page.classNames';

import { IAIApplicationLayout } from '../../common/layouts/IAIApplicationLayout';
import { IAIShimmer } from '../../common/components/IAIShimmer';
import { IAIWidget } from '../../common/components/IAIWidget';

ChartJS.register(BarElement, ArcElement, Title, Tooltip, Legend);

const ReportPage: React.FunctionComponent = () => {
    const {
        handleTicketReports,
        ticketReports,
        ticketReportsLoading
    } = useReport();

    const {
        reportPageContainer
    } = useClassNames();

    //#region Report-Criteria

    const [reportsCriteria, setReportsCriteria] = useState<TicketFilteringCriteria>();

    const _handleChangeReportsCriteria = (name: string, value: any) => setReportsCriteria({
        ...reportsCriteria,
        [name]: value
    });

    const dispatch = useDispatch();

    const _handleClearReportsCriteria = () => {
        setReportsCriteria(undefined);

        dispatch(clearCriteria());

        handleTicketReports({});
    }

    const _handleTicketReports = () => {
        if (reportsCriteria) {
            dispatch(initCriteria(reportsCriteria));
        }

        handleTicketReports({ criteria: reportsCriteria });
    }

    //#endregion

    useEffect(() => {
        _handleTicketReports();
    }, []);

    const {
        t: translate,
        i18n
    } = useTranslation();

    const REPORT_WIDGETS = [
        {
            description: `${ticketReports?.totalCount}`,
            title: translate(TRANSLATION_KEYS.WidgetAllHeading),
            tooltip: translate(TRANSLATION_KEYS.WidgetAllTooltip),
            to: '/ticket?status=all'
        },
        {
            description: `${ticketReports?.openCount}`,
            title: translate(TRANSLATION_KEYS.WidgetOpenHeading),
            tooltip: translate(TRANSLATION_KEYS.WidgetOpenTooltip),
            to: `/ticket?status=${TicketStatusEnum.Open}`
        },
        {
            description: `${ticketReports?.pendingCount}`,
            title: translate(TRANSLATION_KEYS.WidgetPendingHeading),
            tooltip: translate(TRANSLATION_KEYS.WidgetPendingTooltip),
            to: `/ticket?status=${TicketStatusEnum.Pending}`
        },
        {
            description: `${ticketReports?.workingCount}`,
            title: translate(TRANSLATION_KEYS.WidgetWorkingHeading),
            tooltip: translate(TRANSLATION_KEYS.WidgetWorkingTooltip),
            to: `/ticket?status=${TicketStatusEnum.Working}`
        },
        {
            description: `${ticketReports?.closedCount}`,
            title: translate(TRANSLATION_KEYS.WidgetClosedHeading),
            tooltip: translate(TRANSLATION_KEYS.WidgetClosedTooltip),
            to: `/ticket?status=${TicketStatusEnum.Closed}`
        }
    ];

    const PIE_CHART_DATASET = {
        labels: [
            translate(TRANSLATION_KEYS.ChartOpenLabel),
            translate(TRANSLATION_KEYS.ChartPendingLabel),
            translate(TRANSLATION_KEYS.ChartWorkingLabel),
            translate(TRANSLATION_KEYS.ChartClosedLabel)
        ],
        datasets: [
            {
                data: [
                    Math.round(ticketReports?.openCount || 0),
                    Math.round(ticketReports?.pendingCount || 0),
                    Math.round(ticketReports?.workingCount || 0),
                    Math.round(ticketReports?.closedCount || 0)
                ],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1
            }
        ]
    };

    const iaiSystem = useSelector((state: RootState) => state.system);

    const reportDataLoading = ticketReportsLoading ||
        !iaiSystem.departmentsInitialized ||
        !iaiSystem.agentsInitialized;

    return (
        <IAIApplicationLayout
            icon={'LineChart'}
            heading={translate(TRANSLATION_KEYS.Heading)}
            secondaryHeading={translate(TRANSLATION_KEYS.SecondaryHeading)}
        >
            <div className={`report-page-container ${reportPageContainer}`}>
                <div className="row">
                    <div className={'col-2'}>
                        <DatePicker
                            label={translate(TRANSLATION_KEYS.FromDateLabel)}
                            placeholder={translate(TRANSLATION_KEYS.FromDatePlaceholder)}
                            strings={datePickerStrings(i18n.language)}
                            formatDate={(date) => DateTimeEnchanter.formatStandardDate(date, i18n.language)}
                            disabled={reportDataLoading}
                            /**/
                            value={reportsCriteria?.fromDate ? new Date(reportsCriteria?.fromDate) : undefined}
                            onSelectDate={(value) => (
                                _handleChangeReportsCriteria('fromDate', value ? moment(value).endOf('day').toDate().toISOString() : undefined)
                            )}
                        />
                    </div>
                    <div className={'col-2'}>
                        <DatePicker
                            label={translate(TRANSLATION_KEYS.ToDateLabel)}
                            placeholder={translate(TRANSLATION_KEYS.ToDatePlaceholder)}
                            strings={datePickerStrings(i18n.language)}
                            formatDate={(date) => DateTimeEnchanter.formatStandardDate(date, i18n.language)}
                            disabled={reportDataLoading}
                            /**/
                            value={reportsCriteria?.toDate ? new Date(reportsCriteria?.toDate) : undefined}
                            onSelectDate={(value) => (
                                _handleChangeReportsCriteria('toDate', value ? moment(value).endOf('day').toDate().toISOString() : undefined)
                            )}
                        />
                    </div>
                    <div className={'col-2'}>
                        <ComboBox
                            label={translate(TRANSLATION_KEYS.DepartmentLabel)}
                            placeholder={translate(TRANSLATION_KEYS.DepartmentPlaceholder)}
                            allowFreeform={true}
                            autoComplete={'on'}
                            disabled={reportDataLoading}
                            /**/
                            options={_.map(iaiSystem.departments, entry => ({ key: `${entry.key}`, text: `${entry.text}` }))}
                            selectedKey={reportsCriteria?.departmentId || null}
                            onChange={(_, option) => _handleChangeReportsCriteria('departmentId', option?.key)}
                        />
                    </div>
                    <div className={'col-2'}>
                        <ComboBox
                            label={translate(TRANSLATION_KEYS.AgentLabel)}
                            placeholder={translate(TRANSLATION_KEYS.AgentPlaceholder)}
                            allowFreeform={true}
                            autoComplete={'on'}
                            disabled={reportDataLoading}
                            /**/
                            options={_.map(iaiSystem.agents, entry => ({ key: `${entry.key}`, text: `${entry.text}` }))}
                            selectedKey={reportsCriteria?.agentId || null}
                            onChange={(_, option) => _handleChangeReportsCriteria('agentId', option?.key)}
                        />
                    </div>
                    <div className={'col-4'} style={{ paddingTop: 30 }}>
                        <DefaultButton
                            iconProps={{ iconName: 'Cancel' }}
                            disabled={reportDataLoading || !reportsCriteria}
                            onClick={_handleClearReportsCriteria}
                            styles={{ root: { marginRight: 10 } }}
                        >
                            {translate(TRANSLATION_KEYS.ClearText)}
                        </DefaultButton>
                        <PrimaryButton
                            iconProps={{ iconName: 'CheckMark' }}
                            disabled={reportDataLoading}
                            onClick={_handleTicketReports}
                        >
                            {translate(TRANSLATION_KEYS.ApplyText)}
                        </PrimaryButton>
                    </div>
                </div>

                <Separator className="my-3" />

                <Stack
                    horizontal
                    horizontalAlign="center"
                    tokens={{ childrenGap: 30 }}
                    wrap
                >
                    {_.map(REPORT_WIDGETS, (dataItem, dataIndex) => (
                        <IAIShimmer
                            key={dataIndex}
                            type={'shimmer-widget'}
                            loading={!ticketReports || ticketReportsLoading}
                        >
                            <IAIWidget {...dataItem} />
                        </IAIShimmer>
                    ))}
                </Stack>

                <Separator className="my-3" />

                <Stack
                    horizontal
                    horizontalAlign="center"
                >
                    <Pie data={PIE_CHART_DATASET} style={{ maxHeight: '400px' }} />
                </Stack>
            </div>
        </IAIApplicationLayout>
    );
}

export default ReportPage;
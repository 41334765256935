export const ParameterName = {
    GENERICTICKET: "genericTicket",
    COMMENT: "comment",
    MODE: "mode",
    AREA: "area",
    DEPARTMENT: "department",
    AGENT: "agent",
    STATUS: "status",
    PRIORITY: "priority",
    NOTE: "note",
};

export const ModeEnumTranslations: any = {
    Level01Enum: "common.ticketMode.modeOptionLevel01",
    Level02Enum: "common.ticketMode.modeOptionLevel02",
    Level03Enum: "common.ticketMode.modeOptionLevel03"
};

export const StatusEnumTranslations: any = {
    OpenEnum: "common.ticketStatus.statusOptionOpen",
    PendingEnum: "common.ticketStatus.statusOptionPending",
    WorkingEnum: "common.ticketStatus.statusOptionWorking",
    ClosedEnum: "common.ticketStatus.statusOptionClosed"
};

export const PriorityEnumTranslations: any = {
    LowEnum: "common.ticketPriority.priorityOptionLow",
    MediumEnum: "common.ticketPriority.priorityOptionMedium",
    HighEnum: "common.ticketPriority.priorityOptionHigh"
};
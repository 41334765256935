import React from 'react';

import { Shimmer, ShimmerElementsGroup, ShimmerElementType } from '@fluentui/react/lib/Shimmer';

import { IShimmer } from '../../../../models';

const IAIHedingShimmer: React.FunctionComponent<IShimmer> = ({ style }) => {
    const _handleShimmerElements = (): JSX.Element => (
        <div>
            <ShimmerElementsGroup shimmerElements={[{ type: ShimmerElementType.line, height: 16 }]} />
            <ShimmerElementsGroup shimmerElements={[{ type: ShimmerElementType.gap, height: 7, width: '100%' }]} />
            <ShimmerElementsGroup shimmerElements={[{ type: ShimmerElementType.line, height: 16 }]} />
            <ShimmerElementsGroup shimmerElements={[{ type: ShimmerElementType.gap, height: 7, width: '100%' }]} />
            <ShimmerElementsGroup shimmerElements={[{ type: ShimmerElementType.line, height: 16 }]} />
        </div>
    );

    return (
        <Shimmer customElementsGroup={_handleShimmerElements()} />
    );
}

export default IAIHedingShimmer;
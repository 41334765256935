export class LocalStorageHelper {
    public static STORAGE_KEYS = {
        IAI_THEME: 'IAI_THEME'
    };

    public static createItem = (key: string, value: any) => (
        localStorage.setItem(key, JSON.stringify(value))
    );

    public static retrieveItem = (key: string) => {
        const storedItem = localStorage.getItem(key);

        if (!storedItem) {
            return null;
        };

        return JSON.parse(storedItem);
    };

    public static deleteItem = (key: string) => (
        localStorage.removeItem(key)
    );
}
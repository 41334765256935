import { getTheme } from '@fluentui/react';
import { mergeStyleSets } from "@fluentui/react/lib/Styling";

interface IIAIWebchatClassNames {
    webchatContainer: string;
    webchatFormContainer: string;
}

export const useClassNames = (): IIAIWebchatClassNames => {
    const applicationTheme = getTheme();

    return mergeStyleSets({
        webchatContainer: {
            background: applicationTheme.palette.themeLighterAlt
        },
        webchatFormContainer: {
            background: applicationTheme.palette.themeLighterAlt
        }
    });
}
import { useTranslation } from 'react-i18next';
import { IPropertiesForm } from '../hooks/usePropertiesForm';
import { TRANSLATION_KEYS } from '../../../Localization/translation-keys';

export interface IPropertiesFormValidator {
    displayName?: string;
    values?: string;
}

export class PropertiesFormValidator {
    public static validateForm = (values: IPropertiesForm, translate: any): IPropertiesFormValidator => {
        let formErrors: IPropertiesFormValidator = {};

        if (!values.displayName || !values.displayName.toString()) {
            formErrors.displayName = translate(TRANSLATION_KEYS.validateFormName);
        }

        if (!values.values || !values.values.toString()) {
            formErrors.values = translate(TRANSLATION_KEYS.validateFormValues);
        }

        return formErrors;
    }
}
import { Configuration, PopupRequest, IPublicClientApplication } from "@azure/msal-browser";
import { ApplicationSettings } from '../../utils/application';

// Config object to be passed to Msal on creation
export const _msalConfig: Configuration = {
    auth: {
        authority: ApplicationSettings.IAI_APP_AUTHORITY,
        clientId: ApplicationSettings.IAI_APP_CLIENTID,
        navigateToLoginRequestUrl: false,
        postLogoutRedirectUri: window.location.origin,
        redirectUri: window.location.origin + '/login'
    }
};

/// Controlla e rimuove "CUSTOM:" se presente all'inizio
const resourceName = (typeof ApplicationSettings.IAI_APP_RESOURCE_NAME === 'string' &&
    ApplicationSettings.IAI_APP_RESOURCE_NAME.startsWith('CUSTOM:'))
    ? ApplicationSettings.IAI_APP_RESOURCE_NAME.slice(7)
    : ApplicationSettings.IAI_APP_RESOURCE_NAME;

// Determina lo scope corretto
const scope = (typeof ApplicationSettings.IAI_APP_RESOURCE_NAME === 'string' &&
    ApplicationSettings.IAI_APP_RESOURCE_NAME.startsWith('CUSTOM:'))
    ? `${resourceName}`
    : `https://${resourceName}/${ApplicationSettings.IAI_APP_CLIENTID}/user_impersonation`;

export const _loginRequest: PopupRequest = {
    scopes: [
        //"api://phnutServDesk/user_impersonation"
        scope // Imposta lo scope corretto in base alla presenza di "CUSTOM:"
    ]
};

/*
// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const _loginRequest: PopupRequest = {
    scopes: [
        //`https://${ApplicationSettings.IAI_APP_RESOURCE_NAME}/${ApplicationSettings.IAI_APP_CLIENTID}/user_impersonation` // IAI
    ]
};
*/


export const aquireToken = async (instance: IPublicClientApplication) => {
    const account: any = instance.getActiveAccount();
    if (!account) {
        // throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        return null;
    }

    return await instance.acquireTokenSilent({
        ..._loginRequest,
        account: account
    });
}

export const _loginGraphRequest: PopupRequest = {
    scopes: [
        "openid",
        "profile",
        "User.Read"
    ]
};

export const aquireGraphToken = async (instance: IPublicClientApplication) => {
    const account: any = instance.getActiveAccount();
    if (!account) {
        // throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        return null;
    }

    return await instance.acquireTokenSilent({
        ..._loginGraphRequest,
        account: account
    });
}
export const TRANSLATION_KEYS = {
    Heading: "solutionPage.heading",
    SecondaryHeading: "solutionPage.secondaryHeading",
    ColumnPreviewTooltip: "solutionPage.columnPreviewTooltip",
    ColumnTitleLabel: "solutionPage.columnTitleLabel",
    ColumnDepartmentLabel: "solutionPage.columnDepartmentLabel",
    ColumnCreatedAtLabel: "solutionPage.columnCreatedAtLabel",
    ColumnActionCommandUpdateLabel: "solutionPage.columnActionCommandUpdateLabel",
    ColumnActionCommandUpdateTooltip: "solutionPage.columnActionCommandUpdateTooltip",
    ColumnActionCommandDeleteLabel: "solutionPage.columnActionCommandDeleteLabel",
    ColumnActionCommandDeleteTooltip: "solutionPage.columnActionCommandDeleteTooltip",
    CommandCreateLabel: "solutionPage.commandCreateLabel",
    CommandCreateTooltip: "solutionPage.commandCreateTooltip",
    DetailsListEmptyContentLabel: "solutionPage.detailsListEmptyContentLabel"
};
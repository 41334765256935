export const TRANSLATION_KEYS = {
    ConfirmText: "common.saveText",
    DismissText: "common.dismissText",
    DeleteText: "common.deleteText",
    addProperty: "settingPropertyPage.addProperty",
    editProperty: "settingPropertyPage.editProperty",
    propertyName: "settingPropertyPage.propertyName",
    propertyNamePlaceholder: "settingPropertyPage.propertyNamePlaceholder",
    propertyValuePlaceholder: "settingPropertyPage.propertyValuePlaceholder",
    propertyValues: "settingPropertyPage.propertyValues",
    propertyValue: "settingPropertyPage.propertyValue",
    propertyValueDescription: "settingPropertyPage.propertyValueDescription",
    validateFormName: "settingPropertyPage.validateFormName",
    validateFormValues: "settingPropertyPage.validateFormValues",
    heading: "settingPropertyPage.heading",
    headingDescription: "settingPropertyPage.headingDescription",
    tooltipContentTitle: "settingPropertyPage.tooltipContentTitle",
    tooltipContent: "settingPropertyPage.tooltipContent",
    tableName: "settingPropertyPage.tableName",
    tableEditActions: "settingPropertyPage.tableEditActions",
    tableEditEdit: "settingPropertyPage.tableEditEdit",
    tableEditDelete: "settingPropertyPage.tableEditDelete",
    newProperty: "settingPropertyPage.newProperty",
    deleteProperty: "settingPropertyPage.deleteProperty",
    deletePropertyMsg: "settingPropertyPage.deletePropertyMsg",
    proceedDelete: "settingPropertyPage.proceedDelete"
};
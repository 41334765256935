import React from 'react';
import _ from 'lodash';

import { Stack } from '@fluentui/react';

import { ApplicationSettings } from '../../../../../utils/application';
import { IRouteSettings, RouterSettings } from '../../../../../utils/router';
import { useAuthContext } from '../../../../../contexts/auth-context';
import { useClassNames } from './iai-sidenav.classNames';

import IAIAnnouncementItem from './components/IAIAnnouncementItem/iai-announcement-item';
import IAILogoutItem from './components/IAILogoutItem/iai-logout-item';
import IAISidenavItem from './components/IAISidenavItem/iai-sidenav-item';

const IAISidenav: React.FunctionComponent = () => {
    const {
        isAuthenticated
    } = useAuthContext();

    const {
        stackContainer
    } = useClassNames();

    // let isAuthenticated = useIsAuthenticated();

    let navigationItems = _.filter(RouterSettings.ROUTES, entry => entry.sidenavRender === true);

    return (
        <Stack className={stackContainer} tokens={{ childrenGap: 15, padding: 15 }}>
            <img
                src={`/application-themes/${ApplicationSettings.IAI_APP_THEME_ROOT}/app-logo-small.webp`}
                style={{ width: 55 }}
            />

            {isAuthenticated && _.map(navigationItems, (routeData: IRouteSettings, routeKey) => (
                <React.Fragment key={`${routeKey}__${routeData.routeKey}`}>
                    {routeKey === navigationItems.length - 1 &&
                        <React.Fragment>
                            <IAIAnnouncementItem />

                            <IAILogoutItem />
                        </React.Fragment>
                    }

                    <IAISidenavItem routeItem={routeData} />
                </React.Fragment>
            ))}
        </Stack>
    );
}

export default IAISidenav;
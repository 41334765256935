import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useBoolean } from '@fluentui/react-hooks';

import { RootState } from '../../../../../redux/application/redux-store';
import { TicketComment } from '../../../../../api-client';
import { RouterSettings } from '../../../../../utils/router';
import { DataEnchanter } from '../../../../../utils/data-enchanters';
import { useWebchat } from './hooks/useWebchat';

import { IAIWebchat } from '../../../../../common/components/IAIWebchat';
import MessageDialog from './components/MessageDialog/message-dialog';

interface IWebchat {
    ticketId?: string;
}

const Webchat: React.FunctionComponent<IWebchat> = ({ ticketId }) => {
    const {
        handleCreateTicketComment,
        createTicketCommentLoading,
        handleRetrieveTicketComments,
        ticketComments,
        ticketCommentsLoading
    } = useWebchat();

    useEffect(() => {
        !!ticketId && handleRetrieveTicketComments({ id: ticketId });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ticketId]);

    const iaiAccount = useSelector((state: RootState) => state.account);

    const [messageModalVisible, { toggle: toggleMessageModalVisible }] = useBoolean(false);

    const _handleSubmit = (comment: TicketComment) => {
        !!ticketId && handleCreateTicketComment({
            id: ticketId,
            ticketComment: {
                ...comment,
                userInfo: { id: DataEnchanter.uuid(), displayName: iaiAccount.displayName }
            },
            onSuccess: () => {
                handleRetrieveTicketComments({ id: ticketId });
                toggleMessageModalVisible();
            }
        });
    }

    const navigate = useNavigate();

    return (
        <React.Fragment>
            <IAIWebchat
                dataSource={ticketComments}
                loading={ticketCommentsLoading || createTicketCommentLoading}
                isAdmin
                onSendMessage={_handleSubmit}
            />

            <MessageDialog
                disabled={ticketCommentsLoading || createTicketCommentLoading}
                modalVisible={messageModalVisible}
                onModalConfirm={() => navigate(RouterSettings.PATHS.dashboard.ticket.root)}
                onModalDismiss={toggleMessageModalVisible}
            />
        </React.Fragment>
    );
}

export default Webchat;
export const TRANSLATION_KEYS = {
    Heading: "settingPage.heading",
    SecondaryHeading: "settingPage.secondaryHeading",
    WidgetSectionHeading: "settingPage.widgetSectionHeading",
    WidgetAreaHeading: "settingPage.widgetAreaHeading",
    WidgetAreaTooltip: "settingPage.widgetAreaTooltip",
    WidgetGroupsHeading: "settingPage.widgetGroupsHeading",
    WidgetGroupsTooltip: "settingPage.widgetGroupsTooltip",
    WidgetCustomPropertyHeading: "settingPage.widgetCustomPropertyHeading",
    WidgetCustomPropertyTooltip: "settingPage.widgetCustomPropertyTooltip",
    WidgetNoticeHeading: "settingPage.widgetNoticeHeading",
    WidgetNoticeTooltip: "settingPage.widgetNoticeTooltip",
    WidgetReceiverHeading: "settingPage.widgetReceiverHeading",
    WidgetReceiverTooltip: "settingPage.widgetReceiverTooltip",
    WidgetSettingHeading: "settingPage.widgetSettingHeading",
    WidgetSettingTooltip: "settingPage.widgetSettingTooltip",
    WidgetTopicsHeading: "settingPage.widgetTopicsHeading",
    WidgetTopicsTooltip: "settingPage.widgetTopicsTooltip",
};
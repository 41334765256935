import { useSelector } from 'react-redux';

import { getTheme, mergeStyleSets } from '@fluentui/react';

import { RootState } from '../../../../../../../redux/application/redux-store';

interface IIAIAnnouncementItemClassNames {
    iaiSidenavItem: string;
    calloutContainer: string;
    calloutBeakCurtain: string;
}

export const useClassNames = (): IIAIAnnouncementItemClassNames => {
    const applicationTheme = getTheme();

    const iaiSystem = useSelector((state: RootState) => state.system);

    return mergeStyleSets({
        iaiSidenavItem: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: applicationTheme.palette.neutralLight,
            borderRadius: 8,
            color: applicationTheme.semanticColors.inputText,
            cursor: 'pointer',
            fontSize: '1.35rem',
            width: 55,
            height: 55,
            textDecoration: 'none',
            transition: 'all 0.3s cubic-bezier(0.75, 0, 0.175, 1)',
            userSelect: 'none',
            position: 'relative',
            selector: {
                '&:hover': {
                    background: iaiSystem.theme === 'light'
                        ? applicationTheme.palette.themeLighter
                        : applicationTheme.palette.themeTertiary,
                    color: iaiSystem.theme === 'light'
                        ? applicationTheme.palette.themePrimary
                        : applicationTheme.semanticColors.inputText
                },
                '&.isActive': {
                    background: iaiSystem.theme === 'light'
                        ? applicationTheme.palette.themeLighter
                        : applicationTheme.palette.themeTertiary,
                    color: iaiSystem.theme === 'light'
                        ? applicationTheme.palette.themePrimary
                        : applicationTheme.semanticColors.inputText
                }
            }
        },
        calloutContainer: {
            padding: 20,
            width: 500,
            maxWidth: '90%'
        },
        calloutBeakCurtain: {
            background: iaiSystem.theme === 'light'
                ? applicationTheme.palette.themeLighter
                : applicationTheme.palette.themeTertiary
        }
    });
}
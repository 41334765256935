export const TRANSLATION_KEYS = {
    Heading: "ticketDetailsPage.detailsForm.changeLogsAccordion.heading",
    ModeOptionLevel01: "common.ticketMode.modeOptionLevel01",
    ModeOptionLevel02: "common.ticketMode.modeOptionLevel02",
    ModeOptionLevel03: "common.ticketMode.modeOptionLevel03",
    StatusOptionOpen: "common.ticketStatus.statusOptionOpen",
    StatusOptionWorking: "common.ticketStatus.statusOptionWorking",
    StatusOptionPending: "common.ticketStatus.statusOptionPending",
    StatusOptionClosed: "common.ticketStatus.statusOptionClosed",
    PriorityOptionLow: "common.ticketPriority.priorityOptionLow",
    PriorityOptionMedium: "common.ticketPriority.priorityOptionMedium",
    PriorityOptionHigh: "common.ticketPriority.priorityOptionHigh",
    ChangeLogsEmpty: "ticketDetailsPage.detailsForm.changeLogsAccordion.changeLogsEmpty"
};
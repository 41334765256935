export const TRANSLATION_KEYS = {
    Heading: "settingPage.noticePage.formDrawer.heading",
    ConfirmText: "common.saveText",
    DismissText: "common.dismissText",
    TitleLabel: "settingPage.noticePage.formDrawer.titleLabel",
    TitlePlaceholder: "settingPage.noticePage.formDrawer.titlePlaceholder",
    TitleErrorMessage: "settingPage.noticePage.formDrawer.titleErrorMessage",
    DescriptionLabel: "settingPage.noticePage.formDrawer.descriptionLabel",
    DescriptionPlaceholder: "settingPage.noticePage.formDrawer.descriptionPlaceholder",
    DescriptionErrorMessage: "settingPage.noticePage.formDrawer.descriptionErrorMessage",
    AvailableFromLabel: "settingPage.noticePage.formDrawer.availableFromLabel",
    AvailableFromPlaceholder: "settingPage.noticePage.formDrawer.availableFromPlaceholder",
    AvailableFromErrorMessage: "settingPage.noticePage.formDrawer.availableFromErrorMessage",
    AvailableToLabel: "settingPage.noticePage.formDrawer.availableToLabel",
    AvailableToPlaceholder: "settingPage.noticePage.formDrawer.availableToPlaceholder",
    AvailableToErrorMessage: "settingPage.noticePage.formDrawer.availableToErrorMessage"
};
import { useState } from 'react';
import { saveAs } from 'file-saver';

import { useMsal } from "@azure/msal-react";

import { TicketAttachment, TicketAttachmentFile } from '../../../../../../../../api-client';
import { IHookCallback } from '../../../../../../../../common/models/IHookCallback';
import { TicketApiClient } from '../../../../../../../../providers/api-provider';
import { NotificationHelper } from '../../../../../../../../utils/notification';
import { useTranslation } from 'react-i18next';
import helpers from '../../../../../../../../utils/helpers/helpers';

export const useTicketAttachment = () => {
    const {
        instance
    } = useMsal();

    const {
        t: translate
    } = useTranslation();

    interface IRetrieveTicketAttachments extends IHookCallback {
        id: string;
    }

    const [ticketAttachments, setTicketAttachments] = useState<TicketAttachment[]>();
    const [ticketAttachmentsLoading, setTicketAttachmentsLoading] = useState<boolean>(false);

    const handleRetrieveTicketAttachments = async (params: IRetrieveTicketAttachments) => {
        setTicketAttachmentsLoading(true);

        const clientApi = await TicketApiClient(instance, helpers.getStoredI18Lang());

        clientApi.getTicketAttachments(params.id)
            .then((response) => {
                setTicketAttachments(response.data);

                params.onSuccess && params.onSuccess();
            })
            .catch((reason) => {
                setTicketAttachments([]);

                params.onError && params.onError();

                NotificationHelper.createNotification({
                    type: 'error',
                    message: translate('system.notification.getTicketAttachments.title'),
                    title: translate('system.notification.getTicketAttachments.message')
                });
            })
            .finally(() => {
                setTicketAttachmentsLoading(false);

                params.onAlways && params.onAlways();
            });
    }

    interface IRetrieveTicketAttachment extends IHookCallback {
        id: string;
        attachmentId: string;
        fileName: string;
    }

    const [ticketAttachmentLoading, setTicketAttachmentLoading] = useState<boolean>(false);

    const handleRetrieveTicketAttachment = async (params: IRetrieveTicketAttachment) => {
        setTicketAttachmentLoading(true);

        const clientApi = await TicketApiClient(instance, helpers.getStoredI18Lang());

        clientApi.getTicketAttachment(params.id, params.attachmentId, { responseType: 'blob' })
            .then((response) => {
                saveAs(new Blob([response.data]), `${params.fileName}`);

                params.onSuccess && params.onSuccess();
            })
            .catch((reason) => {
                params.onError && params.onError();

                NotificationHelper.createNotification({
                    type: 'error',
                    message: translate('system.notification.getTicketAttachment.title'),
                    title: translate('system.notification.getTicketAttachment.message')
                });
            })
            .finally(() => {
                setTicketAttachmentLoading(false);

                params.onAlways && params.onAlways();
            });
    }

    interface IDeleteTicketAttachment extends IHookCallback {
        id: string;
        attachmentId: string;
    }

    const [deleteTicketAttachmentLoading, setDeleteTicketAttachmentLoading] = useState<boolean>(false);

    const handleDeleteTicketAttachment = async (params: IDeleteTicketAttachment) => {
        setDeleteTicketAttachmentLoading(true);

        const clientApi = await TicketApiClient(instance, helpers.getStoredI18Lang());

        clientApi.deleteTicketAttachment(params.id, params.attachmentId)
            .then((response) => {
                params.onSuccess && params.onSuccess();

                NotificationHelper.createNotification({
                    type: 'success',
                    message: translate('system.notification.deleteTicketAttachmentSuccess.title'),
                    title: translate('system.notification.deleteTicketAttachmentSuccess.message')
                });
            })
            .catch((reason) => {
                params.onError && params.onError();

                NotificationHelper.createNotification({
                    type: 'error',
                    message: translate('system.notification.deleteTicketAttachment.title'),
                    title: translate('system.notification.deleteTicketAttachment.message')
                });
            })
            .finally(() => {
                setDeleteTicketAttachmentLoading(false);

                params.onAlways && params.onAlways();
            });
    }

    // Upload Attachments

    interface IUploadTicketAttachment extends IHookCallback {
        id: string;
        attachment: TicketAttachmentFile;
    }

    const [uploadTicketAttachmentLoading, setUploadTicketAttachmentLoading] = useState<boolean>(false);

    const handleUploadTicketAttachment = async (params: IUploadTicketAttachment) => {
        setUploadTicketAttachmentLoading(true);

        const clientApi = await TicketApiClient(instance, helpers.getStoredI18Lang());

        clientApi.insertTicketAttachment(params.id, params.attachment)
            .then((response) => {
                params.onSuccess && params.onSuccess();

                NotificationHelper.createNotification({
                    type: 'success',
                    message: translate('system.notification.insertTicketAttachmentSuccess.title'),
                    title: translate('system.notification.insertTicketAttachmentSuccess.message')
                });
            })
            .catch((reason) => {
                params.onError && params.onError();

                NotificationHelper.createNotification({
                    type: 'error',
                    message: translate('system.notification.insertTicketAttachment.title'),
                    title: translate('system.notification.insertTicketAttachment.message')
                });
            })
            .finally(() => {
                setUploadTicketAttachmentLoading(false);

                params.onAlways && params.onAlways();
            });
    }

    return {
        handleRetrieveTicketAttachments,
        ticketAttachments,
        ticketAttachmentsLoading,
        handleRetrieveTicketAttachment,
        ticketAttachmentLoading,
        handleDeleteTicketAttachment,
        deleteTicketAttachmentLoading,
        handleUploadTicketAttachment,
        uploadTicketAttachmentLoading
    }
}
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import {
    DefaultButton,
    PrimaryButton,
    Panel,
    PanelType,
    Separator,
    Stack
} from '@fluentui/react';

import { RootState } from '../../../../../redux/application/redux-store';
import { initTheme } from '../../../../../redux/slices/System/systemSlice';
import { TRANSLATION_KEYS } from './localization/translation-keys';

import { FormProvider, IAIDropdown } from '../../../../../common/components/IAIForm';
import helpers from '../../../../../utils/helpers/helpers';

interface FormValuesProps {
    language: string;
    theme: string;
};

interface IApplicationSettingsDrawer {
    drawerVisible?: boolean;
    onDrawerDismiss: () => void;
};

const ApplicationSettingsDrawer: React.FunctionComponent<IApplicationSettingsDrawer> = ({
    drawerVisible,
    onDrawerDismiss
}) => {
    const iaiSystem = useSelector((state: RootState) => state.system);

    const {
        t: translate,
        i18n
    } = useTranslation();

    //#region Form-Configuration

    const formDataSchema = Yup.object().shape({
        language: Yup.string().required(translate(TRANSLATION_KEYS.LanguageErrorMessage)),
        theme: Yup.string().required(translate(TRANSLATION_KEYS.ThemeErrorMessage))
    });

    const defaultValues = useMemo(
        () => ({
            language: helpers.getStoredI18Lang(),
            theme: iaiSystem.theme || 'light'
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const methods = useForm<FormValuesProps>({ resolver: yupResolver(formDataSchema), defaultValues });

    const {
        reset,
        handleSubmit,
        formState: { isSubmitting }
    } = methods;

    useEffect(() => {
        reset(defaultValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //#endregion

    const dispatch = useDispatch();

    const _handleSubmit = (data: FormValuesProps) => {
        i18n.changeLanguage(data.language, () => {
            dispatch(initTheme(data.theme));
            onDrawerDismiss();
        });
    }

    return (
        <FormProvider methods={methods}>
            <Panel
                type={PanelType.smallFixedFar}
                headerText={translate(TRANSLATION_KEYS.Heading)}
                isOpen={drawerVisible}
                isBlocking={true}
                isFooterAtBottom={true}
                onDismiss={onDrawerDismiss}
                onRenderFooterContent={() => (
                    <div className="text-end">
                        <DefaultButton
                            disabled={isSubmitting}
                            iconProps={{ iconName: 'Cancel' }}
                            styles={{ root: { marginRight: 10 } }}
                            onClick={onDrawerDismiss}
                        >
                            {translate(TRANSLATION_KEYS.DismissText)}
                        </DefaultButton>
                        <PrimaryButton
                            type="submit"
                            disabled={isSubmitting}
                            iconProps={{ iconName: 'CheckMark' }}
                            onClick={handleSubmit(_handleSubmit)}
                        >
                            {translate(TRANSLATION_KEYS.ConfirmText)}
                        </PrimaryButton>
                    </div>
                )}
            >
                <Separator />

                <Stack tokens={{ childrenGap: 15 }}>
                    <IAIDropdown
                        name="language"
                        label={translate(TRANSLATION_KEYS.LanguageLabel)}
                        placeholder={translate(TRANSLATION_KEYS.LanguagePlaceholder)}
                        required
                        options={[
                            { key: 'it', text: translate(TRANSLATION_KEYS.LanguageOptionIt) },
                            { key: 'en', text: translate(TRANSLATION_KEYS.LanguageOptionEn) }
                        ]}
                    />

                    <IAIDropdown
                        name="theme"
                        label={translate(TRANSLATION_KEYS.ThemeLabel)}
                        placeholder={translate(TRANSLATION_KEYS.ThemePlaceholder)}
                        required
                        options={[
                            { key: 'light', text: translate(TRANSLATION_KEYS.ThemeOptionLight) },
                            { key: 'dark', text: translate(TRANSLATION_KEYS.ThemeOptionDark) }
                        ]}
                    />
                </Stack>
            </Panel>
        </FormProvider>
    );
}

export default ApplicationSettingsDrawer;
import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';

import { ITextFieldProps, TextField } from '@fluentui/react';

interface IIAITextField extends ITextFieldProps {
    name: string;
}

const IAITextField: React.FunctionComponent<IIAITextField> = ({
    name,
    ...other
}) => {
    const {
        control
    } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <TextField
                    {...field}
                    errorMessage={error?.message}
                    {...other}
                />
            )}
        />
    );
}

export default IAITextField;
import { getTheme, mergeStyleSets } from '@fluentui/react';

interface ITicketPageClassNames {
    pageContainer: string;
}

export const useClassNames = (): ITicketPageClassNames => {
    const applicationTheme = getTheme();

    return mergeStyleSets({
        pageContainer: {
            background: applicationTheme.semanticColors.bodyBackground
        }
    });
}
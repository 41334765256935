import { useState } from 'react';

import { useMsal } from "@azure/msal-react";

import { Ticket } from '../../../../api-client';
import { IHookCallback } from '../../../../common/models/IHookCallback';
import { ExternalPublicApiClient } from '../../../../providers/api-provider';
import { NotificationHelper } from '../../../../utils/notification';
import { useTranslation } from 'react-i18next';

export const useTicketView = () => {
    const {
        instance
    } = useMsal();

    const {
        t: translate
    } = useTranslation();

    interface IRetrieveTicket extends IHookCallback {
        id: string;
        onRetrieveSuccess: (ticket: Ticket) => void;
    }

    const [retrieveTicket, setRetrieveTicket] = useState<Ticket>();
    const [retrieveTicketLoading, setRetrieveTicketLoading] = useState<boolean>(false);

    const handleRetrieveTicket = async (params: IRetrieveTicket) => {
        setRetrieveTicketLoading(true);

        const clientApi = await ExternalPublicApiClient(instance);

        clientApi.getTicketByExternal(params.id)
            .then((response: any) => {
                setRetrieveTicket(response.data);

                params.onRetrieveSuccess(response.data);
            })
            .catch((reason) => {
                params.onError && params.onError();

                NotificationHelper.createNotification({
                    type: 'error',
                    message: translate('system.notification.getTicketByExternal.title'),
                    title: translate('system.notification.getTicketByExternal.message')
                });
            })
            .finally(() => {
                setRetrieveTicketLoading(false);

                params.onAlways && params.onAlways();
            });
    }

    return {
        handleRetrieveTicket,
        retrieveTicket,
        retrieveTicketLoading,
    }
}
import { IDatePickerStrings } from "@fluentui/react";

export const datePickerStrings = (isoCode: string): IDatePickerStrings | undefined => {
	if (isoCode !== 'it' && isoCode !== 'it-IT') {
		return;
	}

	return ({
		months: [
			'Gennaio',
			'Febbraio',
			'Marzo',
			'Aprile',
			'Maggio',
			'Giugno',
			'Luglio',
			'Agosto',
			'Settembre',
			'Ottobre',
			'Novembre',
			'Dicembre'
		],
		shortMonths: ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'],
		days: ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'],
		shortDays: ['D', 'L', 'M', 'M', 'G', 'V', 'S'],
		goToToday: 'Oggi',
		prevMonthAriaLabel: 'Mese precedente',
		nextMonthAriaLabel: 'Mese successivo',
		prevYearAriaLabel: 'Anno precedente',
		nextYearAriaLabel: 'Anno successivo'
	});
}

export const DatePickerStringsIT: IDatePickerStrings = {
	months: [
		'Gennaio',
		'Febbraio',
		'Marzo',
		'Aprile',
		'Maggio',
		'Giugno',
		'Luglio',
		'Agosto',
		'Settembre',
		'Ottobre',
		'Novembre',
		'Dicembre'
	],
	shortMonths: ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'],
	days: ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'],
	shortDays: ['D', 'L', 'M', 'M', 'G', 'V', 'S'],
	goToToday: 'Oggi',
	prevMonthAriaLabel: 'Mese precedente',
	nextMonthAriaLabel: 'Mese successivo',
	prevYearAriaLabel: 'Anno precedente',
	nextYearAriaLabel: 'Anno successivo'
};
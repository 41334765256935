import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import _ from 'lodash';

import {
    Callout,
    DirectionalHint,
    Icon,
    IconButton,
    Stack,
    Text,
    TooltipHost
} from '@fluentui/react';
import { useBoolean, useId } from '@fluentui/react-hooks';

import { RootState } from '../../../../../../../redux/application/redux-store';
import { Announcement } from '../../../../../../../api-client';
import { DateTimeEnchanter } from '../../../../../../../utils/data-enchanters';
import { TRANSLATION_KEYS } from './localization/translation-keys';
import { useClassNames } from './iai-announcement-item.classNames';

import { IAIModal } from '../../../../../../components/IAIModal';
import { IAIEmptyContent } from '../../../../../../components/IAIEmptyContent';

const IAIAnnouncementItem: React.FunctionComponent = () => {
    const {
        iaiSidenavItem,
        calloutContainer,
        calloutBeakCurtain
    } = useClassNames();

    const {
        t: translate
    } = useTranslation();

    const [noticeCalloutVisible, { toggle: toggleNoticeCalloutVisible }] = useBoolean(false);
    const [noticeModalVisible, { toggle: toggleNoticeModalVisible }] = useBoolean(false);
    const [delegateNotice, setDelegateNotice] = useState<Announcement>();

    const _handleNoticeModal = (notice: Announcement) => {
        setDelegateNotice(notice);
        toggleNoticeCalloutVisible();
        toggleNoticeModalVisible();
    }

    const _dismissNoticeModal = () => {
        setDelegateNotice(undefined);
        toggleNoticeCalloutVisible();
        toggleNoticeModalVisible();
    }

    const iconButtonId = useId('iai-notice-item');
    const headingId = useId('iai-notice-item-heading');
    const contentId = useId('iai-notice-item-content');

    const iaiNotices = _.filter(useSelector((state: RootState) => state.system.notices), entry => new Date(entry.availableTo!) > new Date());

    return (
        <TooltipHost
            content={translate(TRANSLATION_KEYS.NoticeCalloutHeading)}
            styles={{ root: { marginTop: 'auto !important' } }}
        >
            <div id={iconButtonId} className={iaiSidenavItem} onClick={toggleNoticeCalloutVisible}>
                {iaiNotices && !!iaiNotices.length && (
                    <span className="position-absolute top-0 start-100 translate-middle bg-danger rounded-circle" style={{ padding: '.4rem' }}>
                        <span className="visually-hidden">
                            {'Nuove notifiche'}
                        </span>
                    </span>
                )}

                <Icon iconName={'Flag'} />

                {noticeCalloutVisible && (
                    <Callout
                        className={calloutContainer}
                        styles={{
                            beak: calloutBeakCurtain,
                            beakCurtain: calloutBeakCurtain,
                            calloutMain: calloutBeakCurtain
                        }}
                        ariaLabelledBy={headingId}
                        ariaDescribedBy={contentId}
                        role="dialog"
                        gapSpace={0}
                        target={`#${iconButtonId}`}
                        directionalHint={DirectionalHint.rightCenter}
                        onDismiss={toggleNoticeCalloutVisible}
                        setInitialFocus
                    >
                        <Text id={headingId} block variant="xLarge" className="mb-3">
                            {translate(TRANSLATION_KEYS.NoticeCalloutHeading)}
                        </Text>

                        {!iaiNotices.length
                            ?
                            <IAIEmptyContent title={translate(TRANSLATION_KEYS.NoticeEmpty)} />
                            :
                            _.map(iaiNotices, (dataItem: Announcement, dataIndex: number) => (
                                <Stack
                                    key={dataIndex}
                                    id={contentId}
                                    horizontal
                                    horizontalAlign="space-between"
                                    verticalAlign="center"
                                    tokens={{ childrenGap: 15 }}
                                >
                                    <IconButton
                                        iconProps={{ iconName: 'MiniExpand' }}
                                        onClick={() => _handleNoticeModal(dataItem)}
                                    />
                                    <Text block variant="small" styles={{ root: { flexGrow: 1 } }}>
                                        {dataItem.title}
                                    </Text>
                                    <Text block variant="small">
                                        {DateTimeEnchanter.formatStandardDate(dataItem.availableFrom)}
                                    </Text>
                                </Stack>
                            ))
                        }
                    </Callout>
                )}
            </div>

            <IAIModal
                isOpen={noticeModalVisible}
                heading={delegateNotice?.title}
                isBlocking={true}
                onDismiss={_dismissNoticeModal}
            >
                <Text block>
                    {parse(delegateNotice?.description || '')}
                </Text>
            </IAIModal>
        </TooltipHost>
    );
}

export default IAIAnnouncementItem;
import { useSelector } from 'react-redux';

import { getTheme, mergeStyleSets } from '@fluentui/react';

import { RootState } from '../../../../redux/application/redux-store';

interface IIAIWidgetIconClassNames {
    widgetContainer: string;
    widgetDescription: string;
}

export const useClassNames = (): IIAIWidgetIconClassNames => {
    const applicationTheme = getTheme();

    const iaiSystem = useSelector((state: RootState) => state.system);

    return mergeStyleSets({
        widgetContainer: {
            background: applicationTheme.palette.neutralLight,
            '&:hover': {
                background: iaiSystem.theme === 'light'
                    ? applicationTheme.palette.themeLighter
                    : applicationTheme.palette.themeTertiary
            },
            '&:hover .widget-description': {
                color: iaiSystem.theme === 'light'
                    ? applicationTheme.palette.themePrimary
                    : applicationTheme.semanticColors.inputText
            }
        },
        widgetDescription: {
            color: applicationTheme.palette.themePrimary
        }
    });
}
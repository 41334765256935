import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { ActivityItem, Panel, PanelType, Separator, Stack, Text } from '@fluentui/react';

import { TicketChangeLog } from '../../../../../../../api-client';
import { TRANSLATION_KEYS } from './localization/translation-keys';
import { DataEnchanter, DateTimeEnchanter } from '../../../../../../../utils/data-enchanters';

import { IAIShimmer } from '../../../../../../../common/components/IAIShimmer';
import { IAIEmptyContent } from '../../../../../../../common/components/IAIEmptyContent';
import { ParameterName } from '../../../../../../../utils/constants/ticket-changelogs-constants';

interface IChangeLogsDrawer {
    changeLogs?: TicketChangeLog[];
    disabled?: boolean;
    drawerVisible?: boolean;
    onDrawerDismiss: () => void;
}

const ChangeLogsDrawer: React.FunctionComponent<IChangeLogsDrawer> = ({
    changeLogs,
    disabled,
    drawerVisible,
    onDrawerDismiss
}) => {
    const {
        i18n,
        t: translate
    } = useTranslation();

    const retrieveTranslatedText = (parameterName: string | undefined, value: string) => {
        if(parameterName === ParameterName.MODE || parameterName === ParameterName.STATUS || parameterName === ParameterName.PRIORITY || value.indexOf('common.unknown') !== -1) {
            return translate(value);
        } else {
            return value ? value : translate('common.unknown');
        }
    };

    const _handleChangesRefined = (changeLog: TicketChangeLog) => {
        if (!changeLog || (!changeLog.oldValue || !changeLog.newValue)) {
            return '';
        }

        const oldValue = DataEnchanter.getChangelogOldAndNewValText(changeLog.parameterName, changeLog.oldValue);
        const newValue = DataEnchanter.getChangelogOldAndNewValText(changeLog.parameterName, changeLog.newValue);

        return `${translate('common.changelogFrom')} ${retrieveTranslatedText(changeLog.parameterName, oldValue)} ${translate('common.changelogTo')} ${retrieveTranslatedText(changeLog.parameterName, newValue)}`;
    };

    const _handleInitialsFromName = (displayName?: string) => {
        if (!displayName) {
            return 'OP';
        }

        let initials = displayName.split(' ');

        return ((initials.shift()?.[0] || '') + (initials.pop()?.[0] || '')).toUpperCase();
    };

    const _generateFormattedString = (parameterName?: string) => {
        return translate(DataEnchanter.getChangelogParameterPreText(parameterName)).replace('#PARAM#', translate(DataEnchanter.getChangelogParameterNameText(parameterName)));
    };

    return (
        <Panel
            type={PanelType.smallFixedFar}
            headerText={translate(TRANSLATION_KEYS.Heading)}
            isOpen={drawerVisible}
            isBlocking={true}
            isFooterAtBottom={true}
            onDismiss={onDrawerDismiss}
        >
            <Separator />

            <Stack tokens={{ childrenGap: 15 }}>
                <IAIShimmer type="shimmer-callout" loading={disabled}>
                    <Stack tokens={{ childrenGap: 15 }}>
                        {_.map(_.orderBy(changeLogs, entry => entry.createdAt, 'desc'), (dataItem, dataIndex) => (
                            <ActivityItem
                                key={dataIndex}
                                activityDescription={(
                                    <React.Fragment>
                                        <Text variant="small"><strong>{dataItem?.userInfo?.displayName}</strong> </Text>
                                        <Text variant="small">{_generateFormattedString(dataItem?.parameterName)} </Text>
                                        <Text variant="small">{_handleChangesRefined(dataItem)}</Text>
                                    </React.Fragment>
                                )}
                                activityPersonas={[
                                    {
                                        imageInitials: _handleInitialsFromName(dataItem?.userInfo?.displayName),
                                        text: dataItem?.userInfo?.displayName,
                                        imageAlt: dataItem?.userInfo?.displayName,
                                        imageShouldFadeIn: true
                                    }
                                ]}
                                timeStamp={(
                                    <Text variant="xSmall">
                                        {DateTimeEnchanter.formatCustomDate(dataItem?.createdAt, i18n.language, { dateStyle: 'short', timeStyle: 'short' })}
                                    </Text>
                                )}
                            />
                        ))}

                        {!disabled && !changeLogs?.length && (
                            <IAIEmptyContent title={translate(TRANSLATION_KEYS.ChangeLogsEmpty)} />
                        )}
                    </Stack>
                </IAIShimmer>
            </Stack>
        </Panel>
    );
}

export default ChangeLogsDrawer;
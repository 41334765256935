import React, { useEffect } from 'react';
import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import { Stack } from '@fluentui/react/lib/Stack';
import { TextField } from '@fluentui/react/lib/TextField';
import { Property } from '../../../../../api-client';
import { IPropertiesForm, usePropertiesForm } from './hooks/usePropertiesForm';
import { PropertiesFormFooter } from './components/PropertiesFormFooter';
import { IButtonStyles, mergeStyleSets, PrimaryButton } from '@fluentui/react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_KEYS } from '../../Localization/translation-keys';

const styles = mergeStyleSets({
    root: { selectors: { '> *': { marginBottom: 15 } } },
    control: { maxWidth: 300, marginBottom: 15 },
});

interface IPropertiesFormDrawer {
    disabled?: boolean;
    drawerVisible?: boolean;
    properties?: Property;
    onDrawerDismiss: () => void;
    onSubmit: (properties: Property, announcementId?: string) => void;
}

const PropertiesFormDrawer: React.FunctionComponent<IPropertiesFormDrawer> = ({ disabled, drawerVisible, properties, onDrawerDismiss, onSubmit }) => {
    const _handleSubmit = (values: IPropertiesForm) => {
        onSubmit(values, values.id);
        handleInitialize({ displayName: '', values: [] })
    }

    const {
        t: translate
    } = useTranslation();

    const {
        handleInitialize,
        handleFormChange,
        formValues,
        handleFormSubmit,
        formErrors
    } = usePropertiesForm(_handleSubmit);

    useEffect(() => {
        properties
            ? handleInitialize(properties)
            : handleInitialize({ displayName: '', values: [] });
    }, [properties]);

    const _handleDismissForm = () => {
        handleInitialize({ displayName: '', values: [] });
        onDrawerDismiss();
    }

    const [textFieldValue, setTextFieldValue] = React.useState('');
    const onChangeTextFieldValue = React.useCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            setTextFieldValue(newValue || '');
        },
        [],
    );
    const handleFormValuesInsert = () => {
        let valuesCollection = formValues.values;
        valuesCollection?.push(textFieldValue);
        handleFormChange('values', valuesCollection);
        setTextFieldValue('');
        formErrors.values = '';
    }

    const handleChange = (e:any, v:any, i:any) => {
        if(formValues.values) {
            formValues.values[i] = v;
        }
        handleFormChange('values', formValues.values);
    };

    const buttonStylesInsert: IButtonStyles = {
        root: {
            minWidth: 0,
            padding: '0 5px 0 5px',
            margin: '0 5px 0 5px'
        }
    }
    const buttonStylesEditDelete: IButtonStyles = {
        root: {
            minWidth: 0,
            padding: '0 5px 0 5px',
            margin: '0 5px 20px 5px'
        }
    }

    return (
        <Panel
            customWidth={'750px'}
            headerText={properties && properties.id ? translate(TRANSLATION_KEYS.editProperty) : translate(TRANSLATION_KEYS.addProperty)}
            type={PanelType.custom}
            isFooterAtBottom={true}
            isOpen={drawerVisible}
            onDismiss={_handleDismissForm}
            onRenderFooterContent={() => (
                <PropertiesFormFooter
                    disabled={disabled}
                    onDismiss={_handleDismissForm}
                    onSubmit={handleFormSubmit}
                />
            )}
        >
            <Stack tokens={{ childrenGap: 15 }}>
                <TextField
                    id={'sln_displayName__'}
                    label={translate(TRANSLATION_KEYS.propertyName)}
                    placeholder={translate(TRANSLATION_KEYS.propertyNamePlaceholder)}
                    errorMessage={formErrors.displayName}
                    disabled={disabled}
                    required
                    value={formValues.displayName}
                    onChange={(ev, value) => handleFormChange('displayName', value)}
                />

                <div className="col-12">
                    <Stack horizontal tokens={{ childrenGap: 15 }} styles={{ root: { alignItems: 'flex-end' } }}>
                        <Stack.Item grow>
                            <TextField
                                id={`sln_value___`}
                                label={translate(TRANSLATION_KEYS.propertyValues)}
                                placeholder={translate(TRANSLATION_KEYS.propertyValuePlaceholder)}
                                errorMessage={formErrors.values}
                                disabled={disabled}
                                required
                                value={textFieldValue}
                                onChange={onChangeTextFieldValue}
                            />
                        </Stack.Item>
                        <Stack.Item>
                            <PrimaryButton iconProps={{ iconName: 'Add' }} disabled={textFieldValue == ''} styles={buttonStylesInsert} onClick={handleFormValuesInsert} />
                        </Stack.Item>
                    </Stack>
                    <div><hr /></div>
                    {_.map(formValues.values, (value, dataIndex) => (
                        <Stack key={dataIndex} horizontal tokens={{ childrenGap: 15 }} styles={{ root: { alignItems: 'flex-end' } }}>
                            <Stack.Item grow>
                                <TextField
                                    id={`sln_value___`}
                                    label={translate(TRANSLATION_KEYS.propertyValue)}
                                    // placeholder={'Modifica il valore...'}
                                    description={translate(TRANSLATION_KEYS.propertyValueDescription)}
                                    errorMessage={formErrors.values}
                                    disabled={disabled}
                                    required
                                    value={value}
                                    onChange={(ev, value) => handleChange(ev, value, dataIndex)}
                                />
                            </Stack.Item>
                            <Stack.Item>
                                <PrimaryButton iconProps={{ iconName: 'Delete' }} styles={buttonStylesEditDelete} onClick={() => {
                                    formValues.values && formValues.values.splice(dataIndex, 1);
                                    handleFormChange('values', formValues.values);
                                }} />
                            </Stack.Item>
                        </Stack>
                    ))}
                </div>
            </Stack>
        </Panel>
    );
}

export default PropertiesFormDrawer;
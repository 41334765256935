import { getTheme, mergeStyleSets } from '@fluentui/react';

interface IHomepageClassNames {
    homepageContainer: string;
}

export const useClassNames = (): IHomepageClassNames => {
    const applicationTheme = getTheme();

    return mergeStyleSets({
        homepageContainer: {
            background: applicationTheme.semanticColors.bodyBackground
        }
    });
}
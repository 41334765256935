import appsettings from '../../config/appsettings.json';

import { env } from '../../config/env';
import { ApplicationTheme } from './application-themes';

export class ApplicationSettings {
    public static IAI_APP_AUTHORITY = appsettings[env].authority;
    public static IAI_APP_AXIOS_TIMEOUT = appsettings[env].axiosTimeout;
    public static IAI_APP_API_URL = appsettings[env].baseApiUrl;
    public static IAI_APP_APP_URL = appsettings[env].baseAppUrl;
    public static IAI_APP_RESOURCE_NAME = appsettings[env].resourceName;
    public static IAI_APP_CLIENTID = appsettings[env].clientId;
    public static IAI_APP_THEME = ApplicationTheme.partialTheme(appsettings[env].applicationTheme);
    public static IAI_APP_THEME_DARK = ApplicationTheme.partialThemeDark(appsettings[env].applicationTheme);
    public static IAI_APP_THEME_ROOT = ApplicationTheme.themeResourcesRoot(appsettings[env].applicationTheme);
    public static IAI_APP_DISABLE_COLORBLIND = appsettings[env].disableColorblind;
    public static IAI_APP_ENABLE_TEAMS_SCHEDULER = appsettings[env].enableTeamsScheduler;
}
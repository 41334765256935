import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { ContextualMenuItemType } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';

import { Area } from '../../../api-client';
import { RouterSettings } from '../../../utils/router';
import { TRANSLATION_KEYS } from './localization/translation-keys';
import { useArea } from './hooks/useArea';
import { useClassNames } from './setting-area-page.classNames';

import { IAIApplicationLayout } from '../../../common/layouts/IAIApplicationLayout';
import { IAIBreadcrumb } from '../../../common/components/IAIBreadcrumb';
import {
    IAIDetailsList,
    IAIContextualMenuColumn,
    IAIDatetimeColumn,
    IAIPreviewColumn,
    IAIStringColumn
} from '../../../common/components/IAITable';
import DeleteDialog from './components/DeleteDialog/delete-dialog';
import FormDrawer from './components/FormDrawer/form-drawer';

const SettingAreaPage: React.FunctionComponent = () => {
    const {
        settingAreaPageContainer
    } = useClassNames();

    const {
        handleAreas,
        areas,
        areasLoading,
        handleCreateArea,
        createAreaLoading,
        handleUpdateArea,
        updateAreaLoading,
        handleDeleteArea,
        deleteAreaLoading
    } = useArea();

    useEffect(() => {
        handleAreas();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const _handleSearchPredicate = (dataItem: Area, searchKey: string) => (
        _.includes(dataItem.displayName?.toLowerCase(), searchKey)
    );

    //#region Form-Management

    const [areaDrawerVisible, { toggle: toggleAreaDrawerVisible }] = useBoolean(false);
    const [deleteAreaVisible, { toggle: toggleDeleteAreaVisible }] = useBoolean(false);
    const [delegateArea, setDelegateArea] = useState<Area>();

    const _handleCreateAction = () => {
        setDelegateArea(undefined);
        toggleAreaDrawerVisible();
    }

    const _handleUpsertAction = (dataItem: Area) => {
        setDelegateArea(dataItem);
        toggleAreaDrawerVisible();
    }

    const _handleDismissUpsertAction = () => {
        setDelegateArea(undefined);
        toggleAreaDrawerVisible();
    }

    const _handleDeleteAction = (dataItem: Area) => {
        setDelegateArea(dataItem);
        toggleDeleteAreaVisible();
    }

    const _handleDismissDeleteAction = () => {
        setDelegateArea(undefined);
        toggleDeleteAreaVisible();
    }

    const _handleUpsertArea = (dataItem: Area) => {
        const onUpsertSuccess = () => {
            _handleDismissUpsertAction();

            handleAreas();
        };

        dataItem.id
            ?
            handleUpdateArea({ id: dataItem.id, area: dataItem, onSuccess: onUpsertSuccess })
            :
            handleCreateArea({ area: dataItem, onSuccess: onUpsertSuccess });
    }

    const _handleDeleteArea = () => {
        if (delegateArea?.id) {
            handleDeleteArea({
                id: delegateArea.id,
                onSuccess: () => {
                    _handleDismissDeleteAction();

                    handleAreas();
                }
            });
        }
    }

    //#endregion

    const {
        t: translate
    } = useTranslation();

    return (
        <IAIApplicationLayout
            icon={'ArrangeBringToFront'}
            heading={translate(TRANSLATION_KEYS.Heading)}
            secondaryHeading={translate(TRANSLATION_KEYS.SecondaryHeading)}
        >
            <div className={settingAreaPageContainer}>
                <IAIBreadcrumb
                    items={[
                        {
                            key: RouterSettings.ROUTES_KEYS.IAI_APP_SETTING,
                            text: translate(TRANSLATION_KEYS.BreadcrumbSettingHeading)
                        },
                        {
                            key: RouterSettings.ROUTES_KEYS.IAI_APP_SETTING_AREA,
                            text: translate(TRANSLATION_KEYS.Heading),
                            isCurrentItem: true
                        }
                    ]}
                />

                <IAIDetailsList
                    dataSource={areas || []}
                    dataColumns={[
                        {
                            key: 'are__preview',
                            fieldName: '',
                            name: '',
                            minWidth: 50,
                            maxWidth: 50,
                            onRender: (dataItem: Area) => (
                                <IAIPreviewColumn
                                    heading={dataItem.displayName}
                                    content={_.map(dataItem.departments, entry => entry.displayName).join(', ')}
                                    tooltip={translate(TRANSLATION_KEYS.ColumnPreviewTooltip)}
                                />
                            )
                        },
                        {
                            key: 'are__displayName',
                            fieldName: 'displayName',
                            name: translate(TRANSLATION_KEYS.ColumnTitleLabel),
                            minWidth: 200,
                            isResizable: true,
                            onRender: (dataItem: Area) => (
                                <IAIStringColumn align="start" text={dataItem.displayName} />
                            )
                        },
                        {
                            key: 'are__departments',
                            fieldName: '',
                            name: translate(TRANSLATION_KEYS.ColumnDepartmentLabel),
                            minWidth: 200,
                            isResizable: true,
                            onRender: (dataItem: Area) => (
                                <IAIStringColumn
                                    align="start"
                                    text={_.map(dataItem.departments, entry => entry.displayName).join(', ')}
                                />
                            )
                        },
                        {
                            key: 'are__createdAt',
                            fieldName: 'createdAt',
                            name: translate(TRANSLATION_KEYS.ColumnCreatedAtLabel),
                            minWidth: 100,
                            maxWidth: 100,
                            onRender: (dataItem: Area) => (
                                <IAIDatetimeColumn align="start" datetime={dataItem.createdAt} />
                            )
                        },
                        {
                            key: 'are__actions',
                            fieldName: '',
                            name: '',
                            minWidth: 50,
                            maxWidth: 50,
                            onRender: (dataItem: Area, dataIndex) => (
                                <IAIContextualMenuColumn
                                    menuItems={[
                                        {
                                            key: `menuItem__update_${dataIndex}`,
                                            iconProps: { iconName: 'EditCreate' },
                                            text: translate(TRANSLATION_KEYS.ColumnActionCommandUpdateLabel),
                                            title: translate(TRANSLATION_KEYS.ColumnActionCommandUpdateTooltip),
                                            onClick: () => _handleUpsertAction(dataItem)
                                        },
                                        { key: `menuItem__divider_${dataIndex}`, itemType: ContextualMenuItemType.Divider },
                                        {
                                            key: `menuItem__delete_${dataIndex}`,
                                            iconProps: { iconName: 'Delete' },
                                            text: translate(TRANSLATION_KEYS.ColumnActionCommandDeleteLabel),
                                            title: translate(TRANSLATION_KEYS.ColumnActionCommandDeleteTooltip),
                                            onClick: () => _handleDeleteAction(dataItem)
                                        }
                                    ]}
                                />
                            )
                        }
                    ]}
                    dataLoading={areasLoading}
                    commands={[
                        {
                            key: '',
                            disabled: areasLoading,
                            text: translate(TRANSLATION_KEYS.CommandCreateLabel),
                            title: translate(TRANSLATION_KEYS.CommandCreateTooltip),
                            iconProps: { iconName: 'Add' },
                            onClick: _handleCreateAction
                        }
                    ]}
                    emptyContentLabel={translate(TRANSLATION_KEYS.DetailsListEmptyContentLabel)}
                    onItemInvoked={(dataItem: Area) => _handleUpsertAction(dataItem)}
                    onSearchPredicate={_handleSearchPredicate}
                />

                <FormDrawer
                    area={delegateArea}
                    disabled={areasLoading || createAreaLoading || updateAreaLoading}
                    drawerVisible={areaDrawerVisible}
                    onDrawerConfirm={_handleUpsertArea}
                    onDrawerDismiss={_handleDismissUpsertAction}
                />

                <DeleteDialog
                    disabled={areasLoading || deleteAreaLoading}
                    modalVisible={deleteAreaVisible}
                    onModalConfirm={_handleDeleteArea}
                    onModalDismiss={_handleDismissDeleteAction}
                />
            </div>
        </IAIApplicationLayout>
    );
}

export default SettingAreaPage;
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TicketAttachmentFile, TicketFilteringCriteria } from '../../../api-client';

// Define a type for the slice state
interface TicketState {
    criteria?: TicketFilteringCriteria;
    // TO REMOVE AND BETTER FORM MANAGEMENT
    attachments: TicketAttachmentFile[];
}

// Define the initial state using that type
const initialState: TicketState = {
    attachments: []
};

export const ticketSlice = createSlice({
    name: 'ticket',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        initCriteria: (state, action: PayloadAction<TicketFilteringCriteria>) => {
            state.criteria = action.payload;
        },
        keepCriteria: (state, action: PayloadAction<TicketFilteringCriteria>) => {
            let cleanPayload = Object.fromEntries(Object.entries(action.payload).filter(([_, v]) => !!v));
            if (!!Object.keys(cleanPayload).length) {
                state.criteria = {
                    ...cleanPayload
                };
            }
            else {
                state.criteria = undefined;
            }
        },
        clearCriteria: (state) => {
            state.criteria = undefined;
        },
        // TO REMOVE AND BETTER FORM MANAGEMENT
        keepAttachments: (state, action: PayloadAction<TicketAttachmentFile>) => {
            state.attachments = [
                ...state.attachments,
                action.payload
            ];
        },
        clearAttachments: (state) => {
            state.attachments = [];
        }
    }
});

export const {
    initCriteria,
    keepCriteria,
    clearCriteria,
    keepAttachments,
    clearAttachments
} = ticketSlice.actions;

export default ticketSlice.reducer;
import React from 'react';
import _ from 'lodash';

import { Icon, Stack, TooltipHost } from '@fluentui/react';

import { DataEnchanter } from '../../../../utils/data-enchanters';
import { useClassNames } from './iai-status-column.classNames';
import { useTranslation } from 'react-i18next';
import { ApplicationSettings } from '../../../../utils/application';

interface IIAIStatusColumn {
    align: 'start' | 'center' | 'end';
    status?: string;
}

const IAIStatusColumn: React.FunctionComponent<IIAIStatusColumn> = ({
    align = 'start',
    status
}) => {
    const {
        statusIcon
    } = useClassNames({ status });

    const {
        t: translate
    } = useTranslation();

    return (
        <div className={`text-${align}`}>
            <TooltipHost
                hidden={ApplicationSettings.IAI_APP_DISABLE_COLORBLIND}
                content={(
                    <Stack>
                        {_.map(DataEnchanter.colorblindByStatusEnum(status || ''), (dataItem, dataIndex) => (
                            <Stack key={dataIndex} horizontal verticalAlign="center">
                                <Icon
                                    iconName={'StatusCircleInner'}
                                    className={statusIcon}
                                    styles={{ root: { color: dataItem } }}
                                />
                                {translate(DataEnchanter.textByStatusEnum(status || ''))}
                            </Stack>
                        ))}
                    </Stack>
                )}
            >
                <Stack horizontal verticalAlign="center">
                    <Icon iconName={'StatusCircleInner'} className={statusIcon} />
                    {translate(DataEnchanter.textByStatusEnum(status || ''))}
                </Stack>
            </TooltipHost>
        </div>
    );
}

export default IAIStatusColumn;
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import _ from 'lodash';

import {
    ComboBox,
    DatePicker,
    DefaultButton,
    PrimaryButton,
    Panel,
    PanelType,
    Separator,
    Stack
} from '@fluentui/react';

import { RootState } from '../../../redux/application/redux-store';
import { TicketFilteringCriteria, TicketModeEnum, TicketPriorityEnum, TicketStatusEnum } from '../../../api-client';

import { TRANSLATION_KEYS } from './localization/translation-keys';
import { datePickerStrings } from '../../../utils/constants';
import { DateTimeEnchanter } from '../../../utils/data-enchanters';

interface IIAITicketsRefinementDrawer {
    criteria?: TicketFilteringCriteria;
    disabled?: boolean;
    drawerVisible?: boolean;
    onApplyCriteria: () => void;
    onClearCriteria: () => void;
    onChangeCriteria: (name: string, value: any) => void;
    onDrawerDismiss: () => void;
}

const IAITicketsRefinementDrawer: React.FunctionComponent<IIAITicketsRefinementDrawer> = ({
    criteria,
    disabled,
    drawerVisible,
    onApplyCriteria,
    onClearCriteria,
    onChangeCriteria,
    onDrawerDismiss
}) => {
    //#region Dropdown-Management

    const iaiSystem = useSelector((state: RootState) => state.system);

    const areaOptions = useMemo(
        () => (
            _.map(iaiSystem.areas, entry => ({ key: entry.key!, text: entry.text! }))
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [iaiSystem.areas]
    );

    const departmentOptions = useMemo(
        () => (
            _.map(iaiSystem.departments, entry => ({ key: entry.key!, text: entry.text! }))
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [iaiSystem.departments]
    );

    const agentOptions = useMemo(
        () => (
            _.map(iaiSystem.agents, entry => ({ key: entry.key!, text: entry.text! }))
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [iaiSystem.agents]
    );

    //#endregion

    const {
        i18n,
        t: translate
    } = useTranslation();

    return (
        <Panel
            type={PanelType.smallFixedFar}
            headerText={translate(TRANSLATION_KEYS.Heading)}
            isOpen={drawerVisible}
            isBlocking={true}
            isFooterAtBottom={true}
            onDismiss={onDrawerDismiss}
            onRenderFooterContent={() => (
                <div className="text-end">
                    <DefaultButton
                        disabled={disabled}
                        iconProps={{ iconName: 'Delete' }}
                        styles={{ root: { marginRight: 10 } }}
                        onClick={onClearCriteria}
                    >
                        {translate(TRANSLATION_KEYS.DismissText)}
                    </DefaultButton>
                    <PrimaryButton
                        type="submit"
                        disabled={disabled}
                        iconProps={{ iconName: 'Search' }}
                        onClick={onApplyCriteria}
                    >
                        {translate(TRANSLATION_KEYS.ConfirmText)}
                    </PrimaryButton>
                </div>
            )}
        >
            <Separator />

            <Stack tokens={{ childrenGap: 15 }}>
                {/* 
                <ComboBox
                    label={translate(TRANSLATION_KEYS.ModeLabel)}
                    placeholder={translate(TRANSLATION_KEYS.ModePlaceholder)}
                    allowFreeform={true}
                    autoComplete={'on'}
                    disabled={disabled}
                    options={[
                        { key: TicketModeEnum.Level01, text: translate(TRANSLATION_KEYS.ModeOptionLevel01) },
                        { key: TicketModeEnum.Level02, text: translate(TRANSLATION_KEYS.ModeOptionLevel02) },
                        { key: TicketModeEnum.Level03, text: translate(TRANSLATION_KEYS.ModeOptionLevel03) }
                    ]}
                    selectedKey={criteria?.mode || null}
                    onChange={(_, option) => onChangeCriteria('mode', option?.key)}
                />
                */}
                
                <ComboBox
                    label={translate(TRANSLATION_KEYS.AreaLabel)}
                    placeholder={translate(TRANSLATION_KEYS.AreaPlaceholder)}
                    allowFreeform={true}
                    autoComplete={'on'}
                    disabled={disabled || !iaiSystem.areasInitialized}
                    options={areaOptions}
                    selectedKey={criteria?.areaId || null}
                    onChange={(_, option) => onChangeCriteria('areaId', option?.key)}
                />
                <ComboBox
                    label={translate(TRANSLATION_KEYS.DepartmentLabel)}
                    placeholder={translate(TRANSLATION_KEYS.DepartmentPlaceholder)}
                    allowFreeform={true}
                    autoComplete={'on'}
                    disabled={disabled || !iaiSystem.departmentsInitialized}
                    options={departmentOptions}
                    selectedKey={criteria?.departmentId || null}
                    onChange={(_, option) => onChangeCriteria('departmentId', option?.key)}
                />
                <ComboBox
                    label={translate(TRANSLATION_KEYS.AgentLabel)}
                    placeholder={translate(TRANSLATION_KEYS.AgentPlaceholder)}
                    allowFreeform={true}
                    autoComplete={'on'}
                    disabled={disabled || !iaiSystem.agentsInitialized}
                    options={agentOptions}
                    selectedKey={criteria?.agentId || null}
                    onChange={(_, option) => onChangeCriteria('agentId', option?.key)}
                />
                <ComboBox
                    label={translate(TRANSLATION_KEYS.StatusLabel)}
                    placeholder={translate(TRANSLATION_KEYS.StatusPlaceholder)}
                    allowFreeform={true}
                    autoComplete={'on'}
                    disabled={disabled}
                    options={[
                        { key: TicketStatusEnum.Open, text: translate(TRANSLATION_KEYS.StatusOptionOpen) },
                        { key: TicketStatusEnum.Working, text: translate(TRANSLATION_KEYS.StatusOptionWorking) },
                        { key: TicketStatusEnum.Pending, text: translate(TRANSLATION_KEYS.StatusOptionPending) },
                        { key: TicketStatusEnum.Closed, text: translate(TRANSLATION_KEYS.StatusOptionClosed) }
                    ]}
                    selectedKey={criteria?.status || null}
                    onChange={(_, option) => onChangeCriteria('status', option?.key)}
                />
                <ComboBox
                    label={translate(TRANSLATION_KEYS.PriorityLabel)}
                    placeholder={translate(TRANSLATION_KEYS.PriorityPlaceholder)}
                    allowFreeform={true}
                    autoComplete={'on'}
                    disabled={disabled}
                    options={[
                        { key: TicketPriorityEnum.Low, text: translate(TRANSLATION_KEYS.PriorityOptionLow) },
                        { key: TicketPriorityEnum.Medium, text: translate(TRANSLATION_KEYS.PriorityOptionMedium) },
                        { key: TicketPriorityEnum.High, text: translate(TRANSLATION_KEYS.PriorityOptionHigh) }
                    ]}
                    selectedKey={criteria?.priority || null}
                    onChange={(_, option) => onChangeCriteria('priority', option?.key)}
                />
                <DatePicker
                    label={translate(TRANSLATION_KEYS.FromDateLabel)}
                    placeholder={translate(TRANSLATION_KEYS.FromDatePlaceholder)}
                    strings={datePickerStrings(i18n.language)}
                    formatDate={(date) => DateTimeEnchanter.formatStandardDate(date, i18n.language)}
                    disabled={disabled}
                    value={criteria?.fromDate ? new Date(criteria?.fromDate) : undefined}
                    onSelectDate={(value) => (
                        onChangeCriteria('fromDate', value ? moment(value).endOf('day').toDate().toISOString() : undefined)
                    )}
                />
                <DatePicker
                    label={translate(TRANSLATION_KEYS.ToDateLabel)}
                    placeholder={translate(TRANSLATION_KEYS.ToDatePlaceholder)}
                    strings={datePickerStrings(i18n.language)}
                    formatDate={(date) => DateTimeEnchanter.formatStandardDate(date, i18n.language)}
                    disabled={disabled}
                    value={criteria?.toDate ? new Date(criteria?.toDate) : undefined}
                    onSelectDate={(value) => (
                        onChangeCriteria('toDate', value ? moment(value).endOf('day').toDate().toISOString() : undefined)
                    )}
                />
            </Stack>
        </Panel>
    );
}

export default IAITicketsRefinementDrawer;